import PropTypes from "prop-types";
import { set, sub } from "date-fns";
import { noCase, sentenceCase } from "change-case";
import { useState, useRef, useEffect } from "react";
import { useAppContext } from "src/hooks";
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import { styled, lighten } from "@mui/material/styles";
// utils
import { fToNow } from "src/utils/formatTime";
// components
import { Iconify, Scrollbar, MenuPopover } from "src/components/";
import notification_chat from "src/assets/media/notification_chat.svg";
import {
  _notificationList,
  _readAllNotifications,
  _readNotification,
  _reset_unread_notification_count,
} from "src/DAL/notifications";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { replace } from "lodash";
import {
  InvitationNotification,
  Invoice,
  LeftWorkspaceNotification,
  ProjectNotification,
  TaskComment,
  TaskNotification,
} from "src/assets";
// ----------------------------------------------------------------------

const IconButtonStyle = styled(IconButton)(({ theme, custom_prop }) => {
  // console.log(custom_prop);
  return {
    width: 40,
    height: 40,
    // backgroundColor: lighten(theme.palette.primary.lighter, 0.3),
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.lighter, 0.3),
    },
  };
});

export default function NotificationsPopover() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    notificationCount,
    setNotificationCount,
    notifications,
    setNotifications,
    count,
    setCount,
    page,
    setPage,
    loading,
    setLoading,
    getNotificationList,
    unreadNotification,
    setUnreadNotification,
    socketEmit,
    socket,
    dispatch_get_user_profile,
  } = useAppContext();
  const anchorRef = useRef(null);

  const [displayLoader, setdisplayLoader] = useState(false);

  const readNotifications = async (type, id) => {
    let reqObj = {};
    if (type == "all") {
      reqObj = { type };
    } else if (type == "one") {
      reqObj = { notification_id: id, type, mobile_push_notification_id: "" };
    } else {
      return;
    }
    const result = await _readNotification(reqObj);
    if (result.code == 200) {
      if (type == "all") {
        setUnreadNotification(0);
      }
    }
    console.log(result, "read notifications");
  };
  const totalUnRead = notificationCount;
  // const totalUnRead = notifications.filter(
  //   (item) => item.isUnRead === true
  // ).length;

  const [open, setOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = async (event) => {
    setOpen(event.currentTarget);
    setAnchorEl(event.currentTarget);
    getNotificationList();
    const result = await _reset_unread_notification_count();
    if (result.code == 200) {
      setNotificationCount(0);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleMarkAllAsRead = () => {
    readNotifications("all");
    setNotifications((prev) => {
      return prev.map((notification) => ({
        ...notification,
        isUnRead: false,
      }));
    });
  };
  const handleClick = (notification) => {
    const type = notification.notification_type;
    const data = notification.payload.data;
    if (type === "Invitation to a workspace") {
      navigate("/invitations");
    } else if (type === "Invitation accepted") {
      navigate(`/workspaces/${data.id}`, {
        state: {
          workspace_data: {
            _id: data.id,
            team: data.team,
            name: data.workspace_name,
            allow_add_project: data.allow_add_project,
            allow_drag_drop: data.allow_drag_drop,
            role: data.role,
          },
        },
      });
    } else if (type == "Assign in project") {
      navigate("/workspaces/projects/" + data.id);
    } else if (
      type == "Add a task" ||
      type == "update task status" ||
      type == "update task priority" ||
      type == "update task date" ||
      type == "add a new comment"
    ) {
      navigate(
        `/workspaces/copied-task-link/${data.project_id}/${data.section_id}/${data.task_id}`,
        { state: { task_id: data.task_id } }
      );
    } else if (type == "Chat Created") {
      navigate("/chats");
    } else if (type.includes("support ticket")) {
      navigate(`/support/${data.ticket_id}`);
    } else if (type.includes("Invoice")) {
      navigate(`/invoices/detail-invoice`, { state: { _id: data.id } });
    }
    setNotifications((prev) => {
      return prev.map((temp) => {
        if (temp._id == notification._id) {
          return {
            ...temp,
            isUnRead: false,
          };
        } else {
          return { ...temp };
        }
      });
    });
    if (notification.isUnRead) {
      // setNotificationCount((prev) => (prev > 0 ? prev - 1 : prev));
      readNotifications("one", notification._id);
    }
    handleClose();
  };
  useEffect(() => {
    setPage(0);
    getNotificationList();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        socketEmit("send_unread_count", {
          user_id: dispatch_get_user_profile().user_id,
        });
      }
    }, 1000);
  }, [socket]);
  return (
    <>
      <IconButtonStyle
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        custom_prop={"custom-value"}
        // sx={{ width: 40, height: 40, backgroundColor: '#c8facdc5' }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonStyle>

      {!displayLoader && (
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        >
          <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                You have {unreadNotification} unread notifications
              </Typography>
            </Box>

            {unreadNotification > 0 && (
              <Tooltip title=" Mark all as read">
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Divider sx={{ borderStyle: "dashed" }} />
          {/* {displayLoader && (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          )} */}
          {!displayLoader && (
            <Scrollbar sx={{ height: { xs: "80vh" } }}>
              <List
                disablePadding
                // subheader={
                //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                //     New
                //   </ListSubheader>
                // }
              >
                {notifications.map((notification) => (
                  <NotificationItem
                    handleClick={handleClick}
                    key={notification._id}
                    notification={notification}
                  />
                ))}
              </List>
              {count > notifications.length && (
                <Box>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    onClick={() => getNotificationList(page)}
                  >
                    View More
                  </LoadingButton>
                </Box>
              )}
            </Scrollbar>
          )}

          <Divider sx={{ borderStyle: "dashed" }} />
        </MenuPopover>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, handleClick }) {
  const { avatar, title } = renderContent(notification);
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => handleClick(notification)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.notification_type}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        <br /> {sentenceCase(notification.payload.notification.body)}
      </Typography>
    </Typography>
  );
  const getNotificationIcon = (type) => {
    if (type.toLowerCase().includes("invitation")) {
      return InvitationNotification;
    } else if (type.toLowerCase().includes("task")) {
      return TaskNotification;
    } else if (type.toLowerCase().includes("comment")) {
      return TaskComment;
    } else if (type.toLowerCase().includes("project")) {
      return ProjectNotification;
    } else if (type.toLowerCase().includes("left")) {
      return LeftWorkspaceNotification;
    } else if (type.toLowerCase().includes("invoice")) {
      return Invoice;
    } else {
      return notification_chat;
    }
  };
  return {
    avatar: (
      <img
        src={getNotificationIcon(notification.notification_type)}
        alt="notification-logo"
        height={"80%"}
        width={"80%"}
      />
    ),
    title,
  };
  //---------------
  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_package.svg' />,
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_shipping.svg' />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_mail.svg' />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_chat.svg' />,
  //     title,
  //   };
  // }
  // return {
  //   avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
  //   title,
  // };
}
