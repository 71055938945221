import { Avatar } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { s3baseUrl } from "src/config";
// ---------------------------------------------------------
const UserAvatar = ({ image, name, alt, sx }) => {
  let user_name = name ? name : "User";
  // ---------------------------------------------------------
  if (image) {
    return <Avatar sx={{ ...sx }} alt={alt} src={s3baseUrl + image} />;
  }
  return <Avatar sx={{ ...sx }}>{user_name.substring(0, 2)}</Avatar>;
};

export default UserAvatar;
UserAvatar.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  alt: PropTypes.string,
  sx: PropTypes.object,
};
