import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { Loadable, TaskLinkController } from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import {
  AddInvoice,
  AddReport,
  Clients,
  EmailVerification,
  NewProfile,
  SupportTicketDetails,
  SupportTickets,
  TaskInvoices,
  TaskReports,
} from "./pages";
import InvoiceDetails from "./pages/invoices/InvoiceDetails";
import Transactions from "./pages/Transactions/Transactions";
import Plans from "./pages/Plans/Plans";
import PlanDetail from "./pages/Plans/PlanDetail";
import MakeElementWrapper from "./pages/Plans/MakeElementWrapper";
//pages

const AddTeam = Loadable(lazy(() => import("./pages/add-team/AddTeam")));
// const Chat = Loadable(lazy(() => import("./pages/chat/Chat")));
// const GroupChat = Loadable(lazy(() => import("./pages/chat/GroupChat")));
const Chats = Loadable(lazy(() => import("./pages/Chats/Chats")));
const CreateWorkspace = Loadable(
  lazy(() => import("./pages/workspaces/CreateWorkspace"))
);
const CreateWorkspaceProject = Loadable(
  lazy(() => import("./pages/create-workspace-project/CreateWorkspaceProject"))
);
const Dashboard = Loadable(lazy(() => import("./pages/dashboard/Dashboard")));
const EditProfile = Loadable(lazy(() => import("./pages/profile/EditProfile")));
const EditWorkspace = Loadable(
  lazy(() => import("./pages/workspaces/EditWorkspace"))
);
const EditWorkspaceProject = Loadable(
  lazy(() => import("./pages/edit-workspace-project/EditWorkspaceProject"))
);
const ForgotPassword = Loadable(
  lazy(() => import("./pages/forgot-password/ForgotPassword"))
);
const Invitations = Loadable(
  lazy(() => import("./pages/workspaces/Invitations"))
);
const TeamMemberProfile = Loadable(
  lazy(() => import("./pages/member-profile/TeamMemberProfile"))
);
const TermsAndConditions = Loadable(
  lazy(() => import("./pages/terms-and-conditions/TermsAndConditions.jsx"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("./pages/privacy-policy/PrivacyPolicy"))
);
const Login = Loadable(lazy(() => import("./pages/login/Login")));
const PaymentPlans = Loadable(
  lazy(() => import("./pages/Payment/PaymentPlans"))
);
const Payment = Loadable(lazy(() => import("./pages/Payments/Payment")));
const PlansViaLink = Loadable(
  lazy(() => import("./pages/Payment/PlansViaLink"))
);
const PaymentSuccess = Loadable(
  lazy(() => import("./pages/Payments/PaymentSuccess"))
);
const Page404 = Loadable(lazy(() => import("./pages/page-404/Page404")));
const Profile = Loadable(lazy(() => import("./pages/profile/Profile")));
const Register = Loadable(lazy(() => import("./pages/register/Register")));
const Settings = Loadable(lazy(() => import("./pages/settings/Settings")));
const Team = Loadable(lazy(() => import("./pages/team/Team")));
const VerifiedTasks = Loadable(
  lazy(() => import("./pages/verified-tasks/VerifiedTasks"))
);
const WorkspaceDetail = Loadable(
  lazy(() => import("./pages/workspace-detail/WorkspaceDetail"))
);
const WorkspaceProject = Loadable(
  lazy(() => import("./pages/workspace-project/WorkspaceProject"))
);
const WorkSpaces = Loadable(
  lazy(() => import("./pages/workspaces/WorkSpaces"))
);
const TaskCalendar = Loadable(
  lazy(() => import("./pages/task-calendar/TaskCalendar"))
);
const TargetDatePassedTasks = Loadable(
  lazy(() => import("./pages/target-date-passed-tasks/TargetDatePassedTasks"))
);
const AllTasks = Loadable(lazy(() => import("./pages/all-tasks/AllTasks")));
const AllCompletedTasks = Loadable(
  lazy(() => import("./pages/all-completed-tasks/AllCompletedTasks"))
);
const ProjectTemplates = Loadable(
  lazy(() => import("./pages/projectTemplates/ProjectTemplates"))
);
const AddProjectTemplate = Loadable(
  lazy(() => import("./pages/projectTemplates/components/AddProjectTemplate"))
);
const EditProjectTemplate = Loadable(
  lazy(() => import("./pages/projectTemplates/components/EditProjectTemplate"))
);
const TemplateProject = Loadable(
  lazy(() => import("./pages/template-project/TemplateProject"))
);
const TransactionHistory = Loadable(
  lazy(() => import("./pages/TransactionHistory/TransactionHistory"))
);
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "calendar", element: <TaskCalendar /> },
        { path: "team", element: <Team /> },
        { path: "transactions", element: <Transactions /> },
        { path: "plans", element: <Plans /> },
        { path: "plan-detail/:id", element: <MakeElementWrapper /> },
        { path: "payment-history", element: <TransactionHistory /> },
        { path: "team/add-team", element: <AddTeam /> },
        { path: "profile", element: <NewProfile /> },
        // { path: "profile", element: <Profile /> },
        // { path: "profile/edit-profile", element: <EditProfile /> },
        { path: "settings", element: <Settings /> },
        { path: "workspaces", element: <WorkSpaces /> },
        {
          path: "target-date-passed-tasks",
          element: <TargetDatePassedTasks />,
        },
        {
          path: "all-tasks",
          element: <AllTasks />,
        },
        {
          path: "all-completed-tasks",
          element: <AllCompletedTasks />,
        },
        { path: "project-templates", element: <ProjectTemplates /> },
        { path: "templates/create", element: <AddProjectTemplate /> },
        {
          path: "templates/edit-template/:template_id",
          element: <EditProjectTemplate />,
        },
        {
          path: "project-templates/:template_id",
          element: <TemplateProject />,
        },
        { path: "invitations", element: <Invitations /> },
        { path: "workspaces/create", element: <CreateWorkspace /> },
        { path: "workspaces/edit/:workspace_id", element: <EditWorkspace /> },
        { path: "workspaces/:id", element: <WorkspaceDetail /> },
        {
          path: "workspaces/:workspace_id/:project_id",
          element: <VerifiedTasks />,
        },
        { path: "workspaces/projects/:id", element: <WorkspaceProject /> },
        {
          path: "workspaces/create-project",
          element: <CreateWorkspaceProject />,
        },
        {
          path: "workspaces/edit-project/:id",
          element: <EditWorkspaceProject />,
        },
        // { path: "chat", element: <Chat /> },
        { path: "chats", element: <Chats /> },
        // { path: "group-chat", element: <GroupChat /> },
        { path: "member-profile/:id", element: <TeamMemberProfile /> },
        {
          path: "workspaces/copied-task-link/:project_id/:section_id/:task_id",
          element: <TaskLinkController />,
        },
        {
          path: "clients",
          element: <Clients />,
        },
        {
          path: "reports",
          element: <TaskReports />,
        },
        {
          path: "reports/add-report",
          element: <AddReport />,
        },
        {
          path: "invoices",
          element: <TaskInvoices />,
        },
        {
          path: "invoices/add-invoice",
          element: <AddInvoice />,
        },
        {
          path: "invoices/edit-invoice",
          element: <AddInvoice />,
        },
        {
          path: "invoices/detail-invoice",
          element: <InvoiceDetails />,
        },
        {
          path: "support",
          element: <SupportTickets />,
        },
        {
          path: "support/:id",
          element: <SupportTicketDetails />,
        },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "terms-and-conditions", element: <TermsAndConditions /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "verification", element: <EmailVerification /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "payment-plans", element: <PaymentPlans /> },
        { path: "plansLink", element: <PlansViaLink /> },
        { path: "payment-success", element: <PaymentSuccess /> },
        { path: "payment", element: <Payment /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
