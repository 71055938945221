import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Tooltip, Box, Stack, Grid, Button } from "@mui/material";
import React from "react";
import { CircularLoader } from "src/components";
import { TASK_STATUS_OPTIONS } from "src/constants";
import { isColorLight } from "src/utils";

/* <<--------------------------------------------------------->> */
const CalendarComponent = ({ loading, events, onEventClick }) => {
  /* <<--------------------------------------------------------->> */

  /* <<--------------------------------------------------------->> */
  const getStatusProps = (value) => {
    if (!value) {
      return { color: "" };
    }
    if (String(value) === "0") {
      return { color: "#FFC107" };
    }
    let [found] = TASK_STATUS_OPTIONS.filter(
      (item) => String(item.value) === String(value)
    );
    if (found) {
      return found;
    }
  };
  const renderEventContent = (info) => {
    const { more, bg } = info.event._def.extendedProps;
    if (!more) {
      return <></>;
    }
    return (
      <div style={{ overflow: "hidden", width: "100%" }}>
        <Tooltip title={more?.task_title}>
          <div
            style={{
              color: isColorLight(bg) ? "#000" : "#fff",
              backgroundColor: bg,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              padding: "2px 4px",
              // fontSize: "10px",
              fontWeight: "500",
              borderRadius: "3px",
            }}
          >
            {more?.task_title}
          </div>
        </Tooltip>
      </div>
    );
  };
  /* <<--------------------------------------------------------->> */
  if (loading) {
    return (
      <>
        <CircularLoader />
      </>
    );
  }
  return (
    <div className="full-calendar">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "today prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        eventContent={renderEventContent}
        eventClick={onEventClick}
        events={events}
        initialView="dayGridMonth"
        // editable={true}
        // selectable={true}
        selectMirror={true}
        dayMaxEvents={2}
        weekends={true}
      />
    </div>
  );
};

export default CalendarComponent;
