import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../Iconify";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "src/hooks";
import { _update_card } from "src/DAL/payment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { set_to_localStorage } from "src/utils";
import { _get_user_profile } from "src/DAL";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#00AB55",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      padding: "20px",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid black",
    },
    invalid: {
      border: "1px solid #fa755a",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function ManageCard({ openModal, handleCloseModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const {
    dispatch_get_user_profile,
    dispatch_set_user_profile,
    dispatch_set_plan_limits,
    dispatch_set_current_plan,
  } = useAppContext();
  const user = dispatch_get_user_profile();
  console.log(user, "user_idddddddddddddd");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const getUserProfile = async () => {
    try {
      const result = await _get_user_profile();
      console.log(result, "customerDetailllsss");
      if (result.code == 200) {
        // connectSocket(result.customer.user_id);
        dispatch_set_user_profile(result.customer);
        dispatch_set_plan_limits(result.count_data);
        dispatch_set_current_plan(result.current_plans);
        set_to_localStorage("user", result.customer);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching user data");
    }
  };

  const submitUpdateCard = (e) => {
    e.preventDefault();
    try {
      setSaveBtnLoading(true);
      const cardElement = elements.getElement(CardElement);
      stripe.createToken(cardElement).then(async function (result) {
        if (result.error) {
          console.log(result, "resultttttttttttttt");
          enqueueSnackbar(result.error.message, { variant: "error" });
          setSaveBtnLoading(false);
          return;
        }
        const req = {
          token: result.token.id,
        };
        console.log(req, "reqqqqqqqqq");
        const apiResult = await _update_card(req);
        enqueueSnackbar(apiResult.message, {
          variant: apiResult.code == 200 ? "success" : "error",
        });
        if (apiResult.code == 200) {
          handleCloseModal();
          getUserProfile();
        }
        setSaveBtnLoading(false);
      });
    } catch (error) {
      console.log(error, " catched while updating the card");
      setSaveBtnLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ p: 1 }}>
          <div className="d-flex justify-content-between align-items-center">
            <>Manage Card</>
            <IconButton onClick={handleCloseModal}>
              <Iconify icon="charm:cross" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ pt: 1 }}>
          {user?.card_info?.is_card_available && (
            <div className="mb-5">
              <Typography fontWeight={500} fontSize={"15px"}>
                Default Card
              </Typography>
              <Card sx={{ p: 2, borderRadius: "7px" }}>
                <div className="d-flex justify-content-between align-items-center ">
                  <div>
                    <Iconify
                      icon={
                        user?.card_info?.card_brand === "Visa"
                          ? "logos:visa"
                          : user?.card_info?.card_brand === "MasterCard"
                          ? "logos:mastercard"
                          : user?.card_info?.card_brand === "Discover"
                          ? "logos:discover"
                          : user?.card_info?.card_brand === "UnionPay"
                          ? "logos:unionpay"
                          : user?.card_info?.card_brand === "Diners Club"
                          ? "fa-brands:cc-diners-club"
                          : user?.card_info?.card_brand === "American Express"
                          ? "fontisto:american-express"
                          : user?.card_info?.card_brand === "JCB"
                          ? "logos:jcb"
                          : "fluent-mdl2:payment-card"
                      }
                      width={50}
                      height={24}
                    />
                  </div>
                  <Typography>
                    **** **** **** {user?.card_info?.card_number}
                  </Typography>
                </div>
              </Card>
            </div>
          )}
          <form onSubmit={submitUpdateCard}>
            <div className="mb-3">
              <Typography fontWeight={500} fontSize={"15px"}>
                Enter New Card
              </Typography>
              <div
                style={{
                  border: "1px solid lightgrey",
                  marginTop: "8px",
                  padding: "16px 8px",
                  borderRadius: "8px",
                }}
              >
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>
            </div>
            <div className="mb-2">
              <LoadingButton
                loading={saveBtnLoading}
                variant="contained"
                fullWidth
                type="submit"
              >
                Save
              </LoadingButton>
            </div>
          </form>
          <div>
            *You can only have one card in your account. After changing card
            your future bills will be charged from new card.
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ManageCard;
