import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { filter } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _createGroupChat, _getChatTeamList } from "src/DAL";
import { Iconify, MuiDrawer, Scrollbar } from "src/components";
import ImageSelector from "src/components/ImageSelector";
import StyledChatSearch from "src/components/search/StyledChatSearch";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";
import { upload_image } from "src/utils";

function AddChatDrawer({
  open,
  onClose,
  handleAddNewChat,
  isLoading,
  setMessageListPage,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    setChatList,
    getModifiedChatItem,
    currentChat,
    setCurrentChat,
    socketEmit,
    setMessageList,
    setMessageCount,
    chatType: contextChatType,
  } = useAppContext();
  const [chatType, setChatType] = useState(contextChatType);
  const [teamList, setTeamList] = useState([]);
  const [loadingTeam, setLoadingTeam] = useState(false);
  const getTeamList = async () => {
    setLoadingTeam(true);
    const result = await _getChatTeamList();
    setTeamList(result.chat_team_list);
    setLoadingTeam(false);
  };
  //////////////Search user by name//////////////////////////////////////////

  const [searchValue, setSearchValue] = useState("");

  const handleLocalChatSearch = (input, array) => {
    const get_merged = (item) => {
      return `${item.first_name} ${item.last_name}`;
    };
    let filtered = filter(
      array,
      (item) =>
        get_merged(item).toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === "") {
      return data;
    }
    if (searchValue !== "") {
      let filtered = handleLocalChatSearch(searchValue, data);
      return filtered;
    }
  };
  ///////////////////////////////////////////////////////////////////////////////

  /////////////////////////Add Group//////////////////////////////////////////////
  const [checked, setChecked] = useState({});
  const [createGroupStep, setCreateGroupStep] = useState("1");
  const [newGroupName, setNewGroupName] = useState("");
  const [groupImage, setGroupImage] = useState();
  const [createGroupLoading, setCreateGroupLoading] = useState(false);
  const handleToggle = (item) => () => {
    if (!checked[item.user_id]) {
      let temp = item;
      delete temp.allow_add_project;
      delete temp.allow_drag_drop;
      delete temp._id;
      delete temp.leave_reason;
      setChecked((prev) => {
        return {
          ...prev,
          [item.user_id]: temp,
        };
      });
    } else {
      let temp = checked;
      delete temp[item.user_id];
      setChecked({ ...temp });
    }
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    try {
      if (newGroupName.trim() == "") {
        enqueueSnackbar("Please provide valid Group name", {
          variant: "error",
        });
        return;
      }
      setCreateGroupLoading(true);
      let image = "";
      if (groupImage) {
        const imageResult = await upload_image(groupImage);
        if (imageResult.code == 200) {
          image = imageResult.path;
        }
      }
      const req_obj = {
        group_name: newGroupName,
        group_image: image,
        start_time: "",
        end_time: "",
        team: Object.values(checked),
      };
      const result = await _createGroupChat(req_obj);
      if (result.code == 200) {
        onClose();
        setChatList((prev) => {
          return [getModifiedChatItem(result.group), ...prev];
        });
        setCurrentChat(getModifiedChatItem(result.group));
        setMessageList([]);
        setMessageListPage(0);
        setMessageCount(0);
        enqueueSnackbar("Group created successfully", { variant: "success" });
        socketEmit("addNewChat", result.group);
        console.log("helllo from func");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched while creating group");
    } finally {
      setCreateGroupLoading(false);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setChatType(contextChatType);
  }, [contextChatType]);
  useEffect(() => {
    open && getTeamList();
    return () => {
      // setChatType(contextChatType);
      setNewGroupName("");
      setGroupImage();
      setSearchValue("");
      setCreateGroupStep("1");
      setChecked({});
    };
  }, [open]);
  return (
    <MuiDrawer
      isOpen={open}
      paperProps={{ width: { xs: 1, sm: 400 } }}
      onClose={onClose}
      title="Create New"
      headerComponent={
        <TabContext value={chatType}>
          <TabList onChange={(e, val) => setChatType(val)}>
            <Tab sx={{ width: "50%" }} label="Chat" value="all" />
            <Tab sx={{ width: "50%" }} label="Group" value="groups" />
          </TabList>
          {!loadingTeam && (
            <>
              <TabPanel value="all">
                <div style={{ position: "relative", minHeight: "100%" }}>
                  <div className="search-user-list row  mb-2">
                    <span
                      className="col-12"
                      style={{ paddingLeft: "30px", paddingRight: "30px" }}
                    >
                      <StyledChatSearch
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeHolder="Search..."
                      />
                    </span>
                  </div>
                  <Scrollbar sx={{ height: "450px" }}>
                    <Stack spacing={2} sx={{ p: 2, paddingTop: 0 }}>
                      {/* <Grid gap={3} container maxWidth="sm">
                      <Grid item xs={12}> */}
                      {getFilteredList(teamList).map((user, index) => {
                        return (
                          <ButtonBase
                            key={index}
                            disabled={isLoading}
                            className="team-list-parent my-2 border w-100"
                            onClick={() => handleAddNewChat(user)}
                          >
                            <div className="d-flex align-items-center">
                              <div>
                                {user.image !== "" ? (
                                  <Tooltip
                                    arrow
                                    title={`${user.first_name} ${user.last_name}`}
                                  >
                                    <Avatar
                                      src={s3baseUrl + user.image}
                                      className="chat-avatar"
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    arrow
                                    title={`${user.first_name} ${user.last_name}`}
                                  >
                                    <Avatar
                                      alt={`${user.first_name} ${user.last_name}`}
                                      className="chat-avatar"
                                    >
                                      {user.first_name.substring(0, 1)}
                                    </Avatar>
                                  </Tooltip>
                                )}
                              </div>
                              <div className="ml-2">
                                <Typography>
                                  {user.first_name + " " + user.last_name}
                                </Typography>
                              </div>
                            </div>
                          </ButtonBase>
                        );
                      })}
                      {/* </Grid>
                    </Grid> */}
                    </Stack>
                  </Scrollbar>
                </div>
              </TabPanel>
              <TabPanel value="groups">
                {createGroupStep == "1" && (
                  <>
                    <div className="search-user-list row  mb-2">
                      <span
                        className="col-12"
                        style={{ paddingLeft: "30px", paddingRight: "30px" }}
                      >
                        <StyledChatSearch
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          placeHolder="Search..."
                        />
                      </span>
                    </div>
                    <List
                      dense
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        mx: "auto",
                      }}
                    >
                      <Scrollbar sx={{ height: "76vh" }}>
                        {getFilteredList(teamList).map((item) => {
                          return (
                            <ListItem
                              key={item._id}
                              onClick={handleToggle(item)}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  //   onChange={handleToggle(item)}
                                  checked={checked[item.user_id] ? true : false}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemAvatar>
                                  {item.image !== "" ? (
                                    <Tooltip
                                      arrow
                                      title={`${item.first_name} ${item.last_name}`}
                                    >
                                      <Avatar
                                        src={s3baseUrl + item.image}
                                        className="chat-avatar"
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      arrow
                                      title={`${item.first_name} ${item.last_name}`}
                                    >
                                      <Avatar
                                        alt="Remy Sharp"
                                        className="chat-avatar"
                                      >
                                        {item.first_name.substring(0, 1)}
                                      </Avatar>
                                    </Tooltip>
                                  )}
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography>
                                      {item.first_name + " " + item.last_name}
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </Scrollbar>
                    </List>
                    {getFilteredList(teamList).length > 0 && (
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="contained"
                          sx={{ m: "auto" }}
                          onClick={() => {
                            if (Object.keys(checked).length > 0) {
                              setCreateGroupStep("2");
                            } else {
                              enqueueSnackbar("Select atleast one member", {
                                variant: "error",
                              });
                            }
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    )}
                  </>
                )}
                {createGroupStep == "2" && (
                  <>
                    <div>
                      <IconButton
                        sx={{ mb: 1, mx: 1 }}
                        onClick={() => setCreateGroupStep("1")}
                      >
                        <Iconify icon="ep:back" />
                      </IconButton>
                    </div>
                    <ImageSelector setFile={setGroupImage} />
                    <TextField
                      sx={{ mt: 2 }}
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                      required
                      fullWidth
                      label="Group Name"
                    />
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "50px" }}
                    >
                      <LoadingButton
                        loading={createGroupLoading}
                        variant="contained"
                        onClick={handleCreateGroup}
                      >
                        Create
                      </LoadingButton>
                    </div>
                  </>
                )}
              </TabPanel>
            </>
          )}
        </TabContext>
      }
    >
      {loadingTeam && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <CircularProgress />
        </div>
      )}
    </MuiDrawer>
  );
}

export default AddChatDrawer;
