import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  _delete_invoice,
  _delete_report,
  _invoice_list,
  _invoice_list_as_client,
  _report_list,
  _update_invoice_status,
} from "src/DAL";
import {
  CircularLoader,
  Iconify,
  Label,
  MuiDialog,
  NoData,
  Page,
  Scrollbar,
  SearchNotFound,
} from "src/components";
import AddNewCard from "src/components/AddNewCard";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";
import {
  del_from_local_storage,
  get_from_localStorage,
  set_to_localStorage,
} from "src/utils";
import { convertCurrencyToSign } from "src/utils/constant";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "total_time", label: "Time", alignRight: false },
  { id: "price", label: "Amount", alignRight: false },
  { id: "discount", label: "Discount", alignRight: false },
  { id: "final_price", label: "Sub Total", alignRight: false },
  { id: "payment", label: "Payment", alignRight: false },
  { id: "pdf_invoice", label: "PDF Invoice", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  {
    id: "createdAt",
    label: "Created At",
    alignRight: false,
    alignCenter: true,
  },
  { id: "", alignRight: false } /* to add empty column */,
];
function TaskInvoices() {
  const { dispatch_get_user_profile } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [menuOpen, setMenuOpen] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState();
  const [currentInvoice, setCurrentInvoice] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    get_from_localStorage("invoiceTab") ?? "admin"
  );
  const navigate = useNavigate();
  const handleNav = () => {
    navigate("/invoices/add-invoice");
  };
  const handleOpenMenu = (e) => {
    setMenuOpen(e.target);
  };
  const handleCloseMenu = () => {
    setMenuOpen(null);
    handleCloseSubMenu();
    setCurrentInvoice();
  };
  const handleOpenSubMenu = (e) => {
    setSubMenuOpen(e.target);
  };
  const handleCloseSubMenu = () => {
    setSubMenuOpen(null);
  };
  const getInvoiceList = async () => {
    try {
      setLoading(true);
      let result;
      if (currentTab == "admin") {
        result = await _invoice_list();
      } else {
        result = await _invoice_list_as_client(
          dispatch_get_user_profile().user_id
        );
      }
      if (result.code == 200) {
        setInvoices(result.invoice_list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catched while fetching list");
    } finally {
      setLoading(false);
    }
  };
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const handleNavEdit = () => {
    navigate("/invoices/edit-invoice", {
      state: { ...currentInvoice },
    });
  };
  const handleNavDetails = () => {
    navigate("/invoices/detail-invoice", {
      state: { ...currentInvoice },
    });
  };
  const changeInvoiceStatus = async (status) => {
    try {
      if (!currentInvoice) {
        console.log("no invoice selected");
        return;
      }
      const result = await _update_invoice_status({
        invoice_id: currentInvoice._id,
        status,
      });
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setInvoices((prev) =>
          prev.map((invoice) => {
            if (invoice._id == currentInvoice._id) {
              return { ...invoice, invoice_status: status };
            } else {
              return { ...invoice };
            }
          })
        );
        handleCloseMenu();
      }
    } catch (error) {
      console.log(error, " catched while changing invoice status");
    }
  };
  const menuOptions = [
    {
      label: "Edit",
      clickHandler: handleNavEdit,
    },
    { label: "Details", clickHandler: handleNavDetails },
    { label: "Change Payment Status", clickHandler: handleOpenSubMenu },
    { label: "Delete", clickHandler: openDeleteDialog },
  ];
  const subMenuOptions = [
    {
      label: "Pending",
      value: "pending",
      clickHandler: () => {
        changeInvoiceStatus("pending");
      },
    },
    {
      label: "Paid",
      value: "paid",
      clickHandler: () => {
        changeInvoiceStatus("paid");
      },
    },
    {
      label: "Verified",
      value: "verified",
      clickHandler: () => {
        changeInvoiceStatus("verified");
      },
    },
  ];
  const getFilteredSubMenu = () => {
    if (
      dispatch_get_user_profile().user_id ==
      currentInvoice?.invoice_client_data?.user_id
    ) {
      if (currentInvoice.invoice_status == "verified") {
        return [];
      } else {
        return subMenuOptions.filter((item) => item.label !== "Verified");
      }
    } else {
      return subMenuOptions;
    }
  };
  const getFilteredMenuOptions = (draft) => {
    if (currentInvoice?.is_draft) {
      return [...menuOptions].filter(
        (item) =>
          item.label !== "Details" && item.label !== "Change Payment Status"
      );
    } else {
      if (
        dispatch_get_user_profile().user_id ==
        currentInvoice?.invoice_client_data?.user_id
      ) {
        if (currentInvoice.invoice_status == "verified") {
          return menuOptions.filter(
            (item) =>
              item.label !== "Change Payment Status" && item.label !== "Edit"
          );
        } else {
          return [...menuOptions].filter((item) => item.label !== "Edit");
        }
      } else {
        return [...menuOptions].filter((item) => item.label !== "Edit");
      }
    }
  };
  const handleDeleteInvoice = async () => {
    try {
      if (!currentInvoice) {
        console.log("no invoice selected");
        return;
      }
      setDeleteLoading(true);
      const result = await _delete_invoice(currentInvoice._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setInvoices((prev) =>
          prev.filter((invoice) => invoice._id !== currentInvoice._id)
        );
        closeDeleteDialog();
        handleCloseMenu();
      }
    } catch (error) {
      console.log(error, " fetched while deleting invoice");
    } finally {
      setDeleteLoading(false);
    }
  };
  const filteredInvoices = () => {
    if (!filterName) {
      return [...invoices];
    } else {
      return invoices?.filter((x) =>
        x.title.toLowerCase().includes(filterName.toLowerCase())
      );
    }
  };
  const isReportNotFound = filteredInvoices().length === 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  useEffect(() => {
    setFilterName("");
    getInvoiceList();
    set_to_localStorage("invoiceTab", currentTab);
  }, [currentTab]);
  // if (loading) {
  //   return (
  //     <Page title="Invoices">
  //       <Container maxWidth="xl">
  //         <Stack
  //           direction="row"
  //           alignItems="center"
  //           justifyContent="space-between"
  //           className="mb-4"
  //         >
  //           <Typography variant="h4">Invoices</Typography>
  //           {currentTab !== "client" && (
  //             <Button
  //               onClick={handleNav}
  //               variant="contained"
  //               startIcon={<Iconify icon="eva:plus-fill" />}
  //             >
  //               Add Invoice
  //             </Button>
  //           )}
  //         </Stack>
  //         <CircularLoader />
  //       </Container>
  //     </Page>
  //   );
  // }
  return (
    <Page title="Invoices">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="mb-4"
        >
          <Typography variant="h4">Invoices</Typography>
          {currentTab !== "client" && (
            <Button
              onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Invoice
            </Button>
          )}
        </Stack>
        {/* {invoices.length == 0 && !loading && (
          <Tabs value={currentTab} onChange={(e, val) => setCurrentTab(val)}>
            <Tab label="Sent Invoices" value="admin" />
            <Tab label="Received Invoices" value={"client"} />
          </Tabs>
        )} */}
        {/* {invoices.length !== 0 && ( */}
        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            minHeight: "90vh",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"}>
              <Tabs
                value={currentTab}
                onChange={(e, val) => {
                  setLoading(true);
                  setCurrentTab(val);
                }}
              >
                <Tab label="Sent Invoices" value="admin" />
                <Tab label="Received Invoices" value={"client"} />
              </Tabs>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <ListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
                moduleName="Invoices"
              />
            </Grid>
          </Grid>
          {loading && (
            <Grid item xs={12} minHeight={"60vh"}>
              <CircularLoader />
            </Grid>
          )}
          {!loading && invoices.length > 0 && (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {filteredInvoices()
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            title,
                            total_time,
                            total_price,
                            _id,
                            createdAt,
                            discount,
                            final_price,
                            is_draft,
                            pdf_invoice,
                            invoice_status,
                            currency,
                          } = row;
                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (is_draft) {
                                  navigate("/invoices/edit-invoice", {
                                    state: { ...row },
                                  });
                                } else {
                                  navigate("/invoices/detail-invoice", {
                                    state: { ...row },
                                  });
                                }
                              }}
                            >
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {rowsPerPage * page + (index + 1)}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Typography variant="subtitle2" noWrap>
                                  {title}
                                </Typography>
                              </TableCell>
                              <TableCell>{total_time}</TableCell>
                              <TableCell>{`${convertCurrencyToSign(
                                currency
                              )} ${total_price}`}</TableCell>
                              <TableCell>
                                {discount
                                  ? `${convertCurrencyToSign(
                                      currency
                                    )} ${Number(discount)}`
                                  : ""}
                              </TableCell>
                              <TableCell>{`${convertCurrencyToSign(
                                currency
                              )} ${final_price}`}</TableCell>
                              <TableCell>
                                <Label
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  variant="ghost"
                                  color={
                                    invoice_status == "pending"
                                      ? "error"
                                      : invoice_status == "verified"
                                      ? "success"
                                      : "warning"
                                  }
                                >
                                  {capitalCase(invoice_status)}
                                </Label>
                              </TableCell>
                              <TableCell>
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  style={{ display: "inline" }}
                                >
                                  {pdf_invoice && (
                                    <a
                                      target="_blank"
                                      href={s3baseUrl + pdf_invoice}
                                    >
                                      <Iconify
                                        icon="vscode-icons:file-type-pdf2"
                                        sx={{ height: "32px", width: "32px" }}
                                      />
                                    </a>
                                  )}
                                  {!pdf_invoice && (
                                    <Tooltip title="No pdf available">
                                      <div>
                                        <Iconify
                                          icon="mdi:file-document-error"
                                          sx={{
                                            height: "32px",
                                            width: "32px",
                                            color: "#dd2025",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <Label
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  variant="outlined"
                                  color={is_draft ? "warning" : "success"}
                                >
                                  {is_draft ? "Draft" : "Sent"}
                                </Label>
                              </TableCell>
                              <TableCell>
                                {moment(createdAt).format("LLL")}
                              </TableCell>
                              <TableCell
                                // align="right"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <IconButton
                                  sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentInvoice(row);
                                    handleOpenMenu(e);
                                  }}
                                >
                                  <Iconify
                                    sx={{ color: "black" }}
                                    icon="fluent:more-vertical-16-filled"
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                          // });
                        })}
                      {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                      <Menu
                        open={Boolean(menuOpen)}
                        anchorEl={menuOpen}
                        onClose={handleCloseMenu}
                      >
                        {getFilteredMenuOptions(currentInvoice?.is_draft).map(
                          (item) => (
                            <MenuItem onClick={item.clickHandler}>
                              {item.label}
                            </MenuItem>
                          )
                        )}
                      </Menu>
                      <Menu
                        open={Boolean(subMenuOpen)}
                        anchorEl={subMenuOpen}
                        onClose={handleCloseSubMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {getFilteredSubMenu().map((option) => (
                          <MenuItem
                            key={option.label}
                            onClick={option.clickHandler}
                            selected={
                              currentInvoice?.invoice_status == option.value
                            }
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableBody>

                    {isReportNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={filteredInvoices()?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
          {currentTab == "client" && (
            <NoData
              dataLength={filteredInvoices().length}
              loading={loading}
              // taskSection={true}
              title="No Invoices found"
            />
          )}
          {/* )} */}
          {invoices.length == 0 && !loading && currentTab !== "client" && (
            <AddNewCard title="invoice" handleClick={handleNav} />
          )}
        </Card>
      </Container>
      <MuiDialog
        title="Delete Invoice"
        message="Are you sure you want to delete this invoice?"
        open={deleteDialogOpen}
        onToggle={closeDeleteDialog}
        onAgree={handleDeleteInvoice}
        loading={deleteLoading}
      />
    </Page>
  );
}

export default TaskInvoices;
