import { get_from_localStorage, invokeApi } from 'src/utils';

export const _add_task_section = async (data) => {
  const requestObj = {
    path: `api/section/add_section`,
    method: 'POST',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_section = async (data, id) => {
  const requestObj = {
    path: `api/section/edit_section/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_section_without_task = async (data, id) => {
  const requestObj = {
    path: `api/section/delete_section_without_task/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_section_with_task = async (data, id) => {
  const requestObj = {
    path: `api/section/delete_section/${id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_section_order = async (data, id) => {
  const requestObj = {
    path: `api/section/change_section_order/${id}`,
    method: 'POST',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _make_section_default = async (data, id) => {
  const requestObj = {
    path: `api/section/make_section_default/${id}`,
    method: 'POST',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
}