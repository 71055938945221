import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useSnackbar } from "notistack";
import {
  _user_password_reset,
  _verify_email_reset_code,
  _send_email_reset_code,
} from "src/DAL/auth";
import { Iconify } from "src/components";
import { motion } from "framer-motion";
import { MAX_PASSWORD_LIMIT, MIN_PASSWORD_LIMIT } from "src/constants";
// ----------------------------------------------------------------------

export default function ForgotForm({step, setStep}) {
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
    verificationCode: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const { enqueueSnackbar } = useSnackbar();
  const ResetSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(MIN_PASSWORD_LIMIT, "Password Too Short!")
      // .max(MAX_PASSWORD_LIMIT, "Password Too Long!")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .min(MIN_PASSWORD_LIMIT, "Password Too Short!")
      // .max(MAX_PASSWORD_LIMIT, "Password Too Long!")
      .equals(
        [formInputs.newPassword],
        "New Password and Confirm Password does not match"
      )
      .required("Please add your new Password"),
  });
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = await ResetSchema.validate(formInputs).catch((er) => {
      if (er) {
        return er.errors;
      }
      return [];
    });
    if (errors.length > 0) {
      errors.map((er) => {
        enqueueSnackbar(er, { variant: "error" });
      });
      return;
    }
    const data = new FormData();
    data.append("email", formInputs.email.trim());
    data.append("password", formInputs.password.trim());
    data.append("confirm_password", formInputs.confirmPassword.trim());
    console.log(...data, "reset-req");

    setIsLoading(true);
    const result = await _user_password_reset({
      email: formInputs.email,
      password: formInputs.password,
      confirm_password: formInputs.confirmPassword,
    });
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Password Reset Successfully", {
        variant: "success",
        autoHideDuration: 4000,
      });
      navigate("/login");
      //nav to login, show success modal first
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNav = () => {
    setStep((prev) => prev - 1);
  };
  const handleGetCode = async () => {
    const EmailSchema = Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required");

    let invalid = false;
    await EmailSchema.validate(formInputs.email).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    const data = { email: formInputs.email.trim() };
    setProcessing(true);
    const result = await _send_email_reset_code(data);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      setStep(2);
      enqueueSnackbar("Code has been sent to your email", {
        variant: "success",
      });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleVerifyCode = async () => {
    if (formInputs.verificationCode === "") {
      enqueueSnackbar("Code cannot be empty", { variant: "error" });
      return;
    }
    if (formInputs.email === "") {
      enqueueSnackbar("Email not provided", { variant: "error" });
      return;
    }
    const data = {
      email: formInputs.email.trim(),
      verification_code: formInputs.verificationCode.trim(),
    };
    setProcessing(true);
    const result = await _verify_email_reset_code(data);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      setStep(3);
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleReset = async (e) => {
    e.preventDefault();
    const ResetSchema = Yup.object().shape({
      newPassword: Yup.string()
        .min(5, "New Password Too Short!")
        // .max(30, "New Password Too Long!")
        .required("New Password is required"),
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      confirmPassword: Yup.string()
        .equals(
          [formInputs.newPassword],
          "New Password and Confirm Password does not match"
        )
        .required("Confirm Password is Required"),
    });

    let invalid = false;
    await ResetSchema.validate(formInputs).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }

    const data = {
      email: formInputs.email,
      password: formInputs.newPassword,
      confirm_password: formInputs.confirmPassword,
    };
    setProcessing(true);
    const result = await _user_password_reset(data);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Your password has been reset", { variant: "success" });
      navigate("/login");
      setStep(1);
      setFormInputs({
        email: "",
        newPassword: "",
        confirmPassword: "",
        verificationCode: "",
      });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ---------------------------------------------------------
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Stack direction="row" alignItems="center" justifyContent="start">
          {/* {step !== 1 && (
            <IconButton onClick={handleNav}>
              <Iconify icon="akar-icons:arrow-left" />
            </IconButton>
          )} */}
        </Stack>
        {step === 1 && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              animationDuration: 1,
              animationDelay: 1,
            }}
            transition={{ type: "tween" }}
          >
            <Stack spacing={3}>
              <TextField
                value={formInputs.email}
                onChange={handleChange}
                name="email"
                required
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
              />
              <div className="centered-row">
                <LoadingButton
                  variant="contained"
                  loading={processing}
                  onClick={handleGetCode}
                >
                  Get code
                </LoadingButton>
              </div>
            </Stack>
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              animationDuration: 1,
              animationDelay: 1,
            }}
            transition={{ type: "tween" }}
          >
            <Stack spacing={3}>
              <TextField
                value={formInputs.verificationCode}
                onChange={handleChange}
                name="verificationCode"
                required
                fullWidth
                autoComplete="username"
                type="text"
                label="Code"
              />
              <div className="centered-row">
                <LoadingButton
                  variant="contained"
                  loading={processing}
                  onClick={handleVerifyCode}
                >
                  Verify code
                </LoadingButton>
              </div>
            </Stack>
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              animationDuration: 1,
              animationDelay: 1,
            }}
            transition={{ type: "tween" }}
          >
            <Stack spacing={3}>
              <TextField
                value={formInputs.newPassword}
                onChange={handleChange}
                fullWidth
                name="newPassword"
                required
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label="New Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                value={formInputs.confirmPassword}
                onChange={handleChange}
                fullWidth
                name="confirmPassword"
                required
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label="Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Stack justifyContent="center" alignItems="center">
                <div style={{ width: "fit-content" }}>
                  <LoadingButton
                    onClick={handleReset}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={processing}
                  >
                    Reset
                  </LoadingButton>
                </div>
              </Stack>
            </Stack>
          </motion.div>
        )}
      </Stack>
    </form>
  );
}
