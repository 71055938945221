import { Box, Button, CircularProgress, Container, Tab } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import AddChatDrawer from "./components/AddChatDrawer";
import {
  _addUserInChatList,
  _getAllChats,
  _getChatMessages,
  _getGroupChatList,
} from "src/DAL";
import { useSnackbar } from "notistack";
import ChatHeader from "./components/ChatHeader";
import ChatListSidebar from "./components/ChatListSidebar";
import MessageContainer from "./components/MessageContainer";
import { useAppContext } from "src/hooks";
import { chat } from "src/assets";
import ChatListDrawer from "./components/ChatListDrawer";
import { Page } from "src/components";
import { debounce } from "lodash";
import { TabContext, TabList } from "@mui/lab";
import StyledChatSearch from "src/components/search/StyledChatSearch";
import { del_from_local_storage } from "src/utils";

function Chats() {
  const {
    currentChat,
    setCurrentChat,
    chatList,
    setChatList,
    chatCount,
    setChatCount,
    chatType,
    setChatType,
    socketEmit,
    getModifiedChatItem,
    chatListDrawerOpen,
    setChatListDrawerOpen,
    setBadge,
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isAddChatDrawerOpen, setIsAddChatDrawerOpen] = useState(false);
  ///////////////////Chats///////////////////////////////////////////////

  const [chatListLoading, setChatListLoading] = useState(false);
  const [chatListPage, setChatListPage] = useState(0);
  const [addChatLoading, setAddChatLoading] = useState(false);

  const modifiedChatList = (list) => {
    const modified = list.map((chat) => {
      return { ...getModifiedChatItem(chat) };
    });
    return [...modified];
  };
  const getChatsList = async (type) => {
    setChatListLoading(true);
    try {
      if (type == "all") {
        const result = await _getAllChats(0, 15);
        if (result.code == 200) {
          let tempChatList = modifiedChatList(result.chat_list.chat_list);
          if (currentChat) {
            let temp = tempChatList.findIndex(
              (chat) => chat._id == currentChat._id
            );
            if (temp == -1) {
              tempChatList.unshift(currentChat);
            } else {
              setCurrentChat(tempChatList[temp]);
            }
          }
          setChatList(tempChatList);
          setChatCount(result.chat_list.chat_list_count);
          setChatListPage((prev) => prev + 1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else if (type == "groups") {
        const result = await _getGroupChatList(0, 15);
        setChatCount(result.group_list.group_chat_list_count);
        let tempChatList = modifiedChatList(result.group_list.group_chat_list);
        if (currentChat && currentChat.type == "1") {
          let temp = tempChatList.findIndex(
            (chat) => chat._id == currentChat._id
          );
          if (temp == -1) {
            tempChatList.unshift(currentChat);
          } else {
            setCurrentChat(tempChatList[temp]);
          }
        }
        setChatList(tempChatList);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched");
    } finally {
      setChatListLoading(false);
    }
  };
  const handleAddNewChat = async (user) => {
    setAddChatLoading(true);
    try {
      let temp = chatList.findIndex(
        (chat) => chat.type == "0" && chat.user[0].user_id == user.user_id
      );
      if (temp !== -1) {
        if (currentChat?._id !== chatList[temp]._id) {
          setCurrentChat(chatList[temp]);
          setMessageList([]);
          setMessageListPage(0);
          getMessageList(chatList[temp]);
        }
        setChatListDrawerOpen(false);
        setIsAddChatDrawerOpen(false);
        return;
      }

      const req_data = {
        team: [
          {
            user_id: user.user_id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            image: user.image,
            status: user.status,
            role: user.role,
            user_status: user.user_status,
          },
        ],
      };
      const result = await _addUserInChatList(req_data);
      if (result.code == 200) {
        if (chatType == "all") {
          setChatList((prev) => {
            return [getModifiedChatItem(result.chat), ...prev];
          });
          setChatCount((prev) => prev + 1);
        }
        setCurrentChat(getModifiedChatItem(result.chat));
        setIsAddChatDrawerOpen(false);
        enqueueSnackbar("Chat added successfully", { variant: "success" });
        setMessageList([]);
        setMessageListPage(0);
        setChatListDrawerOpen(false);
        socketEmit("addNewChat", result.chat);
      } else if (result.code == 400 && result.chat) {
        if (chatType == "all") {
          setChatList((prev) => {
            return [getModifiedChatItem(result.chat), ...prev];
          });
        }
        setCurrentChat(getModifiedChatItem(result.chat));
        setIsAddChatDrawerOpen(false);
        setMessageList([]);
        setMessageListPage(0);
        setChatListDrawerOpen(false);
        getMessageList(getModifiedChatItem(result.chat));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while adding chat");
    } finally {
      setAddChatLoading(false);
    }
  };
  useEffect(() => {
    setChatList([]);
    setChatCount(0);
    setChatListPage(0);
    getChatsList(chatType);
  }, [chatType]);
  ////////////////////////////////////////////////////////////////////////

  //////////////////////Load more Chats///////////////////////////////////
  const loadMoreChats = async () => {
    try {
      if (chatType == "all") {
        const result = await _getAllChats(chatListPage, 15);
        if (result.code == 200) {
          let tempChatList = chatList.concat(
            modifiedChatList(result.chat_list.chat_list)
          );
          const uniqueArray = tempChatList.reduce((accumulator, current) => {
            const isDuplicate = accumulator.find(
              (item) => item._id === current._id
            );
            if (!isDuplicate) {
              return [...accumulator, current];
            }
            return accumulator;
          }, []);
          // setChatList((prev) => {
          //   return prev.concat(modifiedChatList(result.chat_list.chat_list));
          // });
          setChatList(uniqueArray);
          setChatCount(result.chat_list.chat_list_count);
          setChatListPage((prev) => prev + 1);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else if (chatType == "groups") {
        const result = await _getGroupChatList(chatListPage, 15);
        if (result.code == 200) {
          setChatList((prev) => {
            return prev.concat(
              modifiedChatList(result.group_list.group_chat_list)
            );
          });
          setChatCount(result.group_list.group_chat_list_count);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched");
    } finally {
      setChatListLoading(false);
    }
  };
  ////////////////////////////////////////////////////////////////////////
  //////////////////////Messages/////////////////////////////////////////
  const { setMessageList, messageCount, setMessageCount } = useAppContext();
  const [loadingMessageList, setLoadingMessageList] = useState(false);

  const [messageListPage, setMessageListPage] = useState(0);
  const getMessageList = async (chat) => {
    setLoadingMessageList(true);
    try {
      const result = await _getChatMessages(chat._id);
      if (result.code == 200) {
        setMessageList(result.message.message_list);
        setMessageCount(result.message.message_count);
        setMessageListPage((prev) => prev + 1);
      } else if (
        result.message == "Cannot read properties of null (reading 'type')"
      ) {
        setCurrentChat();
        del_from_local_storage("currentChat");
        setChatList((prev) =>
          prev.filter((prevChat) => prevChat._id !== chat._id)
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched");
    } finally {
      setLoadingMessageList(false);
    }
  };
  useEffect(() => {
    currentChat && getMessageList(currentChat);
  }, []);
  /////////////////////////////////////////////////////////////////////////////
  //////////////////////////////Load More Messages/////////////////////////////
  const loadMoreMessages = async () => {
    try {
      const result = await _getChatMessages(currentChat._id, messageListPage);
      if (result.code == 200) {
        setMessageList((prev) => {
          return prev.concat(result.message.message_list);
        });
        setMessageListPage((prev) => prev + 1);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while loading more messages");
    }
  };
  ///////////////////////On Chat Click/////////////////////

  const onChatClick = (chat) => {
    if (currentChat?._id == chat._id) {
      setChatListDrawerOpen(false);
      return;
    } else {
      setCurrentChat(chat);
      setMessageListPage(0);
      setMessageCount(0);
      setMessageList([]);
      getMessageList(chat);
      setChatList((prev) => {
        return prev.map((cht) => {
          if (chat._id == cht._id) {
            return { ...cht, unread_count: 0 };
          } else {
            return { ...cht };
          }
        });
      });
      setChatListDrawerOpen(false);
    }
    setBadge((prev) => {
      return { ...prev, chat: Math.max(prev.chat - chat.unread_count, 0) };
    });
  };
  //////////////if the screen is small/////////////////////
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 768 ? false : true
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
      return () => {
        removeEventListener("resize");
      };
    });
  }, []);
  /////////////////Clean Up////////////////////////////////////
  useEffect(() => {
    return () => {
      setMessageList([]);
      setMessageCount(0);
      setMessageListPage(0);
      setChatList([]);
      setChatType("all");
      setCurrentChat();
      setChatListPage(0);
      setChatCount(0);
      del_from_local_storage("currentChat");
      del_from_local_storage("chatType");
    };
  }, []);
  ////////////////////////////////////////////////////////////
  return (
    <Page title="Temp Chat">
      <Container maxWidth="xl">
        <div className="row chat-container common-border">
          {!isMobile && (
            <div
              className="col-3 chat-users common-border"
              style={{ overflow: "hidden" }}
            >
              <TabContext value={chatType}>
                <TabList onChange={(e, val) => setChatType(val)}>
                  <Tab sx={{ width: "50%" }} label="All Chats" value="all" />
                  {/* <Tab sx={{ p: 0 }} label="Chats" value="chats" /> */}
                  <Tab sx={{ width: "50%" }} label="Groups" value="groups" />
                </TabList>
              </TabContext>
              {/* <div
                className="d-flex w-100 position-relative"
                style={{ bottom: "4px", top: "5px" }}
              >
                <span className="w-100">
                  <StyledChatSearch
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeHolder="Search..."
                  />
                </span>
              </div> */}
              <ChatListSidebar
                chatType={chatType}
                onChatClick={onChatClick}
                setChatType={setChatType}
                chatListLoading={chatListLoading}
                chatCount={chatCount}
                chatList={chatList}
                setChatList={setChatList}
                chatListPage={chatListPage}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                loadMoreChats={loadMoreChats}
                setIsAddChatDrawerOpen={setIsAddChatDrawerOpen}
              />
            </div>
          )}
          {isMobile && (
            <ChatListDrawer
              isOpen={chatListDrawerOpen}
              onClose={() => setChatListDrawerOpen(false)}
              // openAddChatDrawer={() => setIsAddChatDrawerOpen(true)}
            >
              <TabContext value={chatType}>
                <TabList onChange={(e, val) => setChatType(val)}>
                  <Tab sx={{ width: "50%" }} label="All Chats" value="all" />
                  {/* <Tab sx={{ p: 0 }} label="Chats" value="chats" /> */}
                  <Tab sx={{ width: "50%" }} label="Groups" value="groups" />
                </TabList>
              </TabContext>
              {/* <div
                className="d-flex w-100 position-relative"
                style={{ bottom: "4px", top: "5px" }}
              >
                <span className="w-100">
                  <StyledChatSearch
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeHolder="Search..."
                  />
                </span>
              </div> */}
              <div className="col-12 chat-users-hidden common-border">
                <ChatListSidebar
                  chatType={chatType}
                  onChatClick={onChatClick}
                  setChatType={setChatType}
                  chatListLoading={chatListLoading}
                  chatCount={chatCount}
                  chatList={chatList}
                  setChatList={setChatList}
                  chatListPage={chatListPage}
                  currentChat={currentChat}
                  setCurrentChat={setCurrentChat}
                  loadMoreChats={loadMoreChats}
                  setIsAddChatDrawerOpen={setIsAddChatDrawerOpen}
                />
              </div>
            </ChatListDrawer>
          )}
          {currentChat && (
            <div
              className={`${
                !isMobile ? "col-9 chat-messages" : "col-12 chat-messages-full"
              } common-border`}
            >
              <ChatHeader
                isMobile={isMobile}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                setChatListDrawerOpen={setChatListDrawerOpen}
              />
              <MessageContainer
                loadingMessageList={loadingMessageList}
                messageCount={messageCount}
                loadMoreMessages={loadMoreMessages}
                currentChat={currentChat}
              />
            </div>
          )}
          {!currentChat && (
            <div
              className={`${
                !isMobile ? "col-9 chat-messages" : "col-12 chat-messages-full"
              } common-border`}
            >
              <div
                style={{ height: "100%" }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <Box
                  sx={{ height: "50%", borderRadius: "50%" }}
                  component="img"
                  src={chat}
                />
                {isMobile && chatList.length > 0 && !chatListLoading && (
                  <Button
                    variant="contained"
                    sx={{ width: "30%" }}
                    onClick={() => setChatListDrawerOpen(true)}
                  >
                    Go to Chats!
                  </Button>
                )}
                {isMobile && chatList.length == 0 && !chatListLoading && (
                  <Button
                    variant="contained"
                    sx={{ width: "30%" }}
                    onClick={() => setIsAddChatDrawerOpen(true)}
                  >
                    Create a Chat
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
      <AddChatDrawer
        open={isAddChatDrawerOpen}
        onClose={() => setIsAddChatDrawerOpen(false)}
        setMessageListPage={setMessageListPage}
        handleAddNewChat={handleAddNewChat}
        isLoading={addChatLoading}
      />
    </Page>
  );
}

export default Chats;
