import { _user_logout, _user_logout_from_all_devices } from "src/DAL";

export const set_to_localStorage = (key, data) => {
  if (typeof data === "object") {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
};

export const get_from_localStorage = (key) => {
  let parsed = undefined;
  let parse_success = true;
  try {
    parsed = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    parse_success = false;
  }
  if (!parse_success) {
    let item = localStorage.getItem(key);

    return item;
  }
  return parsed;
};
export const del_from_local_storage = (key) => {
  let item = localStorage.getItem(key);
  if (item) {
    localStorage.removeItem(key);
  } else {
    console.error("item not found");
  }
};
export const logout_user = async (type) => {
  let token = get_from_localStorage("token");
  localStorage.clear();
  if (type == "0") {
    _user_logout(token);
  } else {
    _user_logout_from_all_devices(token);
  }
};
