import {
  Box,
  Card,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Iconify, MenuPopover } from "src/components";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";

const TemplateImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const MenuContainer = styled("div")({
  top: 0,
  right: 0,
  position: "absolute",
  zIndex: 2,
});

function TemplateCard({ item, menuOptions, onClickMenuItem }) {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { dispatch_set_current_selected_item } = useAppContext();
  const [open, setOpen] = useState(null);
  const handleClose = () => {
    setOpen(null);
  };
  const handleClickItem = (option, index, e) => {
    e.stopPropagation();
    onClickMenuItem(option);
    handleClose();
  };
  const handleOpenOptions = (e, item) => {
    e.stopPropagation();
    setOpen(e.currentTarget);
    dispatch_set_current_selected_item(item);
  };
  const handleNav = () => {
    navigate(`/project-templates/${item._id}`, { state: { template: item } });
  };
  return (
    <Card sx={{ height: "100%", cursor: "pointer" }} onClick={handleNav}>
      <MenuContainer id="menu-container">
        <IconButton
          sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
          ref={anchorRef}
          onClick={(e) => handleOpenOptions(e, item)}
        >
          <Iconify
            sx={{ color: "black" }}
            icon="fluent:more-vertical-16-filled"
          />
        </IconButton>
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          sx={{
            p: 0.5,
            mt: 1.5,
            ml: 0.75,
            width: "7rem",
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          }}
        >
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                onClick={(e) => handleClickItem(option, index, e)}
                key={index}
                sx={{ width: "6.5rem" }}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </MenuPopover>
      </MenuContainer>
      <div>
        <Box sx={{ pt: "100%", position: "relative" }}>
          {item.image ? (
            <TemplateImgStyle
              alt={"name"}
              src={s3baseUrl + item.image}
              className="image-hov"
            />
          ) : (
            <TemplateImgStyle
              alt={"name"}
              src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
              className="image-hov"
            />
          )}
        </Box>
        <Stack spacing={1} sx={{ p: 2, pt: 3.5 }}>
          <Tooltip title="Project template">
            <Typography
              className="template-card"
              variant="body1"
              fontWeight={"bold"}
              // noWrap
              onClick={() => null}
            >
              {item.title}
            </Typography>
          </Tooltip>
        </Stack>
      </div>
    </Card>
  );
}

export default TemplateCard;
