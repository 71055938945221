import React from 'react';
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { OutlinedInput, InputAdornment } from '@mui/material';
import Iconify from './Iconify';
const SearchStyle = styled(OutlinedInput)(({ theme, notransition }) => {
  if (notransition) {
    return {
      width: 240,
      height: 40,
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
      },
      '& legend': {
        display: 'none',
      },
    };
  }
  return {
    width: 240,
    height: 40,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: 320,
      // boxShadow: theme.customShadows.z8
    },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
    '& legend': {
      display: 'none',
    },
  };
});
const SearchBar = ({ value, onChange, placeholder, notransition, sx }) => {
  if (notransition) {
    return (
      <div>
        <SearchStyle
          sx={{ ...sx }}
          notransition='true'
          value={value}
          onChange={onChange}
          placeholder={`${placeholder ? placeholder : 'Search...'}`}
          startAdornment={
            <InputAdornment position='start'>
              <Iconify
                icon='eva:search-fill'
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      </div>
    );
  }
  return (
    <div>
      <SearchStyle
        sx={{ ...sx }}
        value={value}
        onChange={onChange}
        placeholder={`${placeholder ? placeholder : 'Search...'}`}
        startAdornment={
          <InputAdornment position='start'>
            <Iconify
              icon='eva:search-fill'
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
    </div>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
