import PropTypes from "prop-types";
// material
import {
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Checkbox,
} from "@mui/material";

ListHead.propTypes = {
  headLabel: PropTypes.array,
  checkbox: PropTypes.bool,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func,
};

export default function ListHead({
  headLabel,
  checkbox = false,
  rowCount = 0,
  numSelected = 0,
  onSelectAllClick = () => {},
}) {
  return (
    <TableHead>
      <TableRow>
        {checkbox && (
          <TableCell key="checkbox" padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
