import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  PriorityPopover,
  StatusPopover,
  TaskCreatedAt,
  UserAvatar,
} from "src/components";
import { truncateText } from "src/utils";

function DaashboardTaskCard({ task, onTaskClick }) {
  return (
    <Card
      key={task._id}
      onClick={() => onTaskClick(task)}
      sx={{ cursor: "pointer" }}
    >
      <CardContent sx={{ p: 1 }} style={{ paddingBottom: "8px" }}>
        <Typography className="my-2 px-1">
          {truncateText(task.task_title, 65)}
        </Typography>
        <div className="d-flex justify-content-center align-items-center gap-3  mt-2 px-5">
          <Tooltip
            title={`Created by ${
              task.creator_profile.first_name +
              " " +
              task.creator_profile.last_name
            }`}
          >
            <div>
              <UserAvatar
                sx={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                }}
                image={task.creator_profile.image}
                name={task.creator_profile.first_name}
                alt={
                  task.creator_profile.first_name +
                  " " +
                  task.creator_profile.last_name
                }
              />
            </div>
          </Tooltip>
          <TaskCreatedAt date={task.createdAt} />
          <PriorityPopover
            allowOpen={false}
            value={task.priority}
            onChange={() => {}}
          />
          <StatusPopover
            allowOpen={false}
            value={task.task_status}
            onChange={() => {}}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default DaashboardTaskCard;
