import moment from 'moment';
// --------------------------------------------------

export const get_month_first_day = () => {
  let format = 'YYYY-MM-DD';
  let current_time = new Date().getTime();
  let first_day = moment(current_time).startOf('month').format(format);
  return first_day;
};
export const get_month_last_day = () => {
  let format = 'YYYY-MM-DD';
  let current_time = new Date().getTime();
  let last_day = moment(current_time).endOf('month').format(format);

  return last_day;
};

export const get_zone_date = (_date, _format) => {
  let date_format = 'DD MMM, YYYY hh:mm:s a ';
  if (_format) {
    date_format = _format;
  }
  return moment(_date).format(date_format);
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  if (!_date) {
    return '-';
  }
  let date_safari = _date;
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === '-') {
      date_safari = date_safari.replace('-', '/');
    }
  }
  const serverDate = new Date(_date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const date = moment(moment(date_safari).subtract(difference_safari, 'm')).format(date_format);
    return date;
  }
  if (!detectSafariBrowser()) {
    const date = moment(moment(serverDate).subtract(difference, 'm')).format(date_format);

    return date;
  }
};
