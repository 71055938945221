import { useState, useTransition } from "react";

// material
import { styled, alpha } from "@mui/material/styles";
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
} from "@mui/material";
// component
import Iconify from "../../components/Iconify";
//
import { useAppContext } from "src/hooks";
import { debounce } from "lodash";
// ----------------------------------------------------------------------

const APPBAR_DESKTOP = "8vh";
const APPBAR_MOBILE = "8vh";

const SearchbarStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------
/* DEBOUNCE SEARCH */

const debouncedUpdateSearch = debounce((query, cb) => {
  cb(query);
}, 150);
// ----------------------------------------------------------------------
export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { dispatch_set_search_value } = useAppContext();
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    debouncedUpdateSearch(e.target.value, dispatch_set_search_value);
    setSearch(e.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={search}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: "fontWeightBold" }}
            />
            {/* <Button variant='contained' onClick={handleClose}>
              Search
            </Button> */}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
