import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  Paper,
  Badge,
} from "@mui/material";
import { MuiDrawer, Iconify } from "..";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { capitalCase } from "change-case";

// -----------------------------------------------------
const ICON_SIZE = { height: "10px", width: "10px" };

const MAP_TASK_PRIORITY_OPTIONS = [
  { label: "critical", value: 0, color: "#FF4842" },
  { label: "high", value: 1, color: "#ffd500" },
  { label: "medium", value: 2, color: "#54D62C" },
  { label: "low", value: 3, color: "#969292" },
];

const MAP_TASK_STATUS_OPTIONS = [
  { label: "pending", value: 0, color: "#FFC107" },
  { label: "inProgress", value: 2, color: "#3366FF" },
  { label: "readyForTesting", value: 11, color: "#a4bdfc" },
  { label: "completed", value: 3, color: "#00AB55" },
  { label: "cancelled", value: 1, color: "#FF4842" },
];

const FiltersDrawer = ({
  open,
  onClose,
  onChange,
  clearFilter,
  selectedFilters,
}) => {
  const handleClose = () => {
    onClose();
  };

  const onClear = () => {
    clearFilter();
    onClose();
  };

  const handleChange = (e, item, type) => {
    onChange(e, item, type);
  };

  const handleClearFilter = (type) => {
    clearFilter(type);
  };

  return (
    <>
      <MuiDrawer
        isOpen={open}
        onClose={handleClose}
        paperProps={{ width: { xs: 1, sm: 320 } }}
        title={<Typography sx={{ fontWeight: "700" }}>Task Filters</Typography>}
        bottomComponent={<FiltersFooter onClear={onClear} />}
      >
        <Stack spacing={2}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            {/* Status Filters Accordion */}
            <Paper elevation={2}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  className="accordion-Summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="95%"
                  >
                    <Typography fontWeight="bold">Status</Typography>
                    <div>
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClearFilter("task_status");
                        }}
                        color="primary"
                      >
                        Clear
                      </Button>
                      <Badge
                        badgeContent={
                          MAP_TASK_STATUS_OPTIONS.filter((option) =>
                            selectedFilters.task_status.includes(option.value)
                          ).length
                        }
                        color="primary"
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="p-1 pb-0">
                  {MAP_TASK_STATUS_OPTIONS.map((item, i) => (
                    <label key={i} style={{ width: "100%" }}>
                      <Stack
                        className="row-h-effect"
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: "100%" }}
                      >
                        <Checkbox
                          className="check-box-filter"
                          value={item.label}
                          onChange={(e) => handleChange(e, item, "status")}
                          checked={
                            selectedFilters.task_status.findIndex(
                              (option) => option === item.value
                            ) >= 0
                          }
                        />
                        <Box
                          sx={{
                            width: ICON_SIZE.width,
                            height: ICON_SIZE.height,
                            borderRadius: "50%",
                            backgroundColor: item.color || "transparent",
                          }}
                        />
                        <Typography sx={{ flex: 1, userSelect: "none" }}>
                          {capitalCase(item.label)}
                        </Typography>
                      </Stack>
                    </label>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Priority Filters Accordion */}
            <Paper elevation={2}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  className="accordion-Summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="95%"
                  >
                    <Typography fontWeight="bold">Priority</Typography>
                    <div>
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClearFilter("priority");
                        }}
                        color="primary"
                      >
                        Clear
                      </Button>
                      <Badge
                        badgeContent={
                          MAP_TASK_PRIORITY_OPTIONS.filter((option) =>
                            selectedFilters.priority.includes(option.value)
                          ).length
                        }
                        color="primary"
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="p-1 pb-0">
                  {MAP_TASK_PRIORITY_OPTIONS.map((item, i) => (
                    <label key={i} style={{ width: "100%" }}>
                      <Stack
                        className="row-h-effect"
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: "100%" }}
                      >
                        <Checkbox
                          className="check-box-filter"
                          value={item.label}
                          checked={
                            selectedFilters.priority.findIndex(
                              (option) => option === item.value
                            ) >= 0
                          }
                          onChange={(e) => handleChange(e, item, "priority")}
                        />
                        <Iconify
                          icon="carbon:flag-filled"
                          sx={{
                            width: "15px",
                            height: "15px",
                            color: item.color,
                          }}
                        />
                        <Typography sx={{ flex: 1, userSelect: "none" }}>
                          {capitalCase(item.label)}
                        </Typography>
                      </Stack>
                    </label>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Paper>
          </div>
        </Stack>
      </MuiDrawer>
    </>
  );
};

const FiltersFooter = ({ onClear }) => {
  return (
    <Box sx={{ pl: 2, pr: 2, pt: 2, pb: 1 }}>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="primary"
        variant="outlined"
        className="border"
        onClick={onClear}
        startIcon={<Iconify icon={"material-symbols:clear-all-rounded"} />}
      >
        Clear All
      </Button>
    </Box>
  );
};

export default FiltersDrawer;
