import React from "react";
import { Button, Container, Stack, Grid, Avatar, Box } from "@mui/material";
import { NavBackHeader } from "src/components";
const TeamMemberProfile = () => {
  return (
    <Container maxWidth="xl">
      <NavBackHeader heading="Team Member Profile" />
    </Container>
  );
};

export default TeamMemberProfile;
