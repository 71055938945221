import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import Iconify from "./Iconify";
import { useNavigate } from "react-router-dom";
import { capitalCase } from "change-case";

function AddNewCard({ title, handleClick }) {
  const navigate = useNavigate();
  return (
    <Card
      sx={{ width: "180px", height: "270px", m: "20px", cursor: "pointer" }}
      onClick={handleClick}
    >
      <CardContent sx={{ height: "100%" }}>
        <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
          <Iconify
            icon="icon-park-solid:add"
            sx={{
              width: "36px",
              height: "36px",
              color: "#00AB55",
            }}
          />
          <Typography fontWeight={"bold"}>Add {capitalCase(title)}</Typography>
          <Typography>Click here to add {title}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default AddNewCard;
