import React from "react";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Avatar,
  ButtonBase,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { capitalCase } from "change-case";
import { s3baseUrl } from "src/config/";
import { Iconify, Label } from "src/components";
import { useAppContext } from "src/hooks";
// ---------------------------------------------------------
const UserCard = ({
  item,
  userRole,
  onRemoveUser,
  onSettings,
  setMemberSettings = () => {},
  allowedMembersForClient = [],
}) => {
  // ---------------------------------------------------------
  // const { user_profile, role } = item;
  let membersToDisplayForClient = [];
  allowedMembersForClient.map((member) => {
    let index = item.allow_users.indexOf(member.user_id);
    if (index !== -1) {
      membersToDisplayForClient = [...membersToDisplayForClient, member];
    }
  });
  const { image, first_name, last_name, role } = item;
  const { dispatch_set_current_selected_item } = useAppContext();
  const canRemove = (_role) => {
    if (String(userRole) === "admin") {
      return true;
    }
    if (String(userRole) == "manager") {
      if (_role == "admin") {
        return false;
      }
      return true;
    }
    if (String(userRole) === "team") {
      return false;
    }
    // if (String(_role) === String(userRole)) {
    //   return false;
    // }
    return false;
  };
  const canChangeSettings = (_role) => {
    if (userRole == "admin" && _role !== "admin") {
      return true;
    }
    if (userRole == "manager") {
      return true;
    }
    return false;
  };
  const handleRemove = () => {
    dispatch_set_current_selected_item(item);
    onRemoveUser();
  };
  const handleSettings = () => {
    setMemberSettings(item);
    dispatch_set_current_selected_item(item);
    onSettings();
  };
  // ---------------------------------------------------------
  return (
    <Paper onClick={() => console.log(item)} elevation={3} sx={{ p: 1 }}>
      <Stack spacing={1} direction="column">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction="row">
            <Box alignItems="center" justifyContent="center">
              {image ? (
                <Avatar src={s3baseUrl + image} />
              ) : (
                <Avatar>{first_name.substring(0, 2)}</Avatar>
              )}
            </Box>
            <Stack spacing={1} sx={{ ml: 1 }}>
              <Typography variant="body1" noWrap>
                {first_name + " " + last_name}
              </Typography>
              <Stack direction="row" spacing={1} style={{ marginTop: 0 }}>
                <Typography fontWeight="bold" variant="body1">
                  Role
                </Typography>
                <Typography variant="body1" noWrap>
                  {capitalCase(role)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          {canChangeSettings(role) && role !== "client_team" && (
            <Stack direction={"row"}>
              <Tooltip
                title={
                  role == "manager" || role == "team"
                    ? "Change the role of this user"
                    : "Change the allowed team members for this client"
                }
              >
                <IconButton onClick={handleSettings}>
                  <Iconify icon="ic:baseline-settings" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        <Stack spacing={1} sx={{ px: "50px" }}>
          {(role == "client" || role == "client_team") &&
            userRole !== "client" &&
            userRole !== "client_team" && (
              <Stack spacing={0}>
                <Typography fontWeight="bold" variant="body1">
                  Team members visible to the client:
                </Typography>
                {/* <Typography variant="body1"> */}
                <List sx={{ pt: 0 }}>
                  {membersToDisplayForClient.map((member) => {
                    return (
                      <ListItem key={member.user_id} sx={{ p: 0 }}>
                        {member.first_name +
                          " " +
                          member.last_name +
                          " (" +
                          member.role +
                          ")"}
                      </ListItem>
                    );
                  })}
                  {/* </Typography> */}
                </List>
              </Stack>
            )}
        </Stack>
        {canRemove(role) && (
          <div className="centered-row ">
            <ButtonBase onClick={handleRemove} sx={{ borderRadius: "5px" }}>
              <Label sx={{ cursor: "pointer" }} variant="ghost" color="error">
                Remove
              </Label>
            </ButtonBase>
          </div>
        )}
      </Stack>
    </Paper>
  );
};

export default UserCard;
