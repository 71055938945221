import { Button, Card } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

function PricingPlans({ activePlans = [] }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleButtonClick = (plan) => {
    console.log(plan, "plaans");
    navigate(`/plan-detail/${plan?._id}`, { state: plan?._id });
  };

  return (
    <>
      <div className="container py-5">
        <div className="card">
          <div className="row justify-content-center">
            {activePlans.map((plan) => (
              <div className="col-12 col-md-6 col-lg-4 col-xl-4 d-flex align-items-end position-relative plans-card">
                <Card
                  className={`p-3`}
                  sx={{
                    height: "100%",
                    border: "0.15rem solid",
                    width: "100%",
                    borderColor: (theme) => theme.palette.primary.main,
                  }}
                >
                  {plan?.is_active && (
                    <div
                      style={{
                        height: "30px",
                        backgroundColor: "#00AB55",
                        margin: "-16px -17px 16px",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      <p className="pt-1">Active Plan</p>
                    </div>
                  )}
                  <div className="">
                    <div className="h3-style">
                      <div className="wb-editor-block  mt-2">
                        <h4>{plan.name}</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="d-flex pt-2"
                        style={{
                          color: (theme) => theme.palette.primary.main,
                          alignItems: "center",
                        }}
                      >
                        <h4
                          style={{
                            fontStyle: "32px",
                            fontWeight: "initial",
                          }}
                        >
                          {plan.currency_symbol}
                        </h4>{" "}
                        <h4
                          style={{
                            fontStyle: "32px",
                            fontWeight: "initial",
                          }}
                        >
                          {plan.price}
                        </h4>{" "}
                        <span
                          className="ps-1"
                          style={{ fontWeight: "bold", color: "gray" }}
                        >
                          {" "}
                          /{plan.plan_type}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="pt-2">{plan?.short_description}</p>
                    </div>

                    <div>
                      <Button
                        onClick={() => {
                          handleButtonClick(plan);
                        }}
                        fullWidth
                        variant="contained"
                        className="my-2"
                        disabled={plan?.is_active === true}
                      >
                        GET STARTED
                        <i className="fa-solid fa-arrow-right ms-2"></i>
                      </Button>
                    </div>
                    <div className="mt-3">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {` ${plan.name} 
                        Includes:`}
                      </p>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: plan?.detailed_description,
                      }}
                    ></div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingPlans;
