import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, DialogContent, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------
function SimpleDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    title,
    message,
    onAgree,
    disableDismiss,
    loading,
    ExtraComponent,
    cancelbuttonText,
    classes,
    sx,
  } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };
  return (
    <Dialog
      PaperProps={{ sx: { minWidth: "20vw", maxWidth: "100vw", ...sx } }}
      onClose={disableDismiss ? () => null : handleClose}
      open={open}
      classes={classes}
    >
      <DialogTitle>{title ? title : "Untitled"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {message && (
            <Typography variant="body">
              {message ? message : "untitled"}
            </Typography>
          )}

          {ExtraComponent && ExtraComponent}
          <Stack alignItems="center" justifyContent="end" direction="row">
            {onAgree && (
              <LoadingButton loading={loading} onClick={onAgree}>
                Yes
              </LoadingButton>
            )}
            <Button onClick={handleClose}>
              {cancelbuttonText ? cancelbuttonText : "Cancel"}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default function MuiDialog({
  open,
  onToggle,
  title,
  message,
  disableDismiss,
  onAgree,
  loading = false,
  ExtraComponent,
  cancelbuttonText = "",
  cancelFunction,
  classes = {},
  sx = {},
}) {
  const handleClose = (value) => {
    if (!cancelbuttonText) {
      onToggle(false);
    } else {
      cancelFunction();
    }
  };

  return (
    <div>
      <SimpleDialog
        message={message}
        title={title}
        cancelbuttonText={cancelbuttonText}
        open={open}
        onAgree={onAgree}
        onClose={handleClose}
        disableDismiss={disableDismiss}
        loading={loading}
        ExtraComponent={ExtraComponent}
        classes={classes}
        sx={sx}
      />
    </div>
  );
}
MuiDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  disableDismiss: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  ExtraComponent: PropTypes.node,
  cancelbuttonText: PropTypes.string,
  cancelFunction: PropTypes.func,
};
