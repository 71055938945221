import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
// ---------------------------------------------------------
export default function MuiSelect({
  label,
  options,
  value,
  onChange,
  onOptionClick = () => {},
  name = "",
  disabled = false,
}) {
  // const [age, setAge] = React.useState('');
  /* empty to select none by default */
  // ---------------------------------------------------------

  const handleChange = (event) => {
    onChange(event);
  };
  const handleClick = (e, _option) => {
    onOptionClick(e, _option);
  };
  // ---------------------------------------------------------

  // ---------------------------------------------------------
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={disabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          name={name}
        >
          {options.map((item, index) => (
            <MenuItem
              key={index}
              onClick={(e) => handleClick(e, item)}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
MuiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    })
  ),
  onOptionClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
