import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { useSnackbar } from "notistack";
import { _user_login, _verify_email } from "src/DAL";
import { generateTempToken, set_to_localStorage } from "src/utils";
import { useAppContext } from "src/hooks";
import { MuiDialog } from "src/components";
import VerificationInput from "react-verification-input";

// ----------------------------------------------------------------------

export default function LoginForm({ invitation }) {
  const navigate = useNavigate();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { state } = useLocation();
  const [formInputs, setFormInputs] = useState({
    email: state?.email ?? "",
    password: state?.password ?? "",
    deviceToken: "",
    type: 1,
  });
  const [tempToken, setTempToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_set_user_profile } = useAppContext();
  const LoginSchema = Yup.object().shape({
    // deviceToken: Yup.string().required('Device token is required'),
    type: Yup.string().required("Type is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = await LoginSchema.validate(formInputs).catch((er) => {
      if (er) {
        return er.errors;
      }
      return [];
    });
    if (errors.length > 0) {
      errors.map((er) => {
        enqueueSnackbar(er, { variant: "error" });
      });
      return;
    }
    setIsLoading(true);
    const result = await _user_login({
      email: formInputs.email,
      type: formInputs.type,
      password: formInputs.password,
      device_token: formInputs.deviceToken,
    });
    if (result.code === 200) {
      setIsLoading(false);
      set_to_localStorage("token", result.token);
      set_to_localStorage("user", result.customer);
      dispatch_set_user_profile(result.customer);
      if (invitation) {
        navigate("/invitations");
      } else {
        navigate("/dashboard");
      }
    } else {
      if (result.message == "Email verification failed!") {
        navigate("/verification", {
          state: {
            email: formInputs.email,
            from: "login",
            invitation: Boolean(invitation),
          },
        });
      }
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const verifyEmail = async (code) => {
    document.getElementsByClassName("vi")[0].blur();
    const req_data = {
      email: formInputs.email,
      verification_code: code,
      device_token: "",
    };
    const result = await _verify_email(req_data);
    if (result.code == 200) {
      set_to_localStorage("token", result.token);
      set_to_localStorage("user", result.customer);
      dispatch_set_user_profile(result.customer);
      if (invitation) {
        navigate("/invitations");
      } else {
        navigate("/dashboard");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <form methd="get" autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          value={formInputs.email}
          onChange={handleChange}
          name="email"
          required
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
        />

        <TextField
          value={formInputs.password}
          onChange={handleChange}
          fullWidth
          name="password"
          required
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link
          component={RouterLink}
          variant="subtitle2"
          to="/forgot-password"
          underline="hover"
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
      >
        Login
      </LoadingButton>
      {/* <MuiDialog
        title="Verify Email address"
        message="An email was sent to your email address when you registered your account, provide the verification code that was sent in that email"
        disableDismiss={true}
        open={isConfirmationOpen}
        onToggle={(value) => setIsConfirmationOpen(value)}
        ExtraComponent={
          <div className="d-flex justify-content-center">
            <VerificationInput
              validChars="0-9"
              inputProps={{ inputMode: "numeric" }}
              onComplete={verifyEmail}
            />
          </div>
        }
      /> */}
    </form>
  );
}
