export function isColorLight(hexColor) {
  // Remove the "#" symbol if it's included
  hexColor = hexColor.replace("#", "");

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate the relative luminance using the formula for sRGB
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // You can adjust this threshold as needed
  return luminance > 0.5; // If luminance is greater than 0.5, it's considered a light color
}
