import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import Iconify from "../Iconify";
import PropTypes from "prop-types";
import moment from "moment";
// ---------------------------------------------------------
const TaskCreatedAt = ({ date }) => {
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  return (
    <div>
      <Tooltip title={`Created at ${moment(date).format("LLL")}`}>
        <IconButton
          sx={{
            bgcolor: (theme) => theme.palette.action.hover,
            width: "1.9rem",
            height: "1.9rem",
          }}
          size="small"
        >
          <Iconify
            sx={{ width: "1rem", height: "1rem" }}
            icon="zondicons:date-add"
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TaskCreatedAt;
TaskCreatedAt.propTypes = {
  date: PropTypes.string.isRequired,
};
