import { get_from_localStorage, invokeApi } from "src/utils";

export const _workspace_list_for_task_shift = (id) => {
  const requestObj = {
    path: `api/workspace/workspace_list_task_shift/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _project_list_for_task_shift = (id) => {
  const requestObj = {
    path: `api/project/project_list_for_task_shift/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _shift_task_to_other_workspace = (data) => {
  const requestObj = {
    path: `api/task/shift_task`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
