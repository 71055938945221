import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _current_clients_list, _get_rate_of_client } from "src/DAL/team";
import {
  Iconify,
  Label,
  MuiAutoComplete,
  MuiMultiAutocomplete,
  NavBackHeader,
  Page,
  RichEditor,
} from "src/components";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  _add_invoice,
  _get_reports_for_invoice,
  _get_tasks_for_report,
  _save_as_draft,
} from "src/DAL";
import { useSnackbar } from "notistack";
import { useAppContext } from "src/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useTransition } from "react";
import { upload_image } from "src/utils";
import { s3baseUrl } from "src/config";
import { LoadingButton } from "@mui/lab";
import { convertCurrencyToSign } from "src/utils/constant";
function AddInvoice() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { dispatch_get_user_profile } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    title: state ? state?.title : "",
    image: state ? state?.image : "",
    client: state?.invoice_client_data?.user_id
      ? {
          ...state.invoice_client_data,
          label:
            state.invoice_client_data.first_name +
            " " +
            state.invoice_client_data.last_name,
          value: state.invoice_client_data.user_id,
        }
      : null,
    projects: state ? state?.projects : [],
    start_date: state ? state?.start_date : null,
    end_date: state ? state?.end_date : null,
    price_without_discount: state ? Number(state?.total_price) : 0,
    discount: state ? Number(state?.discount) : 0,
    total: state ? Number(state?.final_price) : 0,
    description: state ? state?.description : "",
    invoice_issue_date: state ? state?.invoice_issue_date : null,
    invoice_due_date: state ? state?.invoice_due_date : null,
    total_time: state ? state?.total_time : "",
    tax: state ? state?.tax : "",
    reminder_title: state ? state?.reminder_title : "",
    reminder_date: state ? state?.reminder_date : null,
    reminder_description: state ? state?.reminder_description : "",
  });
  const [file, setFile] = useState();
  const [clients, setClients] = useState([]);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newTask, setNewTask] = useState({
    task_title: "",
    task_billable_amount: 0,
    project: null,
    time_track: { final_time: "" },
    per_hour_rate: 0,
  });
  const [paymentObject, setPaymentObject] = useState({
    workspaces: [],
    projects: [],
    over_all_price: "",
  });
  const [loadingBtns, setLoadingBtns] = useState({ draft: false, send: false });
  const [newTaskDialogOpen, setNewTaskDialogOpen] = useState(false);
  const getClientRateInProject = (project) => {
    if (!project) return;
    const projectWithPrice = paymentObject.projects.find(
      (proj) => proj._id === project._id
    );
    if (projectWithPrice) {
      return Number(projectWithPrice.price);
    }
    const workspaceWithPrice = paymentObject.workspaces.find(
      (workspace) => workspace._id === project.workspace.id
    );
    if (workspaceWithPrice) {
      return Number(workspaceWithPrice.price);
    } else if (paymentObject.over_all_price) {
      return Number(paymentObject.over_all_price);
    } else {
      return 0;
    }
  };

  console.log(paymentObject, "paymentObject");
  const [stateTasks, setStateTasks] = useState(
    state?.tasks
      ? state?.tasks
          // ?.filter((task) => Boolean(task._id))
          .map((task) => {
            const { time_track } = task;
            const task_project = state.projects.find(
              (project) => project._id == task.project.id
            );
            const client_rate_in_project = getClientRateInProject(task_project);
            const task_billable_amount_hours =
              client_rate_in_project *
              (time_track.final_time
                ? Number(time_track.final_time.split(":")[0])
                : 0);
            const task_billable_amount_minutes =
              client_rate_in_project *
              (time_track.final_time
                ? Number(time_track.final_time.split(":")[1]) / 60
                : 0);
            const task_billable_amount = Number(
              task_billable_amount_hours + task_billable_amount_minutes
            ).toFixed(2);
            return {
              ...task,
              temp_id: task._id ?? String(Math.random()),
              task_type: "state",
              task_billable_amount: task.task_billable_amount,
              per_hour_rate: client_rate_in_project,
            };
          })
      : []
  );
  const [selected, setSelected] = useState([...stateTasks]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [taskList, setTaskList] = useState([...stateTasks]);
  const [allSelected, setAllSelected] = useState(false);
  ///////////////////=====================New Flow/////////////////////////////////////////////
  const [step, setStep] = useState(1);
  const [isPending, startTransition] = useTransition();
  const openNewTask = () => {
    setNewTaskDialogOpen(true);
  };
  const closeNewTask = () => {
    setNewTaskDialogOpen(false);
    setNewTask({
      task_title: "",
      task_billable_amount: 0,
      project: null,
      time_track: { final_time: "" },
      per_hour_rate: 0,
    });
  };
  const handleAddTask = () => {
    if (!newTask.task_title) {
      enqueueSnackbar("Please add task title", { variant: "error" });
      return;
    } else if (!newTask.project) {
      enqueueSnackbar("Please select a project", { variant: "error" });
      return;
    }
    let newTempTask = {
      task_title: newTask.task_title,
      project: { id: newTask.project._id, name: newTask.project.title },
      workspace: newTask.project.workspace,
      task_billable_amount: newTask.task_billable_amount
        ? Number(newTask.task_billable_amount)
        : 0,
      time_track: newTask.time_track.final_time
        ? newTask.time_track
        : { final_time: "00:00" },
      temp_id: String(Math.random()),
      per_hour_rate: newTask.per_hour_rate,
      type: "temporary",
    };
    setTaskList((prev) => [newTempTask, ...prev]);
    setSelected((prev) => [newTempTask, ...prev]);
    closeNewTask();
  };
  //////////////////////////////////////////////////////////////////////////////////////////////
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getClientsList = async () => {
    const result = await _current_clients_list();
    if (result.code === 200) {
      setClients(
        result.user_workspace
          .filter((client) => client.status)
          .map((client) => {
            return {
              ...client,
              label: client.first_name + " " + client.last_name,
              value: client.user_id,
            };
          })
      );
      if (state?.invoice_client_data?.user_id) {
        let found = result.user_workspace.find(
          (client) => client.user_id == state?.invoice_client_data?.user_id
        );
        if (found) {
          found = {
            ...found,
            label: found.first_name + " " + found.last_name,
            value: found.user_id,
          };
        }
        // setInputs((prev) => {
        //   return {
        //     ...prev,
        //     client: { ...found },
        //   };
        // });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getClientRateList = async () => {
    try {
      const req = {
        client_id: inputs.client.user_id,
        admin_id: dispatch_get_user_profile().user_id,
      };
      const result = await _get_rate_of_client(req);
      if (result.code == 200) {
        setPaymentObject(result.client_price.price_object);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.log(error, " catched while getting client rate");
    }
  };

  const getTasks = async () => {
    try {
      if (
        inputs.projects.length == 0 ||
        !inputs.start_date ||
        !inputs.client ||
        !inputs.end_date
      ) {
        return;
      }
      setTasksLoading(true);
      const req_obj = {
        client_id: inputs.client.user_id,
        start_date: inputs.start_date,
        end_date: inputs.end_date,
        project_ids: inputs.projects.map((project) => project._id),
      };
      const result = await _get_tasks_for_report(req_obj);
      let temp_tasks = [];
      let temporaryAddedTasks = [];
      if (taskList.length > 0) {
        temporaryAddedTasks = taskList.filter(
          (task) => task?.type == "temporary"
        );
      }
      if (stateTasks.length > 0) {
        let apiTasks = [...result.task_list];
        apiTasks = apiTasks.filter(
          (apiTsk) =>
            !stateTasks.some((stateTsk) => stateTsk._id === apiTsk._id)
        );
        temp_tasks = [
          // ...state.tasks.filter((task) => !Boolean(task._id)),
          ...stateTasks,
          ...apiTasks,
        ].map((task) => {
          const { time_track } = task;
          const task_project = inputs.projects.find(
            (project) => project._id == task.project.id
          );
          const client_rate_in_project = getClientRateInProject(task_project);
          const task_billable_amount_hours =
            client_rate_in_project *
            (time_track.final_time
              ? Number(time_track.final_time.split(":")[0])
              : 0);
          const task_billable_amount_minutes =
            client_rate_in_project *
            (time_track.final_time
              ? Number(time_track.final_time.split(":")[1]) / 60
              : 0);
          const task_billable_amount = Number(
            task_billable_amount_hours + task_billable_amount_minutes
          ).toFixed(2);
          return {
            ...task,
            temp_id: !task._id ? task.temp_id : task._id,
            task_billable_amount:
              task?.task_type == "state"
                ? task?.task_billable_amount
                : task_billable_amount,
            per_hour_rate: client_rate_in_project,
          };
        });
        console.log(temp_tasks, "avjhasviajviasjvaisvjs");
      } else {
        temp_tasks = result.task_list.map((task) => {
          const { time_track } = task;
          const task_project = inputs.projects.find(
            (project) => project._id == task.project.id
          );
          const client_rate_in_project = getClientRateInProject(task_project);
          const task_billable_amount_hours =
            client_rate_in_project *
            (time_track.final_time
              ? Number(time_track.final_time.split(":")[0])
              : 0);
          const task_billable_amount_minutes =
            client_rate_in_project *
            (time_track.final_time
              ? Number(time_track.final_time.split(":")[1]) / 60
              : 0);
          const task_billable_amount = Number(
            task_billable_amount_hours + task_billable_amount_minutes
          ).toFixed(2);
          return {
            ...task,
            temp_id: task._id,
            task_billable_amount:
              task?.task_type == "state"
                ? task?.task_billable_amount
                : task_billable_amount,
            per_hour_rate: client_rate_in_project,
          };
        });
      }
      // setTaskList([...temporaryAddedTasks, ...temp_tasks]);
      setTaskList([...temp_tasks]);
      if (!state) {
        setSelected([...temporaryAddedTasks, ...temp_tasks]);
      }
    } catch (error) {
      console.log(error, " while fetching tasks");
    } finally {
      setTasksLoading(false);
    }
  };
  const handleSave = async (draft) => {
    try {
      if (draft) {
        setLoadingBtns((prev) => {
          return { ...prev, draft: true };
        });
        if (!inputs.title) {
          enqueueSnackbar("Invoice Title cannot be empty", {
            variant: "error",
          });
          return;
        }
      } else {
        setLoadingBtns((prev) => {
          return { ...prev, send: true };
        });
      }
      let image = inputs.image;
      if (file) {
        const imgResult = await upload_image(file);
        if (imgResult.code == 200) {
          image = imgResult.path;
        }
      }
      let req_obj = {
        image,
        title: inputs.title,
        description: inputs.description,
        projects: inputs.projects.map((project) => ({
          ...project,
          price: getClientRateInProject(project),
        })),
        invoice_creater_data: {
          user_id: dispatch_get_user_profile().user_id,
          first_name: dispatch_get_user_profile().first_name,
          last_name: dispatch_get_user_profile().last_name,
          email: dispatch_get_user_profile().email,
          image: dispatch_get_user_profile().image,
          country_code: dispatch_get_user_profile().country_code,
          phone_number: dispatch_get_user_profile().contact_number,
        },
        invoice_issue_date: inputs.invoice_issue_date,
        invoice_due_date: inputs.invoice_due_date,
        start_date: inputs.start_date,
        end_date: inputs.end_date,
        total_time: inputs.total_time,
        total_price: inputs.price_without_discount.toString(),
        discount: inputs.discount.toString(),
        tax: inputs.tax,
        final_price: inputs.total.toString(),
        tasks: selected.map((task) => {
          delete task.temp_id;
          return task;
        }),
        reminder_title: inputs.reminder_title,
        reminder_date: inputs.reminder_date,
        reminder_description: inputs.reminder_description,
        is_draft: draft,
        currency: paymentObject.over_all_currency,
      };
      if (state?._id) {
        req_obj.invoice_id = state._id;
      }
      if (inputs.client) {
        req_obj["invoice_client_data"] = {
          user_id: inputs.client?.user_id,
          first_name: inputs.client?.first_name,
          last_name: inputs.client?.last_name,
          email: inputs.client?.email,
          image: inputs.client?.image,
        };
      }
      let result;
      if (draft) {
        result = await _save_as_draft(req_obj);
      } else {
        result = await _add_invoice(req_obj);
      }
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        navigate(-1, { replace: true });
      }
    } catch (error) {
      console.log(error, " catched while saving invoice");
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    } finally {
      setLoadingBtns({ draft: false, send: false });
    }
  };
  const handleNextStep = (current, next) => {
    if (current == 1 && next == 2) {
      // if (
      //   !inputs.title ||
      //   !inputs.client ||
      //   !inputs.projects.length > 0 ||
      //   !inputs.invoice_issue_date ||
      //   !inputs.invoice_due_date
      // ) {
      //   enqueueSnackbar("Fill all required fields in step 1", {
      //     variant: "error",
      //   });
      //   return;
      // }
      if (!inputs.title.trim()) {
        enqueueSnackbar("Please enter invoice title", {
          variant: "error",
        });
        return;
      } else if (!inputs.client) {
        enqueueSnackbar("Please select a client", {
          variant: "error",
        });
        return;
      } else if (inputs.projects.length == 0) {
        enqueueSnackbar("Please select atleast one project", {
          variant: "error",
        });
        return;
      } else if (!inputs.invoice_issue_date) {
        enqueueSnackbar("Please select invoice issue date", {
          variant: "error",
        });
        return;
      } else if (!inputs.invoice_due_date) {
        enqueueSnackbar("Please select invoice due date", {
          variant: "error",
        });
        return;
      } else {
        setStep(next);
      }
    } else if (current == 1 && next == 3) {
      // if (
      //   !inputs.title ||
      //   !inputs.client ||
      //   !inputs.projects.length > 0 ||
      //   !inputs.invoice_issue_date ||
      //   !inputs.invoice_due_date
      // ) {
      //   enqueueSnackbar("Fill all required fields in step 1", {
      //     variant: "error",
      //   });
      //   return;
      // }
      if (!inputs.title.trim()) {
        enqueueSnackbar("Please enter invoice title", {
          variant: "error",
        });
        return;
      } else if (!inputs.client) {
        enqueueSnackbar("Please select a client", {
          variant: "error",
        });
        return;
      } else if (inputs.projects.length == 0) {
        enqueueSnackbar("Please select atleast one project", {
          variant: "error",
        });
        return;
      } else if (!inputs.invoice_issue_date) {
        enqueueSnackbar("Please select invoice issue date", {
          variant: "error",
        });
        return;
      } else if (!inputs.invoice_due_date) {
        enqueueSnackbar("Please select invoice due date", {
          variant: "error",
        });
        return;
      } else if (!inputs.start_date) {
        enqueueSnackbar("Please select start date for tasks", {
          variant: "error",
        });
        setStep(2);
        return;
      } else if (!inputs.end_date) {
        enqueueSnackbar("Please select end date for tasks", {
          variant: "error",
        });
        setStep(2);
        return;
      } else if (selected.length == 0) {
        enqueueSnackbar("Please select atleast one task for invoice", {
          variant: "error",
        });
        setStep(2);
        return;
      } else if (!inputs.total) {
        enqueueSnackbar("Invalid subtotal", {
          variant: "error",
        });
        setStep(2);
        return;
      }
    } else if (current == 2 && next == 3) {
      if (!inputs.start_date) {
        enqueueSnackbar("Please select start date for tasks", {
          variant: "error",
        });
        return;
      } else if (!inputs.end_date) {
        enqueueSnackbar("Please select end date for tasks", {
          variant: "error",
        });
        return;
      } else if (selected.length == 0) {
        enqueueSnackbar("Please select atleast one task for invoice", {
          variant: "error",
        });
        return;
      } else if (!inputs.total) {
        enqueueSnackbar("Invalid subtotal", {
          variant: "error",
        });
        return;
      }
    }
    setStep(next);
  };
  useEffect(() => {
    if (selected.length == taskList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [taskList, selected]);
  ////////////////////////////////////////////////////////////////
  useEffect(() => {
    getTasks();
  }, [inputs.client, inputs.start_date, inputs.end_date, inputs.projects]);
  useEffect(() => {
    setInputs((prev) => {
      return {
        ...prev,
        total:
          prev.price_without_discount -
            prev.discount +
            (Number(prev.tax ? prev.tax : 0) *
              (prev.price_without_discount - prev.discount)) /
              100 <=
          0
            ? 0
            : (
                prev.price_without_discount -
                prev.discount +
                (Number(prev.tax ? prev.tax : 0) *
                  (prev.price_without_discount - prev.discount)) /
                  100
              ).toFixed(2),
        price_without_discount: prev.price_without_discount
          ? Number(prev.price_without_discount).toFixed(2)
          : prev.price_without_discount,
      };
    });
  }, [inputs.discount, inputs.tax]);
  useEffect(() => {
    if (selected.length > 0) {
      let [tempHours, tempMinutes] = [0, 0];
      let tempTotalAmount = 0;
      for (let i of selected) {
        tempHours += i.time_track.final_time.split(":")[0]
          ? Number(i.time_track.final_time.split(":")[0])
          : 0;
        tempMinutes += i.time_track.final_time.split(":")[1]
          ? Number(i.time_track.final_time.split(":")[1])
          : 0;
        tempTotalAmount = Number(
          Number(tempTotalAmount) + Number(i.task_billable_amount)
        ).toFixed(2);
      }
      if (tempMinutes >= 60) {
        tempHours += Math.floor(tempMinutes / 60);
        tempMinutes = tempMinutes % 60;
      }

      tempHours = String(tempHours).padStart(2, "0");
      tempMinutes = String(tempMinutes).padStart(2, "0");
      console.log(tempTotalAmount, "avajvapajvopva");
      setInputs((prev) => {
        return {
          ...prev,
          total_time: [tempHours, tempMinutes].join(":"),
          price_without_discount: Number(tempTotalAmount).toFixed(2),
          total:
            Number(tempTotalAmount) -
              prev.discount +
              (Number(prev.tax ? prev.tax : 0) *
                (Number(tempTotalAmount) - prev.discount)) /
                100 <=
            0
              ? 0
              : (
                  Number(tempTotalAmount) -
                  prev.discount +
                  (Number(prev.tax ? prev.tax : 0) *
                    (Number(tempTotalAmount) - prev.discount)) /
                    100
                ).toFixed(2),
        };
      });
    } else {
      setInputs((prev) => {
        return { ...prev, total_time: "", total: 0, price_without_discount: 0 };
      });
    }
  }, [selected]);
  useEffect(() => {
    if (newTask.project) {
      const client_rate_in_project = getClientRateInProject(newTask.project);
      let [h, m] = newTask.time_track.final_time.split(":");
      const task_billable_amount_hours =
        client_rate_in_project * Number(h ?? 0);
      const task_billable_amount_minutes =
        client_rate_in_project * (Number(m ?? 0) / 60);
      const task_billable_amount = Number(
        task_billable_amount_hours + task_billable_amount_minutes
      ).toFixed(2);
      setNewTask((prev) => {
        return {
          ...prev,
          task_billable_amount,
          per_hour_rate: client_rate_in_project,
        };
      });
    } else {
      setNewTask((prev) => {
        return {
          ...prev,
          task_billable_amount: 0,
          per_hour_rate: 0,
        };
      });
    }
  }, [newTask.project, newTask.time_track.final_time]);
  useEffect(() => {
    if (inputs.client) {
      getClientRateList();
    }
  }, [inputs.client]);
  useEffect(() => {
    getClientsList();
  }, []);
  useEffect(() => {
    if (pathname == "/invoices/edit-invoice" && !state) {
      navigate("/invoices", { replace: true });
    }
  }, []);
  return (
    <Page title="Add Invoice">
      <Container maxWidth="xl">
        <div
          className="d-flex w-100"
          style={{
            minHeight: "80px",
            backgroundColor: "#f6fffc",
            cursor: "pointer",
          }}
        >
          <div
            className="w-100 d-flex align-items-center"
            style={{
              borderBottom:
                step == 1 ? "3px solid #00ab55" : "1px solid lightgrey",
            }}
            onClick={() => {
              startTransition(() => {
                handleNextStep(step, 1);
              });
            }}
          >
            <div
              style={{
                width: "70%",
                height: "80%",
                margin: "auto",
              }}
              className="d-flex"
            >
              <Iconify
                icon={step == 1 ? "bi:1-circle-fill" : "bi:1-circle"}
                sx={{
                  mt: 0.2,
                  height: "16px",
                  width: "16px",
                  color: step == 1 ? "#00ab55" : "",
                }}
              />
              <div className="ps-2">
                <Typography>Step 1:</Typography>
                <div>
                  <Typography fontWeight={600}>Add Invoice</Typography>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-100 d-flex align-items-center"
            style={{
              borderLeft: "1px solid lightgrey",
              borderRight: "1px solid lightgrey",
              borderBottom:
                step == 2 ? "3px solid #00ab55" : "1px solid lightgrey",
            }}
            onClick={() => {
              startTransition(() => {
                handleNextStep(step, 2);
              });
            }}
          >
            <div
              style={{
                width: "70%",
                height: "80%",
                margin: "auto",
              }}
              className="d-flex"
            >
              <Iconify
                icon={step == 2 ? "bi:2-circle-fill" : "bi:2-circle"}
                sx={{
                  mt: 0.2,
                  height: "16px",
                  width: "16px",
                  color: step == 2 ? "#00ab55" : "",
                }}
              />
              <div className="ps-2">
                <Typography>Step 2:</Typography>
                <div>
                  <Typography fontWeight={600}>Billing</Typography>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-100 d-flex align-items-center"
            style={{
              borderBottom:
                step == 3 ? "3px solid #00ab55" : "1px solid lightgrey",
            }}
            onClick={() => {
              startTransition(() => {
                handleNextStep(step, 3);
              });
            }}
          >
            <div
              style={{
                width: "70%",
                height: "80%",
                margin: "auto",
              }}
              className="d-flex"
            >
              <Iconify
                icon={step == 3 ? "bi:3-circle-fill" : "bi:3-circle"}
                sx={{
                  mt: 0.2,
                  height: "16px",
                  width: "16px",
                  color: step == 3 ? "#00ab55" : "",
                }}
              />
              <div className="ps-2">
                <Typography>Step 3:</Typography>
                <div>
                  <Typography fontWeight={600}>
                    Client Notification Reminder
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        {step == 1 && (
          <div className="py-3">
            <Grid
              container
              spacing={2}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Grid item xs={2}>
                <div>
                  <label htmlFor="icon-button-file">
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      value=""
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setInputs((prev) => {
                          return {
                            ...prev,
                            image: URL.createObjectURL(e.target.files[0]),
                          };
                        });
                        // e.target.value = null;
                      }}
                    />
                    <Tooltip title="Add Logo">
                      <>
                        {!inputs.image && (
                          <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{
                              height: "100px",
                              minWidth: "100px",
                              borderRadius: "5px",
                              border: "2px solid #00ab55",
                              cursor: "pointer",
                            }}
                          >
                            <Iconify
                              icon="ph:plus-fill"
                              sx={{
                                color: "#00ab55",
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <Typography>Add Logo</Typography>
                            <Typography fontSize={"10px"}>200 x 200</Typography>
                          </div>
                        )}
                        {inputs.image && (
                          <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{
                              height: "100px",
                              width: "100px",
                              borderRadius: "5px",
                              border: "2px solid #00ab55",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                !inputs.image.startsWith("blob")
                                  ? s3baseUrl + inputs.image
                                  : inputs.image
                              }
                              height={"100%"}
                              width={"100%"}
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        )}
                      </>
                    </Tooltip>
                  </label>
                </div>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <TextField
                    label="Invoice Title"
                    required
                    name="title"
                    value={inputs.title}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <MuiAutoComplete
                    required
                    label="Client"
                    options={clients}
                    value={inputs.client}
                    onChange={(val) => {
                      setInputs((prev) => {
                        return {
                          ...prev,
                          client: val,
                          projects: val
                            ? val.project.map((proj) => {
                                return {
                                  ...proj,
                                  label: proj.title,
                                  value: proj,
                                };
                              })
                            : [],
                        };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <MuiMultiAutocomplete
                    label="Projects"
                    disableCloseOnSelect
                    required
                    options={
                      inputs?.client?.project?.map((proj) => {
                        return {
                          ...proj,
                          label: proj.title,
                          value: { ...proj },
                        };
                      }) ?? []
                    }
                    value={inputs.projects}
                    onChange={(val) => {
                      setInputs((prev) => {
                        return { ...prev, projects: val };
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Invoice Issue Date"
                      inputFormat="DD-MMM-YYYY"
                      rifmFormatter={(date) =>
                        moment(date).format("DD-MMM-YYYY")
                      }
                      value={inputs.invoice_issue_date}
                      onChange={(newValue) =>
                        setInputs({
                          ...inputs,
                          invoice_issue_date: newValue
                            ? moment(newValue).format("DD-MMM-YYYY")
                            : null,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          required
                          name="invoice_issue_date"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MMM-YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Invoice Due Date"
                      inputFormat="DD-MMM-YYYY"
                      rifmFormatter={(date) =>
                        moment(date).format("DD-MMM-YYYY")
                      }
                      value={inputs.invoice_due_date}
                      onChange={(newValue) =>
                        setInputs({
                          ...inputs,
                          invoice_due_date: newValue
                            ? moment(newValue).format("DD-MMM-YYYY")
                            : null,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth
                          name="invoice_due_date"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MMM-YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <RichEditor
                  placeHolder="Add Invoice Description"
                  value={inputs.description}
                  setValue={(val) =>
                    setInputs((prev) => {
                      return { ...prev, description: val };
                    })
                  }
                />
              </Grid>
            </Grid>
            <div className="d-flex justify-content-end align-items-center mt-3">
              <LoadingButton
                loading={loadingBtns.draft}
                variant="outlined"
                onClick={() => handleSave(true)}
              >
                Save as draft
              </LoadingButton>
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => handleNextStep(1, 2)}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {step == 2 && (
          <div className="py-3">
            <div className="d-flex justify-content-between align-items-center pt-2 pb-3">
              <div>
                <div className="d-flex align-items-center">
                  <Typography
                    fontWeight={"600"}
                    variant="subtitle1"
                    sx={{ mr: 1 }}
                    color={(theme) => theme.palette.primary.main}
                  >
                    Client Name:
                  </Typography>
                  <Typography>{inputs.client?.label}</Typography>
                </div>
                <div className="d-flex align-items-center">
                  <Typography
                    fontWeight={"600"}
                    variant="subtitle1"
                    sx={{ mr: 1 }}
                    color={(theme) => theme.palette.primary.main}
                  >
                    {inputs.projects.length <= 1 ? "Project:" : "Projects:"}
                  </Typography>
                  <Typography>
                    {inputs.projects.map((project) => project.label).join(", ")}
                  </Typography>
                </div>
              </div>
              <Button variant="contained" onClick={openNewTask}>
                Add Task
              </Button>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Task Start Date"
                      inputFormat="DD-MMM-YYYY"
                      rifmFormatter={(date) =>
                        moment(date).format("DD-MMM-YYYY")
                      }
                      value={inputs.start_date}
                      onChange={(newValue) =>
                        setInputs({
                          ...inputs,
                          start_date: newValue
                            ? moment(newValue).format("DD-MMM-YYYY")
                            : null,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth
                          name="start_date"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MMM-YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Task End Date"
                      inputFormat="DD-MMM-YYYY"
                      rifmFormatter={(date) =>
                        moment(date).format("DD-MMM-YYYY")
                      }
                      value={inputs.end_date}
                      onChange={(newValue) =>
                        setInputs({
                          ...inputs,
                          end_date: newValue
                            ? moment(newValue).format("DD-MMM-YYYY")
                            : null,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          fullWidth
                          name="end_date"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MMM-YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction={"row"}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label="Total Time (hr:min)"
                      value={inputs.total_time}
                      name="total_time"
                      inputProps={{ readOnly: true }}
                      // onChange={(e) => {
                      //   let inputValue = e.target.value;

                      //   // Remove non-digit characters
                      //   inputValue = inputValue.replace(/[^0-9:]/g, "");
                      //   // Limit the length to 5 characters
                      //   inputValue = inputValue.slice(0, 5);

                      //   if (inputValue.includes(":")) {
                      //     let temp = inputValue.split(":");
                      //     if (temp[0].length < 2) {
                      //       temp[0] = temp[0].padStart(2, "0");
                      //       inputValue = temp.join(":");
                      //     }
                      //   }
                      //   if (
                      //     inputValue.length > 2 &&
                      //     !inputValue.includes(":")
                      //   ) {
                      //     // Add a colon after the first two digits, if necessary
                      //     inputValue = `${inputValue.slice(
                      //       0,
                      //       2
                      //     )}:${inputValue.slice(2)}`;
                      //   }

                      //   // Use a regular expression to match the desired time format (hh:mm)
                      //   const regex = /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                      //   if (regex.test(inputValue) || inputValue === "") {
                      //     let temp = inputValue.split(":");
                      //     if (Number(temp[1]) > 59) {
                      //       if (Number(temp[0]) < 99) {
                      //         temp[1] = String(Number(temp[1] - 60)).padStart(
                      //           2,
                      //           "0"
                      //         );
                      //         temp[0] = String(Number(temp[0]) + 1);
                      //       } else {
                      //         temp[1] = String(Number(temp[1] - 60));
                      //       }
                      //       inputValue = temp.join(":");
                      //     }
                      //     setInputs((prev) => {
                      //       return { ...prev, total_time: inputValue };
                      //     });
                      //   }
                      // }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Amount
                    </InputLabel>
                    <OutlinedInput
                      sx={{ height: "91%" }}
                      name="price_without_discount"
                      value={inputs.price_without_discount}
                      inputProps={{ readOnly: true }}
                      // onBlur={() => {
                      //   if (
                      //     inputs.price_without_discount.toString().endsWith(".")
                      //   ) {
                      //     if (
                      //       inputs.price_without_discount.toString().length > 1
                      //     ) {
                      //       setInputs((prev) => {
                      //         return {
                      //           ...prev,
                      //           price_without_discount: Number(
                      //             prev.price_without_discount
                      //               .toString()
                      //               .slice(0, -1)
                      //           ),
                      //         };
                      //       });
                      //     } else {
                      //       setInputs((prev) => {
                      //         return {
                      //           ...prev,
                      //           price_without_discount: 0,
                      //         };
                      //       });
                      //     }
                      //   }
                      // }}
                      // onChange={(e) => {
                      //   if (e.target.value.match(/^[0-9.]*$/)) {
                      //     let count = 0;
                      //     for (var i = 0; i < e.target.value.length; i++) {
                      //       if (e.target.value[i] === ".") {
                      //         count++;
                      //       }
                      //     }
                      //     if (count > 1) {
                      //       return;
                      //     }
                      //     handleChange(e);
                      //   }
                      // }}
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          {convertCurrencyToSign(
                            paymentObject.over_all_currency
                          )}
                        </InputAdornment>
                      }
                      label="Amount"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Discount
                    </InputLabel>
                    <OutlinedInput
                      sx={{ height: "91%" }}
                      value={inputs.discount}
                      name="discount"
                      onBlur={() => {
                        if (inputs.discount.toString().endsWith(".")) {
                          if (inputs.discount.toString().length > 1) {
                            setInputs((prev) => {
                              return {
                                ...prev,
                                discount: Number(
                                  prev.discount.toString().slice(0, -1)
                                ),
                              };
                            });
                          } else {
                            setInputs((prev) => {
                              return {
                                ...prev,
                                discount: 0,
                              };
                            });
                          }
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9.]*$/)) {
                          let count = 0;
                          for (var i = 0; i < e.target.value.length; i++) {
                            if (e.target.value[i] === ".") {
                              count++;
                            }
                          }
                          if (count > 1) {
                            return;
                          }
                          handleChange(e);
                        }
                      }}
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          {convertCurrencyToSign(
                            paymentObject.over_all_currency
                          )}
                        </InputAdornment>
                      }
                      label="Discount"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Tax in %"
                      value={inputs.tax}
                      name="tax"
                      onBlur={() => {
                        if (inputs.tax.toString().endsWith(".")) {
                          if (inputs.tax.toString().length > 1) {
                            setInputs((prev) => {
                              return {
                                ...prev,
                                tax: prev.tax.toString().slice(0, -1),
                              };
                            });
                          } else {
                            setInputs((prev) => {
                              return {
                                ...prev,
                                tax: "",
                              };
                            });
                          }
                        }
                      }}
                      onChange={(e) => {
                        if (e.target.value.match(/^[0-9.]*$/)) {
                          let count = 0;
                          for (var i = 0; i < e.target.value.length; i++) {
                            if (e.target.value[i] === ".") {
                              count++;
                            }
                          }
                          if (count > 1) {
                            return;
                          }
                          handleChange(e);
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Sub Total
                    </InputLabel>
                    <OutlinedInput
                      sx={{ height: "91%" }}
                      name="total"
                      inputProps={{ readOnly: true }}
                      // onBlur={() => {
                      //   if (inputs.total.toString().endsWith(".")) {
                      //     if (inputs.total.toString().length > 1) {
                      //       setInputs((prev) => {
                      //         return {
                      //           ...prev,
                      //           total: Number(
                      //             prev.total.toString().slice(0, -1)
                      //           ),
                      //         };
                      //       });
                      //     } else {
                      //       setInputs((prev) => {
                      //         return {
                      //           ...prev,
                      //           total: 0,
                      //         };
                      //       });
                      //     }
                      //   }
                      // }}
                      // onChange={(e) => {
                      //   if (e.target.value.match(/^[0-9.]*$/)) {
                      //     let count = 0;
                      //     for (var i = 0; i < e.target.value.length; i++) {
                      //       if (e.target.value[i] === ".") {
                      //         count++;
                      //       }
                      //     }
                      //     if (count > 1) {
                      //       return;
                      //     }
                      //     handleChange(e);
                      //   }
                      // }}
                      value={inputs.total}
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          {convertCurrencyToSign(
                            paymentObject.over_all_currency
                          )}
                        </InputAdornment>
                      }
                      label="Sub Total"
                    />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {taskList.length > 0 && (
                      <div className="d-flex align-items-center">
                        <Checkbox
                          checked={allSelected}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelected([...taskList]);
                            } else {
                              setSelected([]);
                            }
                          }}
                        />
                        <Typography>Select All Tasks</Typography>
                      </div>
                    )}
                  </div>
                  <div>
                    <LoadingButton
                      loading={loadingBtns.draft}
                      variant="outlined"
                      onClick={() => handleSave(true)}
                    >
                      Save as draft
                    </LoadingButton>
                    <Button
                      variant="contained"
                      sx={{ ml: 2 }}
                      onClick={() => handleNextStep(2, 3)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ width: "100%", borderColor: "black" }}>
                  Completed and Verified Tasks between the given dates
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  {taskList.map((task) => {
                    const isItemSelected = Boolean(
                      selected.find((item) => item.temp_id == task.temp_id)
                    );
                    const { time_track, per_hour_rate } = task;
                    return (
                      <div style={{ position: "relative" }}>
                        <Checkbox
                          sx={{ position: "absolute", zIndex: 10 }}
                          checked={isItemSelected}
                          onChange={() => {
                            if (isItemSelected) {
                              setSelected(
                                selected.filter(
                                  (i) => i.temp_id !== task.temp_id
                                )
                              );
                            } else {
                              setSelected([...selected, task]);
                            }
                          }}
                        />
                        <Card
                          sx={{
                            py: 1,
                            pr: 1,
                            pl: 5,
                            borderRadius: "5px",
                            // bgcolor: isItemSelected
                            //   ? "rgba(0, 171, 85, 0.08)"
                            //   : "",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid
                              item
                              xs={6}
                              // display={"flex"}
                              // alignItems={"center"}
                            >
                              <Typography>{task.task_title}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Label
                                variant="ghost"
                                color={
                                  task.task_status == 3 ? "success" : "default"
                                }
                              >
                                {task.task_status == 3
                                  ? "Completed"
                                  : "Verified"}
                              </Label>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <span className="ms-2">{`${convertCurrencyToSign(
                                paymentObject.over_all_currency
                              )} ${per_hour_rate} / Hour`}</span>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <TextField
                                label="Billable Time"
                                size="small"
                                placeholder="hr:min"
                                value={time_track.final_time}
                                onBlur={() => {
                                  if (time_track.final_time) {
                                    if (time_track.final_time.includes(":")) {
                                      let [hours, minutes] =
                                        time_track.final_time.split(":");
                                      hours = hours.padStart(2, "0");
                                      minutes = minutes.padStart(2, "0");
                                      setTaskList((prev) =>
                                        prev.map((tsk) => {
                                          if (tsk.temp_id == task.temp_id) {
                                            return {
                                              ...tsk,
                                              time_track: {
                                                ...tsk.time_track,
                                                final_time: [
                                                  hours,
                                                  minutes,
                                                ].join(":"),
                                              },
                                            };
                                          } else {
                                            return { ...tsk };
                                          }
                                        })
                                      );
                                      if (isItemSelected) {
                                        setSelected((prev) =>
                                          prev.map((tsk) => {
                                            if (tsk.temp_id == task.temp_id) {
                                              return {
                                                ...tsk,
                                                time_track: {
                                                  ...tsk.time_track,
                                                  final_time: [
                                                    hours,
                                                    minutes,
                                                  ].join(":"),
                                                },
                                              };
                                            } else {
                                              return { ...tsk };
                                            }
                                          })
                                        );
                                      }
                                    } else {
                                      let time =
                                        time_track.final_time.padStart(2, "0") +
                                        ":00";
                                      setTaskList((prev) =>
                                        prev.map((tsk) => {
                                          if (tsk.temp_id == task.temp_id) {
                                            return {
                                              ...tsk,
                                              time_track: {
                                                ...tsk.time_track,
                                                final_time: time,
                                              },
                                            };
                                          } else {
                                            return { ...tsk };
                                          }
                                        })
                                      );
                                      if (isItemSelected) {
                                        setSelected((prev) =>
                                          prev.map((tsk) => {
                                            if (tsk.temp_id == task.temp_id) {
                                              return {
                                                ...tsk,
                                                time_track: {
                                                  ...tsk.time_track,
                                                  final_time: time,
                                                },
                                              };
                                            } else {
                                              return { ...tsk };
                                            }
                                          })
                                        );
                                      }
                                    }
                                  }
                                }}
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  // Remove non-digit characters
                                  inputValue = inputValue.replace(
                                    /[^0-9:]/g,
                                    ""
                                  );
                                  // Limit the length to 5 characters
                                  inputValue = inputValue.slice(0, 5);

                                  if (inputValue.includes(":")) {
                                    let temp = inputValue.split(":");
                                    if (temp[0].length < 2) {
                                      temp[0] = temp[0].padStart(2, "0");
                                      inputValue = temp.join(":");
                                    }
                                  }
                                  if (
                                    inputValue.length > 2 &&
                                    !inputValue.includes(":")
                                  ) {
                                    // Add a colon after the first two digits, if necessary
                                    inputValue = `${inputValue.slice(
                                      0,
                                      2
                                    )}:${inputValue.slice(2)}`;
                                  }

                                  // Use a regular expression to match the desired time format (hh:mm)
                                  const regex =
                                    /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                                  if (
                                    regex.test(inputValue) ||
                                    inputValue === ""
                                  ) {
                                    let temp = inputValue.split(":");
                                    if (Number(temp[1]) > 59) {
                                      if (Number(temp[0]) < 99) {
                                        temp[1] = String(
                                          Number(temp[1] - 60)
                                        ).padStart(2, "0");
                                        temp[0] = String(Number(temp[0]) + 1);
                                      } else {
                                        temp[1] = String(Number(temp[1] - 60));
                                      }
                                      inputValue = temp.join(":");
                                    }
                                    let [h, m] = inputValue.split(":");
                                    const task_billable_amount_hours =
                                      per_hour_rate * Number(h);
                                    const task_billable_amount_minutes =
                                      per_hour_rate * (Number(m ?? 0) / 60);
                                    const task_billable_amount = Number(
                                      task_billable_amount_hours +
                                        task_billable_amount_minutes
                                    ).toFixed(2);
                                    setTaskList((prev) =>
                                      prev.map((tsk) => {
                                        if (tsk.temp_id == task.temp_id) {
                                          return {
                                            ...tsk,
                                            time_track: {
                                              ...task.time_track,
                                              final_time: inputValue,
                                            },
                                            task_billable_amount,
                                          };
                                        } else {
                                          return { ...tsk };
                                        }
                                      })
                                    );
                                    if (isItemSelected) {
                                      let [h, m] = inputValue.split(":");
                                      const task_billable_amount_hours =
                                        per_hour_rate * Number(h);
                                      const task_billable_amount_minutes =
                                        per_hour_rate * (Number(m ?? 0) / 60);
                                      const task_billable_amount = Number(
                                        task_billable_amount_hours +
                                          task_billable_amount_minutes
                                      ).toFixed(2);
                                      setSelected((prev) =>
                                        prev.map((tsk) => {
                                          if (tsk.temp_id == task.temp_id) {
                                            return {
                                              ...task,
                                              time_track: {
                                                ...task.time_track,
                                                final_time: inputValue,
                                              },
                                              task_billable_amount,
                                            };
                                          } else {
                                            return { ...tsk };
                                          }
                                        })
                                      );
                                    }
                                  }
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Billable Amount
                                </InputLabel>
                                <OutlinedInput
                                  label="Billable Amount"
                                  id="outlined-adornment-amount"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {convertCurrencyToSign(
                                        paymentObject.over_all_currency
                                      )}
                                    </InputAdornment>
                                  }
                                  sx={{ height: "91%" }}
                                  size="small"
                                  value={task.task_billable_amount}
                                  onBlur={() => {
                                    if (
                                      task.task_billable_amount
                                        .toString()
                                        .endsWith(".")
                                    ) {
                                      if (
                                        task.task_billable_amount.toString()
                                          .length > 1
                                      ) {
                                        // setInputs((prev) => {
                                        //   return {
                                        //     ...prev,
                                        //     discount: Number(
                                        //       prev.discount
                                        //         .toString()
                                        //         .slice(0, -1)
                                        //     ),
                                        //   };
                                        // });
                                        setTaskList((prev) =>
                                          prev.map((tsk) => {
                                            if (tsk.temp_id == task.temp_id) {
                                              return {
                                                ...tsk,
                                                task_billable_amount: Number(
                                                  tsk.task_billable_amount
                                                    .toString()
                                                    .slice(0, -1)
                                                ),
                                              };
                                            } else {
                                              return { ...tsk };
                                            }
                                          })
                                        );
                                      } else {
                                        setTaskList((prev) =>
                                          prev.map((tsk) => {
                                            if (tsk.temp_id == task.temp_id) {
                                              return {
                                                ...tsk,
                                                task_billable_amount: 0,
                                              };
                                            } else {
                                              return { ...tsk };
                                            }
                                          })
                                        );
                                      }
                                    }
                                  }}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^[0-9.]*$/)) {
                                      let count = 0;
                                      for (
                                        var i = 0;
                                        i < e.target.value.length;
                                        i++
                                      ) {
                                        if (e.target.value[i] === ".") {
                                          count++;
                                        }
                                      }
                                      if (count > 1) {
                                        return;
                                      }
                                      if (
                                        e.target.value.includes(".") &&
                                        e.target.value.split(".")[1] &&
                                        e.target.value.split(".")[1].length > 2
                                      ) {
                                        e.target.value = e.target.value.slice(
                                          0,
                                          -1 *
                                            (e.target.value.split(".")[1]
                                              .length -
                                              2)
                                        );
                                      }
                                      setTaskList((prev) =>
                                        prev.map((tsk) => {
                                          if (tsk.temp_id == task.temp_id) {
                                            return {
                                              ...tsk,
                                              task_billable_amount:
                                                e.target.value.endsWith(".")
                                                  ? e.target.value
                                                  : e.target.value.includes(
                                                      "."
                                                    ) &&
                                                    e.target.value.split(
                                                      "."
                                                    )[1] &&
                                                    e.target.value.split(".")[1]
                                                      .length > 2
                                                  ? Number(
                                                      e.target.value
                                                    ).toFixed(2)
                                                  : Number(e.target.value),
                                            };
                                          } else {
                                            return { ...tsk };
                                          }
                                        })
                                      );
                                      if (isItemSelected) {
                                        setSelected((prev) =>
                                          prev.map((tsk) => {
                                            if (tsk.temp_id == task.temp_id) {
                                              return {
                                                ...tsk,
                                                task_billable_amount:
                                                  e.target.value.endsWith(".")
                                                    ? e.target.value
                                                    : Number(e.target.value),
                                              };
                                            } else {
                                              return { ...tsk };
                                            }
                                          })
                                        );
                                      }
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    );
                  })}{" "}
                </Stack>
              </Grid>
            </Grid>
            <Dialog
              open={newTaskDialogOpen}
              onClose={closeNewTask}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Add Task</DialogTitle>
              <DialogContent>
                <div className="pt-2">
                  <FormControl fullWidth>
                    <TextField
                      label="Task Title"
                      required
                      value={newTask.task_title}
                      onChange={(e) =>
                        setNewTask((prev) => {
                          return {
                            ...prev,
                            task_title: e.target.value,
                          };
                        })
                      }
                    />
                  </FormControl>
                  <Stack spacing={2} direction={"row"} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <MuiAutoComplete
                        required
                        label="Project"
                        value={newTask.project}
                        options={inputs.projects}
                        onChange={(val) =>
                          setNewTask((prev) => {
                            return { ...prev, project: val };
                          })
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        label="Billable Time"
                        placeholder="hr:min"
                        value={newTask.time_track.final_time}
                        onBlur={() => {
                          if (newTask.time_track.final_time) {
                            if (newTask.time_track.final_time.includes(":")) {
                              let [hours, minutes] =
                                newTask.time_track.final_time.split(":");
                              hours = hours.padStart(2, "0");
                              minutes = minutes.padStart(2, "0");
                              setNewTask((prev) => {
                                return {
                                  ...prev,
                                  time_track: {
                                    final_time: [hours, minutes].join(":"),
                                  },
                                };
                              });
                            } else {
                              let time =
                                newTask.time_track.final_time.padStart(2, "0") +
                                ":00";
                              setNewTask((prev) => {
                                return {
                                  ...prev,
                                  time_track: { final_time: time },
                                };
                              });
                            }
                          } else {
                            setNewTask((prev) => {
                              return {
                                ...prev,
                                time_track: { final_time: "00:00" },
                              };
                            });
                          }
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value;

                          // Remove non-digit characters
                          inputValue = inputValue.replace(/[^0-9:]/g, "");
                          // Limit the length to 5 characters
                          inputValue = inputValue.slice(0, 5);

                          if (inputValue.includes(":")) {
                            let temp = inputValue.split(":");
                            if (temp[0].length < 2) {
                              temp[0] = temp[0].padStart(2, "0");
                              inputValue = temp.join(":");
                            }
                          }
                          if (
                            inputValue.length > 2 &&
                            !inputValue.includes(":")
                          ) {
                            // Add a colon after the first two digits, if necessary
                            inputValue = `${inputValue.slice(
                              0,
                              2
                            )}:${inputValue.slice(2)}`;
                          }

                          // Use a regular expression to match the desired time format (hh:mm)
                          const regex = /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                          if (regex.test(inputValue) || inputValue === "") {
                            let temp = inputValue.split(":");
                            if (Number(temp[1]) > 59) {
                              if (Number(temp[0]) < 99) {
                                temp[1] = String(Number(temp[1] - 60)).padStart(
                                  2,
                                  "0"
                                );
                                temp[0] = String(Number(temp[0]) + 1);
                              } else {
                                temp[1] = String(Number(temp[1] - 60));
                              }
                              inputValue = temp.join(":");
                            }
                            setNewTask((prev) => {
                              return {
                                ...prev,
                                time_track: { final_time: inputValue },
                              };
                            });
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Billable Amount
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        startAdornment={
                          <InputAdornment position="start">
                            {convertCurrencyToSign(
                              paymentObject.over_all_currency
                            )}
                          </InputAdornment>
                        }
                        sx={{ height: "91%" }}
                        label="Billable Amount"
                        value={newTask.task_billable_amount}
                        onChange={(e) => {
                          if (e.target.value.match(/^[0-9.]*$/)) {
                            let count = 0;
                            for (var i = 0; i < e.target.value.length; i++) {
                              if (e.target.value[i] === ".") {
                                count++;
                              }
                            }
                            if (count > 1) {
                              return;
                            }
                            if (
                              e.target.value.includes(".") &&
                              e.target.value.split(".")[1] &&
                              e.target.value.split(".")[1].length > 2
                            ) {
                              e.target.value = e.target.value.slice(
                                0,
                                -1 * (e.target.value.split(".")[1].length - 2)
                              );
                            }
                            setNewTask((prev) => {
                              console.log(e.target.value, "vavhasiovasd");
                              return {
                                ...prev,
                                task_billable_amount: e.target.value.endsWith(
                                  "."
                                )
                                  ? e.target.value
                                  : e.target.value.includes(".") &&
                                    e.target.value.split(".")[1] &&
                                    e.target.value.split(".")[1].length > 2
                                  ? Number(e.target.value).toFixed(2)
                                  : Number(e.target.value),
                              };
                            });
                            // setTaskList((prev) =>
                            //   prev.map((tsk) => {
                            //     if (tsk.temp_id == task.temp_id) {
                            //       return {
                            //         ...tsk,
                            //         task_billable_amount:
                            //           e.target.value.endsWith(".")
                            //             ? e.target.value
                            //             : e.target.value.includes(".") &&
                            //               e.target.value.split(".")[1] &&
                            //               e.target.value.split(".")[1].length >
                            //                 2
                            //             ? Number(e.target.value).toFixed(2)
                            //             : Number(e.target.value),
                            //       };
                            //     } else {
                            //       return { ...tsk };
                            //     }
                            //   })
                            // );
                          }
                        }}
                      />
                    </FormControl>
                  </Stack>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeNewTask}>Cancel</Button>
                <Button onClick={handleAddTask}>Add</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        {step == 3 && (
          <div className="pb-3 pt-5">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Reminder Title"
                    required
                    value={inputs.reminder_title}
                    name="reminder_title"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Reminder Date"
                      inputFormat="DD-MMM-YYYY"
                      rifmFormatter={(date) =>
                        moment(date).format("DD-MMM-YYYY")
                      }
                      value={inputs.reminder_date}
                      onChange={(newValue) =>
                        setInputs({
                          ...inputs,
                          reminder_date: newValue
                            ? moment(newValue).format("DD-MMM-YYYY")
                            : null,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          name="reminder_date"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MMM-YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <RichEditor
                  placeHolder="Reminder Description"
                  value={inputs.reminder_description}
                  setValue={(val) =>
                    setInputs((prev) => {
                      return { ...prev, reminder_description: val };
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div className="d-flex justify-content-end align-items-center">
                  <LoadingButton
                    loading={loadingBtns.draft}
                    disabled={loadingBtns.add}
                    variant="outlined"
                    onClick={() => handleSave(true)}
                  >
                    Save as draft
                  </LoadingButton>
                  <LoadingButton
                    disabled={loadingBtns.draft}
                    loading={loadingBtns.send}
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={() => handleSave(false)}
                  >
                    Send
                  </LoadingButton>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </Page>
  );
}

export default AddInvoice;
