import { Button, Container, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { MuiDialog, NavBackHeader, NoData, Page } from "src/components";
import { useAppContext } from "src/hooks";
import InvitationCard from "./components/InvitationCard";
import { filter, replace } from "lodash";
import {
  _accept_workspace_invitation,
  _cancel_workspace_invitation,
  _invitation_list,
  _reject_workspace_invitation,
  _resend_workspace_invitation,
  _workspace_invitation_list,
} from "src/DAL";
import { useSnackbar } from "notistack";
import SentInvitationCard from "./components/sentInvitaionCard";
// ----------------------------------------
const Invitations = () => {
  /* need API for this route data */
  const { state } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingInv, setLoadingInv] = useState(true);
  const [invitations, setInvitations] = useState([]);
  const [sentInvitations, setSentInvitations] = useState([]);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendInvOpen, setResendInvOpen] = useState(false);
  const [cancelInvOpen, setCancelInvOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [type, setType] = useState("received");
  const {
    dispatch_get_current_selected_item,
    dispatch_get_search_value,
    dispatch_set_current_selected_item,
    dispatch_get_user_profile,
    socketEmit,
    setBadge,
  } = useAppContext();
  // ------------------------------------------------
  const handleLocalSearch = (input, array) => {
    let filtered = filter(
      array,
      (_item) =>
        _item.workspace.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  console.log(state, "location-state");
  const getInvitations = () => {
    // let filtered = handleLocalSearch(dispatch_get_search_value(), invitations);
    if (type == "received") {
      return invitations;
    } else {
      return sentInvitations;
    }
  };
  const openResendInvDialog = () => {
    setResendInvOpen(true);
  };
  const closeResendInvDialog = () => {
    setResendInvOpen(false);
  };
  const handleAccept = async (_data) => {
    const result = await _accept_workspace_invitation(null, _data._id);
    if (result.code === 200) {
      console.log(result, "res");
      setBadge((prev) => {
        return {
          ...prev,
          invitation: prev.invitation - 1,
        };
      });
      const receiver = [_data.sender_id];
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.invitation,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } accepted your invitation for joining ${
          _data.workspace.name
        } workspace as ${_data.role}`,
        link: `${window.location.origin}/invitations`,
      });
      let updated = invitations.filter((data) => {
        if (String(data._id) !== String(_data._id)) {
          return data;
        }
      });
      setInvitations(updated);
      enqueueSnackbar("Invitation Accepted Successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleReject = (_data) => {
    dispatch_set_current_selected_item(_data);
    setIsDialogOpen(true);
  };
  const handleResend = (data) => {
    dispatch_set_current_selected_item(data);
    openResendInvDialog();
  };
  const handleCancel = (data) => {
    dispatch_set_current_selected_item(data);
    openCancelInvDialog();
  };
  const openCancelInvDialog = () => {
    setCancelInvOpen(true);
  };
  const closeCancelInvDialog = () => {
    setCancelInvOpen(false);
  };
  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);
      const result = await _resend_workspace_invitation(
        dispatch_get_current_selected_item()._id
      );
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        closeResendInvDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while resending invitation");
    } finally {
      setResendLoading(false);
    }
  };
  const handleCancelWorkspaceInvite = async () => {
    // const id = currentInvItem._id;
    const id = dispatch_get_current_selected_item()._id;
    setProcessing(true);
    const result = await _cancel_workspace_invitation(null, id);
    if (result.code === 200) {
      setSentInvitations((prev) => prev.filter((inv) => inv._id !== id));
      closeCancelInvDialog();
      enqueueSnackbar("Invitation cancelled successfully", {
        variant: "success",
      });
      setProcessing(false);
      // closeInvitationDrawer();
      /* filter current row if no workspaces & invitations left  for better ux, */
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeCancelInvDialog();
      // closeInvitationDrawer();
    }
  };
  const handleAgree = async (e) => {
    let item = dispatch_get_current_selected_item();
    setIsDialogOpen(false);
    console.log(item);
    setLoading(true);
    const result = await _reject_workspace_invitation(null, item._id);
    if (result.code === 200) {
      console.log(result, "res");
      setLoading(false);
      setBadge((prev) => {
        return {
          ...prev,
          invitation: prev.invitation - 1,
        };
      });
      let updated = invitations.filter((data) => {
        if (String(data._id) !== String(item._id)) {
          return data;
        }
      });
      setInvitations(updated);
      setSentInvitations();
      enqueueSnackbar("Invitation Rejected Successfully", {
        variant: "success",
      });
      socketEmit("send_notification_to_all", {
        receiver: [item.sender_id],
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } rejected your invitation for joining ${
          item.workspace.name
        } workspace as ${item.role}`,
      });
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getInvitationList = async () => {
    const result = await _workspace_invitation_list(null);
    if (result.code === 200) {
      console.log(result, "invitation-list-res");
      setInvitations(result.invitation);
      setSentInvitations(result.send_invitation);
      setLoadingInv(false);
    } else {
      setLoadingInv(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ------------------------------------------------
  useLayoutEffect(() => {
    getInvitationList();
  }, []);
  // ------------------------------------------------

  return (
    <Page title="Invitations">
      <Container maxWidth="xl">
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <NavBackHeader heading="Workspace Invitations" />
          {/* <div className="d-flex">
            <Button
              variant={type == "received" ? "contained" : "outlined"}
              onClick={() => setType("received")}
              sx={{ minWidth: "100px" }}
            >
              Received
            </Button>
            <Button
              variant={type == "sent" ? "contained" : "outlined"}
              onClick={() => setType("sent")}
              sx={{ ml: 2, minWidth: "100px" }}
            >
              Sent
            </Button>
          </div> */}
        </div>
        <div className="custom-tabs mb-2">
          <Tabs value={type} onChange={(e, val) => setType(val)}>
            <Tab label="Received" value={"received"} />
            <Tab label="Sent" value={"sent"} />
          </Tabs>
        </div>
        <Grid container spacing={3}>
          {loadingInv === false && (
            <>
              {type == "received" &&
                getInvitations().map((data, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={3} xl={2}>
                      <InvitationCard
                        onAccept={(data) => handleAccept(data)}
                        onReject={(data) => handleReject(data)}
                        item={data}
                        key={index}
                      />
                    </Grid>
                  );
                })}
              {type == "sent" &&
                getInvitations().map((data, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={3} xl={2}>
                      <SentInvitationCard
                        onCancel={(data) => handleCancel(data)}
                        onResend={(data) => handleResend(data)}
                        item={data}
                        key={index}
                      />
                    </Grid>
                  );
                })}
            </>
          )}
          <NoData
            loading={loadingInv}
            dataLength={getInvitations().length}
            title="No Invitations Found"
          />
        </Grid>
      </Container>
      <MuiDialog
        title="Reject Invitation"
        message="Are you sure you want to reject this invitation?"
        enableDismiss={true}
        open={isDialogOpen}
        onToggle={(value) => setIsDialogOpen(value)}
        onAgree={handleAgree}
        loading={loading}
      />
      <MuiDialog
        open={cancelInvOpen}
        message="Are you sure you want to cancel your invite?"
        title="Cancel invite"
        onToggle={(value) => setCancelInvOpen(value)}
        onAgree={handleCancelWorkspaceInvite}
        loading={processing}
      />
      <MuiDialog
        open={resendInvOpen}
        message="Are you sure you want to re-send this invitation?"
        title="Resend invite"
        onToggle={(value) => setResendInvOpen(value)}
        onAgree={handleResendInvitation}
        loading={resendLoading}
      />
    </Page>
  );
};

export default Invitations;
