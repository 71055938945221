import { get_from_localStorage, invokeApi } from "src/utils";
export const _get_template_sections_list = (data, id, page) => {
  const requestObj = {
    path: `api/section_template/section_template_list/${id}?page=${page}&limit=15`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_template_section = (data) => {
  const requestObj = {
    path: `api/section_template/add_section_template`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_template_section = (data, id) => {
  const requestObj = {
    path: `api/section_template/edit_section_template/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_template_section = (id) => {
  const requestObj = {
    path: `api/section_template/delete_section_template/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _make_template_section_default = (data, id) => {
  const requestObj = {
    path: `api/section_template/make_section_template_default/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_template_task = (data, id) => {
  const requestObj = {
    path: `api/template_task/add_template_task/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_template_task = (id, data) => {
  const requestObj = {
    path: `api/template_task/edit_template_task/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _template_task_list = (data, id) => {
  const requestObj = {
    path: `api/template_task/task_template_list/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _load_more_template_tasks = async (path, data) => {
  const requestObj = {
    path: path,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_template_task = (id) => {
  const requestObj = {
    path: `api/template_task/delete_template_task/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_template_task_order = (data) => {
  const requestObj = {
    path: `api/template_task/update_template_task_order`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_template_section_order = (data, id) => {
  const requestObj = {
    path: `api/section_template/change_template_section_order/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
