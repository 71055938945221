import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Iconify,
  Label,
  LabelPopup,
  MuiDialog,
  PriorityPopover,
  RichEditor,
  Scrollbar,
  StatusPopover,
  TaskDatePick,
  TeamPicker,
  UserAvatar,
} from "src/components";
import "./css/style.css";
import TaskComment from "./TaskComment";
import SidebarButton from "./SidebarButton";
import PriorityButton from "./components/PriorityButton";
import StatusButton from "./components/StatusButton";
import { useSnackbar } from "notistack";
import { _edit_task, _update_task_files } from "src/DAL";
import MembersButton from "./components/MembersButton";
import LabelDialog from "./components/LabelDialog";
import { s3baseUrl } from "src/config";
import {
  ALLOWED_TASK_FILE_TYPES,
  FILE_ACCEPT_TYPE_STRING,
  TASK_PRIORITY_OPTIONS,
  TASK_STATUS_OPTIONS,
} from "src/constants";
import { isColorLight, upload_task_files } from "src/utils";
import CheckList from "./components/CheckList";
import {
  _remove_task_checklist,
  _task_detail,
  _update_task_checklist_item,
} from "src/DAL/taskDetailDrawer";
import CheckListItem from "./components/CheckListItem";
import { makeStyles } from "@mui/styles";
import TargetDate from "./components/TargetDate";
import moment from "moment";
import Times from "./components/Times";
import { LoadingButton } from "@mui/lab";
import { useAppContext } from "src/hooks";
import { useDropzone } from "react-dropzone";
import { WifiTetheringErrorRoundedTwoTone } from "@mui/icons-material";
import { capitalCase } from "change-case";
import { string_with_limited_length } from "src/utils/common";
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
    zIndex: 1100,
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});
function TaskDetailDialog({
  open,
  onClose,
  projectTeam,
  taskFiles,
  projectTags,
  setProjectTags,
  onChangeTags,
  task,
  setTask,
  getFileIconType,
  onChangePriority,
  onChangeStatus,
  onChangeMembers,
  handleSendComment,
  newComment,
  setNewComment,
  onDelComment,
  editCommentTitle,
  setEditCommentTitle,
  onEditComment,
  handleUpdateTaskComment,
  editingCommentId,
  setEditingCommentId,
  setTasks,
  getFilteredTasksBySection = () => {},
  onChangeDate,
  getListing = null,
  addCommentLoading,
  sections = [],
  canMoveTask = false,
  onMovetoWorkspace,
  taskDetailLoading = false,
  setTaskDetailLoading = () => {},
  onDelTask = () => {},
}) {
  const nodeListRef = useRef();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_get_user_profile } = useAppContext();
  let textareaRef = useRef(null);
  const editorRef = useRef(null);
  const [taskTitle, setTaskTitle] = useState(task?.task_title);
  const [rendered, setRendered] = useState(false);
  const [description, setDescription] = useState(task?.task_description);
  const [editorOpen, setEditorOpen] = useState(false);
  const [attachments, setAttachments] = useState(taskFiles);
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const [currentFileToremove, setCurrentFileToremove] = useState("");
  const mceWindowRef = useRef(null);
  const [newChecklistItem, setNewChecklistItem] = useState("");
  const [addingChecklistItem, setAddingChecklistItem] = useState("");
  const [deleteChecklistDialogOpen, setDeleteChecklistDialogOpen] =
    useState(false);
  const [editChecklistItem, setEditChecklistItem] = useState("");
  const [targetChecklist, setTargetChecklist] = useState();
  const [targetChecklistItem, setTargetChecklistItem] = useState();
  const [addFilesLoading, setAddFilesLoading] = useState(false);
  const [labelDialogOpen, setDialogLabelOpen] = useState(false);
  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
  const [isChildVisible, setChildVisible] = useState(false);
  const [deletechecklistitemDialog, setDeletechecklistitemDialog] =
    useState(false);
  const [times, setTimes] = useState({
    ...task?.time_track,
  });
  const handleDragEnter = (e) => {
    // e.preventDefault();

    setChildVisible(true);
  };

  console.log(task, "--task");
  console.log(sections, "--sections");

  const getTaskSection = (section_id) => {};

  const handleDragLeave = (e) => {
    // e.preventDefault();
    setChildVisible(false);
  };
  const onDrop = (acceptedFiles) => {
    let e = {
      target: {
        files: acceptedFiles,
      },
    };
    handleFileChange(e);
    console.log("Dropped files:", acceptedFiles);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml": [],
      "application/msword": [],
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [],
      "text/csv": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/vnd.ms-powerpoint": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "text/pdf": [],
      "video/mp4": [],
      "video/webm": [],
      "application/x-zip-compressed": [],
    }, // Specify accepted file types (e.g., images)
    noClick: true,
    noKeyboard: true,
    height: "100%",
    width: "100%",
  });

  const dropzoneStyles = {
    // border: "2px dashed #f00",
    border: isChildVisible || isDragActive ? "2px dashed #cccccc" : "",
    background: "rgba(255, 255, 255, 0.9)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    width: "40vw",
    height: "60vh",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    transition: "border .3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: isChildVisible || isDragActive ? "0.8" : "1",
    zIndex: 10,
    fontWeight: "600",
    fontSize: "15px",
  };
  if (isDragActive && isDragAccept) {
    dropzoneStyles.border = "2px solid #4caf50"; // Accentuate with green border during drag
  } else if (isDragActive && isDragReject) {
    dropzoneStyles.border = "2px solid #f44336"; // Accentuate with red border if rejected
  }
  const handleChange = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset the height to auto
    textarea.style.height = textarea.scrollHeight + "px"; // Set the height to match the content

    setTaskTitle(textarea.value);
  };
  const canEditTask = (type = true) => {
    if (task?.creator) {
      return true;
    } else if (
      task?.creator_profile.user_id == dispatch_get_user_profile().user_id
    ) {
      return true;
    } else if (task.role == "admin" || task.role == "manager") {
      return true;
    }
    let found = task?.team.find(
      (user) => user.user_id == dispatch_get_user_profile().user_id
    );
    if (found && type) {
      return true;
    }

    return false;
  };
  const setNewCommentCallback = useCallback(
    (val) => {
      setNewComment(val);
    },
    [newComment]
  );

  const handleEditClick = (comment) => {
    if (editingCommentId === comment._id) {
      setEditingCommentId("");
    } else {
      setEditingCommentId(comment._id);
      onEditComment(comment);
    }
  };
  const closeRemoveFileDialog = () => {
    setDeleteFileDialogOpen(false);
    setCurrentFileToremove("");
  };
  const openLabelDialog = () => {
    canEditTask() && setDialogLabelOpen(true);
  };
  const closeLabelDialog = () => {
    setDialogLabelOpen(false);
  };
  const openDeleteTaskDialog = () => {
    setDeleteTaskDialogOpen(true);
  };
  const closeDeleteTaskDialog = () => {
    setDeleteTaskDialogOpen(false);
  };
  const handleFileChange = async (e) => {
    setAddFilesLoading(true);
    console.log(e.target.files[0]);
    let final_files = [];
    let files = e.target.files;
    console.log(files, "target-files");
    for (let i = 0; i < files.length; i++) {
      console.log(files[i], "file");
    }
    for (let i = 0; i < files.length; i++) {
      ALLOWED_TASK_FILE_TYPES.map((item, index) => {
        if (item === String(files[i].type)) {
          console.log(files[i].type, "type");
          if (
            files[i].type.includes("application") ||
            files[i].type.includes("text")
          ) {
            final_files.push({ file: files[i], type: "file" });
          }
          if (files[i].type.includes("image")) {
            final_files.push({ file: files[i], type: "image" });
          }
          if (files[i].type.includes("video")) {
            final_files.push({ file: files[i], type: "video" });
          }
        }
      });
    }
    let paths = [];
    for (let i = 0; i < final_files.length; i++) {
      let res = await upload_task_files(final_files[i].file);
      paths.push(res.path);
    }
    paths = [...task?.image].concat(paths);

    updateTaskFiles(paths, "add");
    // setAddTaskFiles([...addTaskFiles, ...final_files]);
  };
  const updateTaskFiles = async (files, action) => {
    try {
      const req_obj = {
        task_id: task?._id,
        list_type: 0,
        images: files,
      };
      const result = await _update_task_files(req_obj);
      if (result.code == 200) {
        if (action == "add") {
          setAttachments([]);
          for (let path of files) {
            let data = getFileIconType(path);
            const { file_type, file_path, color } = data;
            setAttachments((prev) => [
              ...prev,
              {
                path: file_path,
                type: file_type,
                db_path: path,
                color: color,
              },
            ]);
            // files.push({
            //   path: file_path,
            //   type: file_type,
            //   db_path: path,
            //   color: color,
            // });
          }
        } else {
          setAttachments((prev) =>
            prev.filter((item) => item.db_path !== currentFileToremove)
          );
        }
        setTask(result.task_detail);
        setTasks(task?.section_id, task?._id, result.task_detail);
        closeRemoveFileDialog();
      }
    } catch (error) {
      console.log(error, " while updating task files");
    } finally {
      setAddFilesLoading(false);
    }
  };
  const handleCopyTaskLink = async (task) => {
    const { section_id, _id } = task;
    let project_id = task?.project.id;
    console.log(task, "to-copy");
    let newLink = "";
    newLink = `${window.location.origin}/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`;
    console.log(newLink);
    // unsecuredCopyToClipboard(newLink);
    let has_error = "";
    try {
      await window.navigator.clipboard.writeText(newLink);
    } catch (error) {
      if (error) {
        if (error.message) {
          has_error = error.message;
        }
      }
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    if (has_error === "") {
      enqueueSnackbar("Task link copied to clipboard", {
        variant: "success",
      });
      return;
    }
    enqueueSnackbar("Could not copy task link", { variant: "error" });
  };
  const updateTask = async () => {
    try {
      const req_obj = {
        task_id: task?._id,
        image: attachments.map((file) => {
          return file.db_path;
        }),
        task_title: taskTitle,
        task_description: description,
        time_track: {
          estimated_time:
            times.estimated_time && !times.estimated_time.includes(":")
              ? times.estimated_time.padStart(2, "0") + ":00"
              : times.estimated_time &&
                times.estimated_time.includes(":") &&
                times.estimated_time.split(":")[1].length < 2
              ? times.estimated_time.split(":")[0] +
                ":" +
                times.estimated_time.split(":")[1].padStart(2, "0")
              : times.estimated_time,
          actual_time:
            times.actual_time && !times.actual_time.includes(":")
              ? times.actual_time.padStart(2, "0") + ":00"
              : times.actual_time &&
                times.actual_time.includes(":") &&
                times.actual_time.split(":")[1].length < 2
              ? times.actual_time.split(":")[0] +
                ":" +
                times.actual_time.split(":")[1].padStart(2, "0")
              : times.actual_time,
          final_time:
            times.final_time && !times.final_time.includes(":")
              ? times.final_time.padStart(2, "0") + ":00"
              : times.final_time &&
                times.final_time.includes(":") &&
                times.final_time.split(":")[1].length < 2
              ? times.final_time.split(":")[0] +
                ":" +
                times.final_time.split(":")[1].padStart(2, "0")
              : times.final_time,
        },
      };
      const result = await _edit_task(req_obj);
      if (result.code !== 200) {
        enqueueSnackbar(result.message, { variant: "error" });
      } else {
        if (task?.project_data) {
          setTask((prev) => {
            return { ...result.task_data, project_data: prev.project_data };
          });
        } else {
          setTask(result.task_data);
        }
        setTasks(task?.section_id, task?._id, result.task_data);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
  };
  const excludedStatusOptions = () => {
    const currentUserRole = task?.role;
    const creator_profile = task?.creator_profile;

    console.log(currentUserRole, "currentUserRole");
    console.log(creator_profile, "creator_profile");

    if (!currentUserRole) {
      return [5];
    }
    if (creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return [5];
    }
    if (currentUserRole === "admin") {
      return [5];
    }
    if (currentUserRole === "manager") {
      return [5];
    }
    if (currentUserRole === "client" || currentUserRole === "client_team") {
      return [5];
    }
    if (currentUserRole === "team") {
      return [4, 5]; /* exclude verified status for team */
    }
  };
  const onPriorityChange = (_data) => {
    const data = {
      task: task,
      section: { _id: task?.section_id },
      priority: _data.value,
    };
    onChangePriority(data, task?.workspace.id);
  };
  const onTaskStatusChange = (_data) => {
    const data = {
      task: task,
      section: { _id: task?.section_id },
      status: _data.value,
      is_checkbox: false,
    };
    onChangeStatus(data, task?.workspace.id);
  };
  const onTeamChange = (target_user, task_team, is_selected, action) => {
    const data = {
      task: task,
      section: { _id: task?.section_id },
      taskTeam: task_team,
      targetUser: target_user,
      isSelected: is_selected,
      action: action,
    };
    onChangeMembers(data, task?.workspace.id);
  };
  /////////===========Checklist Actions===================/////////////////
  const openDeleteChecklistDialog = (checklist) => {
    setTargetChecklist(checklist);
    setDeleteChecklistDialogOpen(true);
  };
  const closeDeleteChecklistDialog = () => {
    setDeleteChecklistDialogOpen(false);
    setTargetChecklist();
  };
  const deleteCheckList = async () => {
    try {
      const req_obj = {
        task_id: task?._id,
        list_id: targetChecklist._id,
      };
      const result = await _remove_task_checklist(req_obj);
      if (result.code == 200) {
        setTask(result.task_data);
        closeDeleteChecklistDialog();
        setTasks(task?.section_id, task?._id, result.task_data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveCheckListItem = () => {
    try {
      if (!newChecklistItem) {
        return;
      }
      const req_obj = {
        task_id: task?._id,
        list_id: targetChecklist._id,
        list_item_array: [
          ...targetChecklist.items.map((item) => {
            return { name: item.name, status: item.status };
          }),
          { name: newChecklistItem, status: false },
        ],
      };
      updateChecklistItems(req_obj);
    } catch (error) {
      console.log(error);
    }
  };

  const updateChecklistItems = async (req_obj) => {
    const result = await _update_task_checklist_item(req_obj);
    if (result.code == 200) {
      setTask(result.task_data);
      setTargetChecklist((prev) =>
        result.task_data.check_list.find((list) => list._id == prev._id)
      );
      setNewChecklistItem("");
      setTasks(task?.section_id, task?._id, result.task_data);
      closeChecklistItemDeleteDialog();
    }
  };

  const editChecked = (list, currentItem, updatedItem) => {
    setTargetChecklist(list);
    let req_obj = {
      task_id: task?._id,
      list_id: list._id,
      list_item_array: list.items.map((item) => {
        if (currentItem._id == item._id) {
          delete updatedItem._id;
          return updatedItem;
        } else {
          return { name: item.name, status: item.status };
        }
      }),
    };
    updateChecklistItems(req_obj);
  };
  const deleteChecklistItem = () => {
    let req_obj = {
      task_id: task?._id,
      list_id: targetChecklist._id,
      list_item_array: targetChecklist.items
        .filter((val) => val._id !== targetChecklistItem._id)
        .map((item) => {
          delete item._id;
          return item;
        }),
    };
    updateChecklistItems(req_obj);
  };
  const openChecklistItemDeleteDialog = () => {
    setDeletechecklistitemDialog(true);
  };
  const closeChecklistItemDeleteDialog = () => {
    setDeletechecklistitemDialog(false);
    setTargetChecklistItem();
  };
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setNewComment("");
  }, [editingCommentId]);
  useEffect(() => {
    if (taskTitle) {
      setRendered(true);
      const elm = document.getElementsByClassName("task-title")[0];
      if (elm) {
        elm.style.height = "auto";
        elm.style.height = elm.scrollHeight + "px";
      }
    }
    return () => {
      setRendered(false);
    };
  }, [rendered, taskTitle, task]);
  const getTaskDetail = async () => {
    try {
      const result = await _task_detail(task?._id);
      if (result.code == 200) {
        setTimes(result.task_detail.time_track);
        if (task?.sectionData) {
          setTask({ ...task, ...result.task_detail });
        } else {
          setTask({
            ...result.task_detail,
            sectionData: {
              _id: result.task_detail.section_id,
              task: [{ ...result.task_detail }],
            },
          });
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        onClose();
      }
    } catch (error) {
      console.log(error, " catched while fetchig task details");
      onClose();
    } finally {
      setTaskDetailLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      getTaskDetail();
      window.history.replaceState(null, "");
    }
    return () => {
      // setTask();
      setTaskDetailLoading(true);
    };
  }, [open]);
  useEffect(() => {
    setTaskTitle(task?.task_title);
    setDescription(task?.task_description);
    setAttachments(taskFiles);
    if (task && task.task_comment_unread_count > 0) {
      setTask((prev) => {
        return {
          ...prev,
          task_comment_unread_count: 0,
        };
      });
    }
  }, [task]);
  useEffect(() => {
    if (open) {
      window.addEventListener("dragenter", handleDragEnter);
      window.addEventListener("dragover", handleDragEnter);
      window.addEventListener("dragleave", handleDragLeave);
      window.addEventListener("drop", handleDragLeave);
    }
    return () => {
      removeEventListener("dragover", () => {
        console.log("event removed");
      });
      removeEventListener("dragleave", () => {
        console.log("event removed");
      });
      removeEventListener("drop", () => {
        console.log("event removed");
      });
    };
  }, [open]);
  useEffect(() => {
    if (nodeListRef.current) {
      const handleMutation = (mutations) => {
        mutations.forEach((mutation) => {
          // Check if the added or modified element is a textarea or grammarly-extension
          const targetElement = mutation.target;
          if (
            (targetElement instanceof HTMLTextAreaElement &&
              !targetElement.hasAttribute("data-gramm")) ||
            ((targetElement.tagName.toLowerCase() === "grammarly-extension" ||
              targetElement.tagName.toLowerCase() === "grammarly-popups" ||
              targetElement.tagName.toLowerCase() === "grammarly-mirror" ||
              targetElement.tagName.toLowerCase() ===
                "grammarly-desktop-integration") &&
              !targetElement.hasAttribute("data-removed"))
          ) {
            // Apply your logic for textareas and grammarly-extensions
            if (
              targetElement.tagName.toLowerCase() === "grammarly-extension" ||
              targetElement.tagName.toLowerCase() === "grammarly-popups" ||
              targetElement.tagName.toLowerCase() === "grammarly-mirror" ||
              targetElement.tagName.toLowerCase() ===
                "grammarly-desktop-integration"
            ) {
              targetElement.remove(); // Remove grammarly-extension elements
            } else if (targetElement instanceof HTMLTextAreaElement) {
              // Set attributes for textareas
              targetElement.setAttribute("data-gramm", "false");
              targetElement.setAttribute("data-gramm_editor", "false");
              targetElement.setAttribute("data-enable-grammarly", "false");
            }
            // Add additional logic as needed
          }
        });
      };

      // Create a MutationObserver with the callback function
      const observer = new MutationObserver(handleMutation);

      // Start observing changes for the container
      observer.observe(nodeListRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });

      // Cleanup function to disconnect the observer when the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [nodeListRef.current]);
  return (
    <>
      <Dialog
        id="modaaaal"
        disableEnforceFocus={true}
        open={open}
        onClose={() => {
          addFilesLoading &&
            enqueueSnackbar("Uploading Files!", { variant: "warning" });
          !addFilesLoading && onClose();
        }}
        maxWidth="md"
        fullWidth
        scroll="body"
        PaperProps={{ className: "dialog-paper" }}
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
        // sx={{
        //   "& .MuiDialog-container": {
        //     "& .MuiPaper-root": {
        //       // width: "100%",
        //       maxWidth: "40%", // Set your width here
        //     },
        //   },
        // }}
      >
        {task && (
          <DialogContent
            // onDragEnter={handleDragEnter}
            // onDragLeave={handleDragLeave}
            sx={{ p: 0, py: 1 }}
            onClick={() => {
              setEditorOpen(false);
              setAddingChecklistItem("");
              setNewChecklistItem("");
              setEditChecklistItem("");
            }}
          >
            {taskDetailLoading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "90vh" }}
              >
                <CircularProgress />
              </div>
            )}
            {!taskDetailLoading && (
              <div
                ref={nodeListRef}
                style={{ position: "relative" }}
                id="task-detail"
                // onDragEnter={handleDragEnter}
                // onDragLeave={handleDragLeave}
              >
                {(isChildVisible || isDragActive) && (
                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input
                      {...getInputProps()}
                      // style={{ height: "100%", width: "100%" }}
                    />
                    {isDragReject && <p>File type not accepted</p>}
                    {/* Render content only when drag is active */}
                    {(isDragActive || (!isDragReject && isChildVisible)) && (
                      <p>Drop files here</p>
                    )}
                  </div>
                )}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between align-items-center border-bottom ps-3 pe-3 pb-1 fs-10"
                      >
                        <div className="d-flex align-items-center task-top-header me-4">
                          <Typography className="fs-12 fw-5">
                            {string_with_limited_length(
                              task.workspace.name,
                              50
                            )}
                          </Typography>
                          <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
                          <Typography className="fs-12 fw-5">
                            {string_with_limited_length(
                              capitalCase(task.project.name),
                              50
                            )}
                          </Typography>
                          {task.sectionData &&
                            task.sectionData.section_title && (
                              <>
                                <Iconify
                                  icon="grommet-icons:next"
                                  sx={{ m: 1 }}
                                />
                                <Typography className="fs-12 fw-5">
                                  {string_with_limited_length(
                                    task.sectionData.section_title,
                                    40
                                  )}
                                </Typography>
                              </>
                            )}
                        </div>
                        <div className="d-flex justify-content-center">
                          <Iconify
                            icon="ion-close"
                            sx={{
                              height: "24px",
                              width: "24px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              addFilesLoading &&
                                enqueueSnackbar("Uploading Files!", {
                                  variant: "warning",
                                });
                              !addFilesLoading && onClose();
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={1}>
                        <div className="d-flex justify-content-center pt-2">
                          <Iconify
                            icon="fluent-mdl2:task-manager"
                            sx={{ height: "24px", width: "24px" }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={11}>
                        <div className="textarea-wrapper">
                          <textarea
                            style={{
                              resize: "none", // Prevent manual resizing
                              overflowY: "hidden", // Hide vertical scrollbar initially
                            }}
                            spellCheck={false}
                            ref={textareaRef}
                            className="task-title"
                            value={taskTitle}
                            onChange={handleChange}
                            disabled={!canEditTask(false)}
                            // onClick={(e) => e.stopPropagation()}
                            onBlur={() => {
                              if (!taskTitle.trim()) {
                                // document
                                //   .getElementsByClassName("task-title")[0]
                                //   .focus();
                                // setTaskTitle(task?.task_title);
                              } else {
                                updateTask();
                              }
                            }}
                          />
                          {/* <div contentEditable={true}>ascasiocuas</div> */}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={9} xs={12} className="window-main-col">
                    <Grid item xs={12}>
                      <div className="d-flex gap-1">
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "50px",
                              // paddingBottom: "2px",
                              fontWeight: "600",
                            }}
                          >
                            Created By:
                          </div>
                          <div
                            style={{ marginLeft: "50px" }}
                            className="d-flex align-items-center"
                          >
                            <div
                              className="sidebar-button align-items-center"
                              style={{
                                display: "flex",
                                fontWeight: "500",
                                minHeight: "36px",
                              }}
                            >
                              <UserAvatar
                                image={task?.creator_profile.image}
                                name={task?.creator_profile.first_name}
                                sx={{
                                  width: "25px",
                                  height: "25px",
                                  marginRight: "8px",
                                }}
                              />
                              {task?.creator_profile.first_name +
                                " " +
                                task?.creator_profile.last_name}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "50px",
                              // paddingBottom: "2px",
                              fontWeight: "600",
                            }}
                          >
                            Created At:
                          </div>
                          <div
                            style={{ marginLeft: "50px" }}
                            className="d-flex align-items-center"
                          >
                            <div
                              className="sidebar-button"
                              style={{
                                display: "flex",
                                fontWeight: "500",
                                minHeight: "36px",
                              }}
                            >
                              {moment(task?.createdAt).format("LLL")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {/* {task?.team.length > 0 && ( */}
                      <div
                        style={{ marginLeft: "50px", paddingTop: "16px" }}
                        className="d-flex flex-wrap"
                      >
                        {task?.team.map((member, i) => (
                          <div
                            key={i}
                            style={{
                              display: "inline-block",
                              marginLeft: "5px",
                            }}
                          >
                            <Tooltip
                              title={member.first_name + " " + member.last_name}
                            >
                              <div>
                                <UserAvatar
                                  sx={{ height: 35, width: 35 }}
                                  image={member.image}
                                  name={member.first_name}
                                  alt={member.first_name}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        ))}
                        <div
                          style={{
                            marginLeft: "5px",
                            display: "inline-block",
                          }}
                        >
                          <TeamPicker
                            taskTeam={task?.team}
                            projectTeam={projectTeam}
                            onChange={onTeamChange}
                            allowOpen={canEditTask()}
                          />
                        </div>
                      </div>
                      {/* )} */}
                    </Grid>

                    {/* {task?.tag_colors.length > 0 && ( */}
                    <Grid item xs={12}>
                      <h6 className="pt-2" style={{ marginLeft: "60px" }}>
                        Labels
                      </h6>
                      <Stack
                        direction={"row"}
                        flexWrap={"wrap"}
                        spacing={1}
                        sx={{
                          pl: 6.5,
                          // pt: 2,
                          "& > *:first-of-type": {
                            marginLeft: 1,
                          },
                        }}
                      >
                        {task?.tag_colors.map((tag, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                padding: "5px",
                                minWidth: "50px",
                                minHeight: "30px",
                                backgroundColor: tag.color_code,
                                color: isColorLight(tag.color_code)
                                  ? "#000"
                                  : "#fff",
                                borderRadius: "3px",
                                marginBottom: "8px",
                              }}
                            >
                              {tag.name}
                            </div>
                          );
                        })}
                        <div
                          className="sidebar-button p-0 d-flex align-items-center justify-content-center px-1"
                          style={{ maxHeight: "30px" }}
                          onClick={() => {
                            canEditTask() && openLabelDialog();
                          }}
                        >
                          <Iconify
                            icon="tabler:plus"
                            sx={{ width: "20px", height: "20px" }}
                          />
                        </div>
                      </Stack>
                    </Grid>
                    {/* )} */}
                    {/* {task?.end_date && ( */}
                    <Grid item xs={12}>
                      <div
                        style={{ marginLeft: "40px", paddingTop: "16px" }}
                        className="d-flex align-items-end"
                      >
                        {/* {task?.end_date && ( */}

                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "20px",
                              // paddingBottom: "2px",
                              fontWeight: "600",
                            }}
                          >
                            Target Date:
                          </div>
                          <div
                            style={{ marginLeft: "10px" }}
                            className="d-flex align-items-center"
                          >
                            <div>
                              <Checkbox
                                disabled={!canEditTask()}
                                checked={task?.task_status == 3}
                                onChange={(e) =>
                                  onChangeStatus(
                                    {
                                      task: task,
                                      section: { _id: task?.section_id },
                                      status: e.target.checked ? 3 : 0,
                                      is_checkbox: false,
                                    },
                                    task?.workspace.id
                                  )
                                }
                              />
                            </div>
                            <div
                              className="sidebar-button"
                              style={{
                                display: "flex",
                                fontWeight: "500",
                                minHeight: "36px",
                              }}
                            >
                              {/* {task?.end_date && (
                                <>
                                  {moment(task?.end_date).format(
                                    "MMM DD, YYYY [at] hh:mm a"
                                  )}
                                </>
                              )}
                              {!task?.end_date && ( */}
                              <TaskDatePick
                                hasPortal
                                value={task?.end_date}
                                onChange={(newDate) =>
                                  onChangeDate(
                                    {
                                      task: task,
                                      section: { _id: task?.section_id },
                                      endDate: newDate,
                                    },
                                    task?.workspace.id
                                  )
                                }
                                task={task}
                                disabled={!canEditTask()}
                              />
                              {/* )} */}
                              {task?.task_status == 3 && (
                                <Label sx={{ ml: 1 }} color="success">
                                  Completed!
                                </Label>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* )} */}
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "20px",
                              fontWeight: "600",
                            }}
                          >
                            Priority
                          </div>
                          <div
                            style={{
                              // display: "inline",
                              marginLeft: "20px",
                              fontWeight: "500",
                              minHeight: "36px",
                            }}
                          >
                            <PriorityButton
                              value={task?.priority}
                              allowOpen={canEditTask()}
                              onChange={onPriorityChange}
                            />
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "20px",
                              fontWeight: "600",
                            }}
                          >
                            Status
                          </div>
                          <div
                            style={{
                              marginLeft: "20px",
                              fontWeight: "500",
                              minHeight: "36px",
                            }}
                          >
                            <StatusButton
                              value={task?.task_status}
                              allowOpen={canEditTask()}
                              onChange={onTaskStatusChange}
                              extraStatus={task?.extra_status}
                              excludedOptions={excludedStatusOptions()}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container sx={{ ml: "60px", mt: 1 }} spacing={2}>
                        {task?.role !== "client" &&
                          task?.role !== "client_team" && (
                            <Grid item xs={3}>
                              <TextField
                                size="small"
                                label="Estimated Time"
                                value={times.estimated_time}
                                name="estimated_time"
                                onBlur={updateTask}
                                disabled={!canEditTask()}
                                onKeyDown={(e) => e.stopPropagation()}
                                placeholder="hh:mm"
                                error={
                                  !times?.estimated_time &&
                                  (task?.task_status == 0 ||
                                    task?.task_status == 2)
                                }
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  // Remove non-digit characters
                                  inputValue = inputValue.replace(
                                    /[^0-9:]/g,
                                    ""
                                  );
                                  // Limit the length to 5 characters
                                  inputValue = inputValue.slice(0, 5);

                                  if (inputValue.includes(":")) {
                                    let temp = inputValue.split(":");
                                    if (temp[0].length < 2) {
                                      temp[0] = temp[0].padStart(2, "0");
                                      inputValue = temp.join(":");
                                    }
                                  }
                                  if (
                                    inputValue.length > 2 &&
                                    !inputValue.includes(":")
                                  ) {
                                    // Add a colon after the first two digits, if necessary
                                    inputValue = `${inputValue.slice(
                                      0,
                                      2
                                    )}:${inputValue.slice(2)}`;
                                  }

                                  // Use a regular expression to match the desired time format (hh:mm)
                                  const regex =
                                    /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                                  if (
                                    regex.test(inputValue) ||
                                    inputValue === ""
                                  ) {
                                    let temp = inputValue.split(":");
                                    if (Number(temp[1]) > 59) {
                                      if (Number(temp[0]) < 99) {
                                        temp[1] = String(
                                          Number(temp[1] - 60)
                                        ).padStart(2, "0");
                                        temp[0] = String(Number(temp[0]) + 1);
                                      } else {
                                        temp[1] = String(Number(temp[1] - 60));
                                      }
                                      inputValue = temp.join(":");
                                    }
                                    setTimes((prev) => {
                                      return {
                                        ...prev,
                                        estimated_time: inputValue,
                                      };
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          )}
                        {task?.role !== "client" &&
                          task?.role !== "client_team" && (
                            <Grid item xs={3}>
                              <TextField
                                size="small"
                                label="Complete Time"
                                value={times.actual_time}
                                name="actual_time"
                                onBlur={updateTask}
                                disabled={!canEditTask()}
                                onKeyDown={(e) => e.stopPropagation()}
                                placeholder="hh:mm"
                                error={
                                  !times?.actual_time && task?.task_status == 3
                                }
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  // Remove non-digit characters
                                  inputValue = inputValue.replace(
                                    /[^0-9:]/g,
                                    ""
                                  );
                                  // Limit the length to 5 characters
                                  inputValue = inputValue.slice(0, 5);

                                  if (inputValue.includes(":")) {
                                    let temp = inputValue.split(":");
                                    if (temp[0].length < 2) {
                                      temp[0] = temp[0].padStart(2, "0");
                                      inputValue = temp.join(":");
                                    }
                                  }
                                  if (
                                    inputValue.length > 2 &&
                                    !inputValue.includes(":")
                                  ) {
                                    // Add a colon after the first two digits, if necessary
                                    inputValue = `${inputValue.slice(
                                      0,
                                      2
                                    )}:${inputValue.slice(2)}`;
                                  }

                                  // Use a regular expression to match the desired time format (hh:mm)
                                  const regex =
                                    /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                                  if (
                                    regex.test(inputValue) ||
                                    inputValue === ""
                                  ) {
                                    let temp = inputValue.split(":");
                                    if (Number(temp[1]) > 59) {
                                      if (Number(temp[0]) < 99) {
                                        temp[1] = String(
                                          Number(temp[1] - 60)
                                        ).padStart(2, "0");
                                        temp[0] = String(Number(temp[0]) + 1);
                                      } else {
                                        temp[1] = String(Number(temp[1] - 60));
                                      }
                                      inputValue = temp.join(":");
                                    }
                                    setTimes((prev) => {
                                      return {
                                        ...prev,
                                        actual_time: inputValue,
                                      };
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          )}
                        {task?.role !== "team" && (
                          <Grid item xs={3}>
                            <TextField
                              size="small"
                              label={
                                task?.role == "client" ||
                                task?.role == "client_team"
                                  ? "Completed Time"
                                  : "Billable Time"
                              }
                              value={times.final_time}
                              name="final_time"
                              disabled={
                                !canEditTask() ||
                                task?.role == "client" ||
                                task?.role == "client_team"
                              }
                              onBlur={updateTask}
                              onKeyDown={(e) => e.stopPropagation()}
                              placeholder="hh:mm"
                              // error={!times?.final_time}
                              onChange={(e) => {
                                let inputValue = e.target.value;

                                // Remove non-digit characters
                                inputValue = inputValue.replace(/[^0-9:]/g, "");
                                // Limit the length to 5 characters
                                inputValue = inputValue.slice(0, 5);

                                if (inputValue.includes(":")) {
                                  let temp = inputValue.split(":");
                                  if (temp[0].length < 2) {
                                    temp[0] = temp[0].padStart(2, "0");
                                    inputValue = temp.join(":");
                                  }
                                }
                                if (
                                  inputValue.length > 2 &&
                                  !inputValue.includes(":")
                                ) {
                                  // Add a colon after the first two digits, if necessary
                                  inputValue = `${inputValue.slice(
                                    0,
                                    2
                                  )}:${inputValue.slice(2)}`;
                                }

                                // Use a regular expression to match the desired time format (hh:mm)
                                const regex =
                                  /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                                if (
                                  regex.test(inputValue) ||
                                  inputValue === ""
                                ) {
                                  let temp = inputValue.split(":");
                                  if (Number(temp[1]) > 59) {
                                    if (Number(temp[0]) < 99) {
                                      temp[1] = String(
                                        Number(temp[1] - 60)
                                      ).padStart(2, "0");
                                      temp[0] = String(Number(temp[0]) + 1);
                                    } else {
                                      temp[1] = String(Number(temp[1] - 60));
                                    }
                                    inputValue = temp.join(":");
                                  }
                                  setTimes((prev) => {
                                    return {
                                      ...prev,
                                      final_time: inputValue,
                                    };
                                  });
                                }
                              }}
                            />
                          </Grid>
                        )}
                        {/* </div> */}
                      </Grid>
                      {/* )} */}
                    </Grid>
                    {/* )} */}
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <div className="d-flex justify-content-end pt-3">
                          <Iconify
                            icon="fluent:text-description-32-filled"
                            sx={{ height: "24px", width: "24px" }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={11}>
                        <div className="pt-3">
                          <h5 style={{ fontSize: "1.15rem" }}>Description</h5>
                          {!editorOpen && (
                            <div
                              className="no-description-div"
                              onClick={(e) => {
                                if (canEditTask(false)) {
                                  e.stopPropagation();
                                  setEditorOpen(true);
                                  setAddingChecklistItem("");
                                  setNewChecklistItem("");
                                  setEditChecklistItem("");
                                }
                              }}
                            >
                              {!description ? (
                                "Add a more detailed description..."
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-word",
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {editorOpen && (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setAddingChecklistItem("");
                                setNewChecklistItem("");
                                setEditChecklistItem("");
                              }}
                            >
                              <RichEditor
                                value={description}
                                setValue={setDescription}
                                onBlur={updateTask}
                                editorHeight={200}
                              />
                              <div className="d-flex mt-2">
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setEditorOpen(false);
                                    updateTask();
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{ ml: 1 }}
                                  onClick={() => {
                                    setDescription(task?.task_description);
                                    setEditorOpen(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    {attachments.length > 0 && (
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          <div className="d-flex justify-content-end pt-3">
                            <Iconify
                              icon="grommet-icons:attachment"
                              sx={{ height: "24px", width: "24px" }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className="pt-3 ps-1 w-100">
                            <div className="d-flex justify-content-between">
                              <h5>Attachments</h5>
                              {canEditTask() && (
                                <div>
                                  <label htmlFor="icon-button-file">
                                    <input
                                      style={{ display: "none" }}
                                      accept={FILE_ACCEPT_TYPE_STRING}
                                      id="icon-button-file"
                                      type="file"
                                      value=""
                                      onChange={handleFileChange}
                                      multiple
                                      disabled={addFilesLoading}
                                    />
                                    <div
                                      className="sidebar-button d-flex justify-content-center"
                                      style={{ minWidth: "55px" }}
                                    >
                                      {!addFilesLoading && <>Add</>}
                                      {addFilesLoading && (
                                        <div>
                                          <CircularProgress size={"15px"} />
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>
                              )}
                            </div>
                            <Grid container spacing={1}>
                              {attachments.map((item, index) => {
                                return (
                                  <Grid key={index} item xs={4} sm={3} md={2}>
                                    <div className="d-flex">
                                      {item.type === "file" ? (
                                        <a
                                          href={s3baseUrl + item.db_path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Iconify
                                            sx={{
                                              height: "4rem",
                                              width: "4rem",
                                              color: item.color,
                                            }}
                                            icon={item.path}
                                          />
                                        </a>
                                      ) : (
                                        <a
                                          href={s3baseUrl + item.path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            className="add-task-img"
                                            src={s3baseUrl + item.path}
                                          />
                                        </a>
                                      )}
                                      {canEditTask() && (
                                        <Iconify
                                          sx={{
                                            color: "red",
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setCurrentFileToremove(
                                              item.db_path
                                            );
                                            setDeleteFileDialogOpen(true);
                                          }}
                                          icon="gridicons:cross-circle"
                                        />
                                      )}
                                      {/* </IconButton> */}
                                    </div>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {task?.check_list?.map((checklist, i) => {
                      return (
                        <CheckListItem
                          key={i}
                          checklist={checklist}
                          openDeleteChecklistDialog={openDeleteChecklistDialog}
                          editChecked={editChecked}
                          setTargetChecklist={setTargetChecklist}
                          handleSaveCheckListItem={handleSaveCheckListItem}
                          newChecklistItem={newChecklistItem}
                          setNewChecklistItem={setNewChecklistItem}
                          addingChecklistItem={addingChecklistItem}
                          setAddingChecklistItem={setAddingChecklistItem}
                          setEditorOpen={setEditorOpen}
                          setTargetChecklistItem={setTargetChecklistItem}
                          editChecklistItem={editChecklistItem}
                          setEditChecklistItem={setEditChecklistItem}
                          openChecklistItemDeleteDialog={
                            openChecklistItemDeleteDialog
                          }
                          canEditTask={canEditTask()}
                        />
                      );
                    })}
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <div className="d-flex align-items-end justify-content-end h-100">
                          <Iconify
                            icon="majesticons:comments-line"
                            sx={{ height: "24px", width: "24px" }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={11}>
                        <div className="pt-3">
                          <h5
                            style={{
                              fontSize: "1.15rem",
                              marginBottom: "3px",
                            }}
                          >
                            Comments
                          </h5>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="p-4 pe-0 pb-0 d-flex">
                          <UserAvatar
                            image={dispatch_get_user_profile().image}
                            alt={dispatch_get_user_profile().first_name}
                            name={dispatch_get_user_profile().first_name}
                          />
                          <div className="px-2 w-100">
                            {editingCommentId !== "add" && (
                              <div
                                className="comment-data add-comment-field"
                                onClick={() => setEditingCommentId("add")}
                              >
                                Write a comment...
                              </div>
                            )}
                            {editingCommentId === "add" && (
                              <>
                                <RichEditor
                                  value={newComment}
                                  setValue={setNewCommentCallback}
                                  editorRef={editorRef}
                                  editorHeight={200}
                                  placeHolder="Write a comment..."
                                />
                                <LoadingButton
                                  loading={addCommentLoading}
                                  variant="contained"
                                  size="small"
                                  sx={{ mt: 1 }}
                                  onClick={() => {
                                    handleSendComment();
                                    setEditingCommentId("");
                                  }}
                                >
                                  Save
                                </LoadingButton>
                              </>
                            )}
                          </div>
                        </div>
                        {[...task?.task_comment]
                          ?.reverse()
                          .map((comment, index) => (
                            <>
                              <TaskComment
                                key={comment._id}
                                comment={comment}
                                onEdit={() => {}}
                                isEditing={editingCommentId === comment._id}
                                onEditClick={handleEditClick}
                                onDelComment={onDelComment}
                                editCommentTitle={editCommentTitle}
                                setEditCommentTitle={setEditCommentTitle}
                                handleUpdateTaskComment={
                                  handleUpdateTaskComment
                                }
                              />
                            </>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Stack spacing={1} sx={{ padding: "0 16px 8px 8px" }}>
                      <CheckList
                        canEditTask={canEditTask()}
                        task={task}
                        setTask={setTask}
                        setTasks={setTasks}
                      />
                      <MembersButton
                        canEditTask={canEditTask()}
                        taskTeam={task?.team}
                        projectTeam={projectTeam}
                        onChange={(
                          target_user,
                          taskTeam,
                          is_selected,
                          action
                        ) =>
                          onTeamChange(
                            target_user,
                            taskTeam,
                            is_selected,
                            action
                          )
                        }
                      />
                      <label htmlFor="icon-button-file">
                        <input
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          onChange={handleFileChange}
                          multiple
                          disabled={addFilesLoading || !canEditTask()}
                        />
                        <div
                          className="sidebar-button d-flex align-items-center"
                          id="attachment_sidebar_div"
                        >
                          Attachments
                          <IconButton
                            onClick={() => {
                              document
                                .getElementById("attachment_sidebar_div")
                                .click();
                            }}
                          >
                            <Iconify icon="grommet-icons:attachment" />
                          </IconButton>
                          {addFilesLoading && (
                            <CircularProgress size={"15px"} />
                          )}
                        </div>
                      </label>
                      <div
                        className="sidebar-button d-flex align-items-center"
                        onClick={() => handleCopyTaskLink(task)}
                      >
                        Copy Task Link
                        <IconButton
                          sx={{
                            bgcolor: (theme) => theme.palette.action.hover,
                          }}
                          // size="small"
                        >
                          <Iconify
                            sx={{ width: "1rem", height: "1rem" }}
                            icon="clarity:copy-line"
                          />
                        </IconButton>
                      </div>
                      {canMoveTask &&
                        canEditTask() &&
                        (task?.role == "admin" || task?.role == "manager") && (
                          <div
                            className="sidebar-button d-flex align-items-center"
                            onClick={() =>
                              onMovetoWorkspace(task, task?.workspace.id)
                            }
                          >
                            Copy Task
                            <IconButton
                              sx={{
                                bgcolor: (theme) => theme.palette.action.hover,
                              }}
                              // size="small"
                            >
                              <Iconify
                                sx={{ width: "1rem", height: "1rem" }}
                                icon="material-symbols-light:move-down"
                              />
                            </IconButton>
                          </div>
                        )}
                      {canEditTask() && (
                        <div
                          className="sidebar-button d-flex justify-content-center align-items-center"
                          style={{ color: "#bf2626" }}
                          onClick={onDelTask}
                        >
                          Delete Task
                          <IconButton
                            sx={{
                              bgcolor: (theme) => theme.palette.action.hover,
                            }}

                            // size="small"
                          >
                            <Iconify
                              sx={{ width: "1rem", height: "1rem" }}
                              icon="mingcute:delete-line"
                              color="#bf2626"
                            />
                          </IconButton>
                        </div>
                      )}
                    </Stack>
                    <h5
                      style={{
                        fontSize: "1.15rem",
                        marginBottom: 0,
                        padding: "8px 0px 0px 16px",
                      }}
                    >
                      Activity
                    </h5>
                    <div style={{ height: "50vh" }}>
                      <Scrollbar sx={{ height: "50vh" }}>
                        <Stack spacing={1} sx={{ padding: "8px 16px 8px 8px" }}>
                          {task?.activity_track?.track_list
                            ?.filter((track) =>
                              Boolean(
                                track.allow_members.find(
                                  (id) =>
                                    id == dispatch_get_user_profile().user_id
                                )
                              )
                            )
                            .map((activity, i) => {
                              return (
                                <Stack key={i} direction={"row"} spacing={0.5}>
                                  <Iconify
                                    icon="octicon:dot-fill-24"
                                    color="#00ab55"
                                    sx={{ height: "20px", width: "20px" }}
                                  />
                                  <Stack spacing={0.3}>
                                    <Typography fontSize={"12px"}>
                                      {activity.text}
                                    </Typography>
                                    <Typography fontSize={"9px"}>
                                      {moment(activity.created_at).format(
                                        "LLL"
                                      )}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              );
                            })}
                        </Stack>
                      </Scrollbar>
                    </div>
                  </Grid>
                  <LabelDialog
                    projectId={task?.project?.id}
                    projectTags={projectTags}
                    setProjectTags={setProjectTags}
                    onChangeTags={onChangeTags}
                    task={task}
                    sections={sections}
                    getFilteredTasksBySection={getFilteredTasksBySection}
                    getListing={getListing}
                    openDialog={openLabelDialog}
                    closeDialog={closeLabelDialog}
                    dialogOpen={labelDialogOpen}
                    setDialogOpen={setDialogLabelOpen}
                    setSelectedTask={setTask}
                  />
                </Grid>
              </div>
            )}
          </DialogContent>
        )}
      </Dialog>

      <MuiDialog
        open={deleteFileDialogOpen}
        onToggle={(val) => setDeleteFileDialogOpen(false)}
        title="Remove Attachment"
        message="Are you sure you want to remove this attachment?"
        onAgree={() => {
          console.log("first");
          updateTaskFiles(
            taskFiles
              .filter((item) => item.db_path !== currentFileToremove)
              .map((file) => {
                return file.db_path;
              }),
            "remove"
          );
        }}
      />
      <MuiDialog
        open={deleteChecklistDialogOpen}
        onToggle={closeDeleteChecklistDialog}
        onAgree={deleteCheckList}
        title="Delete Checklist"
        message="Are you sure you want to delete this checklist"
      />
      <MuiDialog
        open={deletechecklistitemDialog}
        onToggle={closeChecklistItemDeleteDialog}
        title="Delete Checklist Item"
        message="Are you sure you want to delete this item from checklist?"
        onAgree={deleteChecklistItem}
      />
    </>
  );
}

export default TaskDetailDialog;
