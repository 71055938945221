import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useMemo } from "react";
import ManageCard from "src/components/Manage-Card/ManageCard";
import { _get_stripe_key } from "src/DAL/plans";

function PaymentWrapper({ openModal, handleCloseModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const [stripeKey, setStripeKey] = useState(null);

  const stripePromise = useMemo(() => {
    if (stripeKey) {
      return loadStripe(stripeKey);
    }
    return null;
  }, [stripeKey]);

  const getStripeKeys = async () => {
    try {
      const result = await _get_stripe_key();
      if (result.code === 200) {
        setStripeKey(result.data);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching Stripe key:", error);
      enqueueSnackbar("Failed to load Stripe key", { variant: "error" });
    }
  };

  useEffect(() => {
    getStripeKeys();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <ManageCard openModal={openModal} handleCloseModal={handleCloseModal} />
    </Elements>
  );
}

export default PaymentWrapper;
