import React from "react";

function PaymentBanner({ activePlanDetail = {} }) {
  console.log(activePlanDetail, "activePlanDetail");

  const convertCurrencyToSign = (currency) => {
    if (currency === "gbp") {
      return "£";
    } else if (currency === "eur") {
      return "€";
    } else if (currency === "usd") {
      return "$";
    } else if (currency === "aud") {
      return "A$";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="text-center text-md-start col-12 col-lg-8 col-md-12"
          style={{ outline: "none" }}
        >
          {activePlanDetail?.trial_period_days ? (
            <div className="wb-editor-block">
              <h3
                className="wb-fw-400"
                style={{ textAlign: "center", lineHeight: "1.3" }}
              >
                <span style={{ fontSize: "2.25rem" }}>
                  <strong>
                    {`Start your ${activePlanDetail?.trial_period_days}-day trial, `}
                    <span style={{ color: "#00AB55" }}>FOR FREE</span>
                  </strong>
                </span>
              </h3>
              <p className="wb-fw-400" style={{ textAlign: "center" }}>
                <span
                  style={{ fontSize: "1.125rem" }}
                  data-mce-style="font-size: 1.125rem;"
                >
                  Get Your {activePlanDetail?.trial_period_days}
                  -Day Free Trial After Free Trial: ${activePlanDetail?.price}/
                  {activePlanDetail?.plan_type} + applicable taxes
                </span>
              </p>
            </div>
          ) : (
            <div className="wb-editor-block">
              <h3
                className="wb-fw-400"
                style={{ textAlign: "center", lineHeight: "1.3" }}
              >
                <span style={{ fontSize: "2.25rem" }}>
                  <strong>
                    {`Get your `}
                    <span style={{ color: "#00AB55" }}>
                      {activePlanDetail?.name}
                    </span>
                    {" Plan"}
                  </strong>
                </span>
              </h3>
              <p
                className="wb-fw-400"
                style={{ textAlign: "center" }}
                data-mce-style="text-align: center;"
              >
                <span style={{ fontSize: "1.125rem" }}>
                  Get Your {activePlanDetail?.name}
                  Plan for
                  {activePlanDetail?.is_plan_free === false ? ":" : ""}
                  {activePlanDetail?.currency_symbol}
                  {activePlanDetail?.price}/{activePlanDetail?.plan_type} +
                  applicable taxes
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PaymentBanner;
