import { Button, IconButton, Menu, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { _add_task_checklist } from "src/DAL/taskDetailDrawer";
import Iconify from "src/components/Iconify";

function Times({ times, setTimes, task, updatetask }) {
  console.log(task, "fasuiayhaskchaiuha");
  const [open, setOpen] = useState();
  const handleOpen = (e) => {
    setOpen(e.target);
  };
  const handleClose = () => {
    setTimes({ ...task.time_track });
    setOpen();
  };
  const handleChangeTime = (e) => {
    setTimes((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  useEffect(handleClose, [task]);
  return (
    <div>
      <div
        className="sidebar-button d-flex align-items-center"
        onClick={handleOpen}
      >
        Times
        <IconButton
          sx={{ bgcolor: (theme) => theme.palette.action.hover }}
          size="small"
        >
          <Iconify
            sx={{ width: "24px", height: "24px" }}
            icon="ic:outline-more-time"
          />
        </IconButton>
      </div>
      <Menu open={Boolean(open)} onClose={handleClose} anchorEl={open}>
        <div className="px-3">
          Add times in hours
          <div className="mt-2">
            <TextField
              size="small"
              label="Estimated Time"
              value={times.estimated_time}
              name="estimated_time"
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="hh:mm"
              // onChange={(event) => {
              //   const newValue = event.target.value;
              //   if (/^(\d*|\.)+$/.test(newValue)) {
              //     handleChangeTime(event);
              //   }
              // }}
              onChange={(e) => {
                let inputValue = e.target.value;

                // Remove non-digit characters
                inputValue = inputValue.replace(/[^0-9:]/g, "");
                // Limit the length to 5 characters
                inputValue = inputValue.slice(0, 5);

                if (inputValue.includes(":")) {
                  let temp = inputValue.split(":");
                  if (temp[0].length < 2) {
                    temp[0] = temp[0].padStart(2, "0");
                    inputValue = temp.join(":");
                  }
                }
                if (inputValue.length > 2 && !inputValue.includes(":")) {
                  // Add a colon after the first two digits, if necessary
                  inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(
                    2
                  )}`;
                }

                // Use a regular expression to match the desired time format (hh:mm)
                const regex = /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                if (regex.test(inputValue) || inputValue === "") {
                  let temp = inputValue.split(":");
                  if (Number(temp[1]) > 59) {
                    if (Number(temp[0]) < 99) {
                      temp[1] = String(Number(temp[1] - 60)).padStart(2, "0");
                      temp[0] = String(Number(temp[0]) + 1);
                    } else {
                      temp[1] = String(Number(temp[1] - 60));
                    }
                    inputValue = temp.join(":");
                  }
                  setTimes((prev) => {
                    return { ...prev, estimated_time: inputValue };
                  });
                }
              }}
            />
          </div>
          <div className="mt-2">
            <TextField
              size="small"
              label="Complete Time"
              value={times.actual_time}
              name="actual_time"
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="hh:mm"
              // onChange={(event) => {
              //   const newValue = event.target.value;
              //   if (/^(\d*|\.)+$/.test(newValue)) {
              //     handleChangeTime(event);
              //   }
              // }}
              onChange={(e) => {
                let inputValue = e.target.value;

                // Remove non-digit characters
                inputValue = inputValue.replace(/[^0-9:]/g, "");
                // Limit the length to 5 characters
                inputValue = inputValue.slice(0, 5);

                if (inputValue.includes(":")) {
                  let temp = inputValue.split(":");
                  if (temp[0].length < 2) {
                    temp[0] = temp[0].padStart(2, "0");
                    inputValue = temp.join(":");
                  }
                }
                if (inputValue.length > 2 && !inputValue.includes(":")) {
                  // Add a colon after the first two digits, if necessary
                  inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(
                    2
                  )}`;
                }

                // Use a regular expression to match the desired time format (hh:mm)
                const regex = /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                if (regex.test(inputValue) || inputValue === "") {
                  let temp = inputValue.split(":");
                  if (Number(temp[1]) > 59) {
                    if (Number(temp[0]) < 99) {
                      temp[1] = String(Number(temp[1] - 60)).padStart(2, "0");
                      temp[0] = String(Number(temp[0]) + 1);
                    } else {
                      temp[1] = String(Number(temp[1] - 60));
                    }
                    inputValue = temp.join(":");
                  }
                  setTimes((prev) => {
                    return { ...prev, actual_time: inputValue };
                  });
                }
              }}
            />
          </div>
          {(task?.role == "admin" || task?.role == "manager") && (
            <div className="mt-2">
              <TextField
                size="small"
                label="Billable Time"
                value={times.final_time}
                name="final_time"
                onKeyDown={(e) => e.stopPropagation()}
                placeholder="hh:mm"
                // onChange={(event) => {
                //   const newValue = event.target.value;
                //   if (/^(\d*|\.)+$/.test(newValue)) {
                //     handleChangeTime(event);
                //   }
                // }}
                onChange={(e) => {
                  let inputValue = e.target.value;

                  // Remove non-digit characters
                  inputValue = inputValue.replace(/[^0-9:]/g, "");
                  // Limit the length to 5 characters
                  inputValue = inputValue.slice(0, 5);

                  if (inputValue.includes(":")) {
                    let temp = inputValue.split(":");
                    if (temp[0].length < 2) {
                      temp[0] = temp[0].padStart(2, "0");
                      inputValue = temp.join(":");
                    }
                  }
                  if (inputValue.length > 2 && !inputValue.includes(":")) {
                    // Add a colon after the first two digits, if necessary
                    inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(
                      2
                    )}`;
                  }

                  // Use a regular expression to match the desired time format (hh:mm)
                  const regex = /^(?:.{0,2}|.{2}(?=:)?(?:.{0,3})?)$/;
                  if (regex.test(inputValue) || inputValue === "") {
                    let temp = inputValue.split(":");
                    if (Number(temp[1]) > 59) {
                      if (Number(temp[0]) < 99) {
                        temp[1] = String(Number(temp[1] - 60)).padStart(2, "0");
                        temp[0] = String(Number(temp[0]) + 1);
                      } else {
                        temp[1] = String(Number(temp[1] - 60));
                      }
                      inputValue = temp.join(":");
                    }
                    setTimes((prev) => {
                      return { ...prev, final_time: inputValue };
                    });
                  }
                }}
              />
            </div>
          )}
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            onClick={() => {
              updatetask();
            }}
          >
            Save
          </Button>
        </div>
      </Menu>
    </div>
  );
}

export default Times;
