import React from "react";
// material
import {
  Stack,
  Avatar,
  Box,
  Typography,
  ButtonBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { s3baseUrl } from "src/config";
import { capitalCase } from "change-case";
import { Label } from "src/components";
const InvitationCard = ({ item, onCancel, onResend }) => {
  const { email, user_data, role } = item;
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          {user_data && (
            <Box alignItems="center" justifyContent="center">
              {user_data.image ? (
                <Avatar src={s3baseUrl + user_data.image} />
              ) : (
                <Avatar>{user_data.first_name.substring(0, 2)}</Avatar>
              )}
            </Box>
          )}
          {!user_data && (
            <Box alignItems="center" justifyContent="center">
              <Avatar>{email.substring(0, 2)}</Avatar>
            </Box>
          )}
          <Stack spacing={1} sx={{ ml: 1 }}>
            <Typography variant="body1" noWrap>
              {user_data
                ? user_data.first_name + " " + user_data.last_name
                : email}
            </Typography>
            <Stack direction="row" spacing={1} style={{ marginTop: 0 }}>
              <Typography fontWeight="bold" variant="body1">
                Role
              </Typography>
              <Typography variant="body1" noWrap>
                {capitalCase(role)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* <Stack spacing={1}> */}
        {/* <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold" variant="body1">
              Role
            </Typography>
            <Typography variant="body1" noWrap>
              {capitalCase(item.role)}
            </Typography>
          </Stack>
          {workspace.description && (
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="bold" variant="body1">
                Description
              </Typography>
              {workspace.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: workspace.description }}
                />
              ) : (
                <></>
              )}
            </Stack>
          )} */}
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Tooltip title="cancel invitation">
            <ButtonBase onClick={() => onCancel(item)}>
              <Label
                sx={{ "&:hover": { cursor: "pointer" } }}
                color="error"
                variant="ghost"
              >
                Cancel
              </Label>
            </ButtonBase>
          </Tooltip>
          <Tooltip title="resend invitation">
            <ButtonBase onClick={() => onResend(item)}>
              <Label
                sx={{ "&:hover": { cursor: "pointer" } }}
                color="success"
                variant="ghost"
              >
                Re-send
              </Label>
            </ButtonBase>
          </Tooltip>
        </Stack>
        {/* </Stack> */}
      </Stack>
    </Paper>
  );
};

export default InvitationCard;
