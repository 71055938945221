// here we add all base urls and keys
//local uri
// export const baseUri = "https://done.prod.eanimalhub.com/";
// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const stripePublicKey = String(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
