import {
  Container,
  Box,
  Stack,
  IconButton,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  CircularLoader,
  Iconify,
  MuiAutoComplete,
  MuiDialog,
  MuiDrawer,
  MuiMultiAutocomplete,
  NavBackHeader,
  Page,
  RichEditor,
  SearchBar,
} from "src/components";
import { ALLOWED_IMAGE_TYPES } from "src/constants";
import {
  del_from_local_storage,
  get_from_localStorage,
  get_zone_date,
  set_to_localStorage,
  upload_image,
} from "src/utils";
import * as Yup from "yup";
import { useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  _cancel_workspace_invitation,
  _create_workspace_project,
  _project_detail,
  _resend_workspace_invitation,
  _update_project_team,
  _update_workspace_project,
  _workspace_team,
} from "src/DAL";
import { useAppContext } from "src/hooks";
import moment from "moment";
import { s3baseUrl } from "src/config";
import ProjectUserCard from "../workspace-detail/components/ProjectUserCard";
import ImageSelector from "src/components/ImageSelector";
import InviteMembersDialog from "src/components/InviteMembersDialog";
import InvitationCard from "../workspace-detail/components/InvitationCard";
// ---------------------------------------------------------
const common_button_size = "5rem";
const EditWorkspaceProject = () => {
  const {
    dispatch_get_user_profile,
    socketEmit,
    setSlugProjectsData,
    dispatch_get_current_selected_item,
  } = useAppContext();
  const user = dispatch_get_user_profile();
  const navigate = useNavigate();
  const autoFocusRef = useRef(null);
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const slug = params.id;
  const getModified = (data) => {
    let modified = data.map((item) => {
      return {
        ...item,
        label: item.first_name + " " + item.last_name,
        value: item.user_id,
      };
    });
    return modified;
  };
  const [workspaceData, setWorkspaceData] = useState(
    get_from_localStorage("workspaceData") ?? state?.workspace_data
  );
  const [formInputs, setFormInputs] = useState({
    image: state.project.image,
    title: state.project.title,
    startDate: state.project.start_date,
    endDate: state.project.end_date,
    team: get_from_localStorage("team")
      ? get_from_localStorage("team")
      : state.project.team,
    description: state.project.description,
    status: state.project.status,
  });
  const [file, setFile] = useState("");
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [projectTeamOpen, setProjectTeamOpen] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const getProjectTeam = () => {
    const team = state.workspace_data.team.map((obj1) => {
      const obj2 = state.project.team.find(
        (obj) => obj.user_id === obj1.user_id
      );
      return {
        ...obj1,
        is_added: obj2 ? true : false,
        label: obj1.first_name + " " + obj1.last_name,
        value: obj1.user_id,
      };
    });
    return team;
  };
  const [workspaceTeam, setWorkspaceTeam] = useState(getProjectTeam());
  const getTeamForAutoComplete = () => {
    let team = state?.project?.team.map((user) => {
      return {
        ...user,
        label: user.first_name + " " + user.last_name,
        value: user.user_id,
      };
    });
    team.push({
      ...state?.workspace_data?.admin,
      label:
        state?.workspace_data?.admin.first_name +
        " " +
        state?.workspace_data?.admin.last_name,
      value: state?.workspace_data?.admin.user_id,
    });
    return team;
  };

  const [projectTeam, setProjectTeam] = useState(getTeamForAutoComplete());
  const [currentTab, setCurrentTab] = useState("added");
  const [projectTeamSearch, setProjectTeamSearch] = useState("");
  //////=====Invitation new flow===========///
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [currentTargetItem, setCurrentTargetItem] = useState();
  const [cancelInvOpen, setCancelInvOpen] = useState(false);
  const [resendInvOpen, setResendInvOpen] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const openCancelInvDialog = () => {
    setCancelInvOpen(true);
  };
  const closeCancelInvDialog = () => {
    setCancelInvOpen(false);
    setCurrentTargetItem();
  };
  const openResendInvDialog = () => {
    setResendInvOpen(true);
  };
  const closeResendInvDialog = () => {
    setResendInvOpen(false);
    setCurrentTargetItem();
  };
  const openInvitationDialog = () => {
    setInvitationDialogOpen(true);
  };
  const closeInvitationDialog = () => {
    setInvitationDialogOpen(false);
  };
  const onCancelWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openCancelInvDialog();
  };
  const onResendWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openResendInvDialog();
  };
  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);
      const result = await _resend_workspace_invitation(currentTargetItem._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        closeResendInvDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while resending invitation");
    } finally {
      setResendLoading(false);
    }
  };
  const handleCancelWorkspaceInvite = async () => {
    const id = currentTargetItem._id;
    setProcessing(true);
    const result = await _cancel_workspace_invitation(null, id);
    if (result.code === 200) {
      setWorkspaceData((prev) => {
        return {
          ...prev,
          invitations: prev.invitations.filter(
            (inv) => inv._id !== currentTargetItem._id
          ),
        };
      });
      enqueueSnackbar("Invitation cancelled successfully", {
        variant: "success",
      });
      setProcessing(false);
      closeCancelInvDialog();

      // closeInvitationDrawer();
      /* filter current row if no workspaces & invitations left  for better ux, */
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeCancelInvDialog();
      // closeInvitationDrawer();
    }
  };
  const getFilteredProjectTeam = () => {
    let tempTeam = [...workspaceData.team].filter(
      (user) =>
        user.role !== "admin" &&
        (user.first_name + " " + user.last_name)
          .toLowerCase()
          .includes(projectTeamSearch.toLowerCase())
    );
    tempTeam = tempTeam.map((user) => {
      return {
        ...user,
        is_added: projectTeam.some(
          (projectUser) => projectUser.user_id === user.user_id
        ),
      };
    });
    return {
      added: tempTeam.filter((user) => user.is_added),
      toAdd: tempTeam.filter((user) => !user.is_added),
    };
  };
  const getFilteredInvitations = (invs, search) => {
    if (!search) {
      return invs;
    } else {
      return invs.filter((inv) =>
        inv?.user_data
          ? (inv.user_data.first_name + " " + inv.user_data.last_name)
              .toLowerCase()
              .includes(search.toLowerCase())
          : //    ||
            // inv.email.toLowerCase().includes(search.toLowerCase())
            inv.email.toLowerCase().includes(search.toLowerCase())
      );
    }
  };
  ////////////////////////////////////////////
  const WorkspaceSchema = Yup.object().shape({
    image: Yup.string(),
    title: Yup.string()
      .min(1, "Workspace Title too short!")
      .required("Workspace is required"),
    description: Yup.string(),
    // startDate: Yup.string(),
    //   .min(1, "Invalid start date!")
    //   .notOneOf(["Invalid date", ""], "Invalid start date!")
    //   .required("Start date is required"),
    // endDate: Yup.string()
    //   .min(1, "Invalid end date!")
    //   .notOneOf(["Invalid date", ""], "Invalid end date!")
    //   .required("End date is required"),
    // team: Yup.array()
    //   .min(1, "Choose at least one team member!")
    //   .required("Choose at least one team member!"),
  });
  const handleAutocompleteChange = (value) => {
    console.log(value, "val");
    setAutoCompleteValue(value);
    setFormInputs({ ...formInputs, ["team"]: value });
  };
  // const getWorkspaceTeam = async () => {
  //   console.log(state, "state");
  //   const result = await _workspace_team(null, state.workspace_data._id);
  //   const getModified = (data) => {
  //     let modified = data.map((item) => {
  //       return {
  //         ...item,
  //         label: item.first_name + " " + item.last_name,
  //         value: item,
  //       };
  //     });
  //     console.log(modified, "modified team");
  //     return modified;
  //   };
  //   if (result.code === 200) {
  //     console.log(result, "team-res");
  //     setTeam(getModified(result.team_list.customer_profile));
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };
  const handleChange = async (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleFileChange = async (e) => {
    if (processing) {
      return;
    }
    let file = e.target.files[0];
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setFile(file);
    setFormInputs({ ...formInputs, ["image"]: URL.createObjectURL(file) });
    //
  };
  const getImgSrc = (path) => {
    if (!path) {
      return path;
    }
    if (path.includes("blob:")) {
      return path;
    }
    return s3baseUrl + path;
  };
  const getFormattedDate = (date, format) => {
    if (!date) {
      return "";
    }
    return get_zone_date(date, format);
  };

  const getProjectDetail = async () => {
    const result = await _project_detail(null, slug);
    const getModified = (data) => {
      let modified = data.map((item) => {
        return {
          ...item,
          label: item.first_name + " " + item.last_name,
          value: item,
        };
      });
      console.log(modified, "modified detail team");
      return modified;
    };
    if (result.code === 200) {
      console.log(result, "detail-res");
      const { project_detail } = result;
      setProjectTeam(project_detail.team);
      setAutoCompleteValue(getModified(project_detail.team));
      setFormInputs({
        ["team"]: project_detail.team.filter((user) => user.role !== "admin"),
        ["image"]: project_detail.image ? project_detail.image : "",
        ["description"]: project_detail.description,
        ["title"]: project_detail.title,
        ["startDate"]: project_detail.start_date,
        ["endDate"]: project_detail.end_date,
        ["status"]: project_detail.status,
      });
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const closeProjectTeam = () => {
    setProjectTeamOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let invalid = false;
    await WorkspaceSchema.validate(formInputs).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    const start_date = formInputs.startDate;
    const end_date = formInputs.endDate;
    let is_before = moment(start_date).isBefore(end_date);
    let is_same = moment(start_date).isSame(end_date);
    if (start_date && end_date) {
      if (is_same === false) {
        if (is_before === false) {
          enqueueSnackbar(
            "Start date should be greater or equal to end date!",
            {
              variant: "error",
            }
          );
          return;
        }
      }
    }

    let project_team = [];
    formInputs.team.forEach((item) => {
      project_team.push(item);
    });

    const data = {
      title: formInputs.title,
      workspace_id: state.workspace_data._id,
      team: project_team,
      description: formInputs.description,
      status: formInputs.status,
      start_date: getFormattedDate(formInputs.startDate, "YYYY-MM-DD"),
      end_date: getFormattedDate(formInputs.endDate, "YYYY-MM-DD"),
    };
    setProcessing(true);
    if (formInputs.image !== "") {
      data.image = formInputs.image;
    }
    if (file) {
      const result = await upload_image(file);
      if (result.code === 200) {
        data.image = result.path;
      }
    }

    console.log(data, "update-proj-req");

    setProcessing(true);
    const result = await _update_workspace_project(data, slug);
    if (result.code === 200) {
      console.log(result, "update-proj-res");
      const updatedProject = result.Project;
      setProcessing(false);
      // let temp = get_from_localStorage("projects");
      // console.log(temp.projects, "acaskcljasck");
      // let arr = temp.projects;
      // arr.map((project, i) => {
      //   if (project._id == result.Project._id) {
      //     project = {
      //       ...project,
      //       image: result.Project.image ? result.Project.image : "",
      //       description: result.Project.description,
      //       start_date: result.Project.start_date,
      //       end_date: result.Project.end_date,
      //       team: result.Project.team.filter(
      //         (member) => member.role !== "admin"
      //       ),
      //       title: result.Project.title,
      //     };
      //     arr[i] = project;
      //     return;
      //   }
      // });
      // temp = { ...temp, projects: arr };
      // setSlugProjectsData(temp);
      // set_to_localStorage("projects", temp);
      let receiver = [];
      result.Project.team.map((member) => {
        if (member.user_id !== dispatch_get_user_profile().user_id) {
          receiver.push(member.user_id);
        }
      });
      enqueueSnackbar("Project Updated Successfully", { variant: "success" });
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated a Project`,
        link: `${window.location.origin}/workspaces/projects/${result.Project._id}`,
      });
      navigate(-1);
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useLayoutEffect(() => {
    // getWorkspaceTeam();
    getProjectDetail();
  }, []);
  // ---------------------------------------------------------
  // if (loading) {
  //   return (
  //     <Page title="Edit Project">
  //       <Container maxWidth="xl">
  //         <CircularLoader />
  //       </Container>
  //     </Page>
  //   );
  // }
  ////////////////////////////////TEAM UPDATE///////////////////////////////////
  const [teamDrawerOpen, setTeamDrawerOpen] = useState(false);
  const [removeUserDialogopen, setRemoveUserDialogopen] = useState(false);
  const [targetUserToRemove, setTargetUserToRemove] = useState();
  const [removeProjectUserType, setRemoveProjectUserType] = useState("remove");
  const [removeUserLoading, setRemoveUserLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const handleAddUserInProject = async (user) => {
    setAddLoading(true);
    try {
      const temp = projectTeam;
      temp.push(user);
      const req_data = { team: temp, member_id: null };
      const result = await _update_project_team(req_data, state?.project?._id);
      if (result.code == 200) {
        setWorkspaceTeam((prev) => {
          return prev.map((member) => {
            if (member.user_id == user.user_id) {
              return {
                ...member,
                is_added: true,
              };
            } else {
              return {
                ...member,
              };
            }
          });
        });
        enqueueSnackbar("Project team updated successfully", {
          variant: "success",
        });
        setProjectTeam(temp);
        setFormInputs((prev) => {
          return {
            ...prev,
            team: [...prev.team, user],
          };
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while adding the user in project team");
    } finally {
      setAddLoading(false);
    }
  };
  const onremoveUserClick = (user) => {
    setTargetUserToRemove(user);
    setRemoveUserDialogopen(true);
  };
  const removeUserFromProject = async () => {
    if (removeProjectUserType == "assign" && !autoCompleteValue) {
      enqueueSnackbar(
        "Please select the member to whome you want to assign the tasks",
        { variant: "error" }
      );
      return;
    }
    try {
      setRemoveUserLoading(true);
      const updatedTeam = projectTeam.filter(
        (user) =>
          user.role !== "admin" && user.user_id !== targetUserToRemove.user_id
      );
      const req_data = {
        team: updatedTeam,
        member_id: autoCompleteValue?.user_id,
      };
      const result = await _update_project_team(req_data, state?.project?._id);
      if (result.code == 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        setWorkspaceTeam((prev) => {
          return prev.map((member) => {
            if (member.user_id == targetUserToRemove.user_id) {
              return {
                ...member,
                is_added: false,
              };
            } else {
              return {
                ...member,
              };
            }
          });
        });
        setAutoCompleteValue();
        setRemoveUserDialogopen(false);
        setRemoveProjectUserType("remove");
        setFormInputs((prev) => {
          return {
            ...prev,
            team: prev.team.filter(
              (user) => user.user_id !== targetUserToRemove.user_id
            ),
          };
        });
        setProjectTeam((prev) => {
          return prev.filter(
            (member) => member.user_id !== targetUserToRemove.user_id
          );
        });
        setTargetUserToRemove();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while remove a user from project team");
    } finally {
      setRemoveUserLoading(false);
    }
  };
  ////=======================================================================
  useEffect(() => {
    set_to_localStorage("workspaceData", workspaceData);
    return () => {
      del_from_local_storage("workspaceData");
    };
  }, [workspaceData]);

  useEffect(() => {
    autoFocusRef.current.focus();
  }, [])
  
  //////////////////////////////////////////////////////////////////////////////
  return (
    <Page title="Edit Project">
      <Container maxWidth="xl" sx={{ pb: 5 }}>
        <div className="mb-4">
          <NavBackHeader heading="Edit Project" />
        </div>
        <Container maxWidth="md">
          <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack spacing={3}>
                <ImageSelector
                  file={file}
                  setFile={setFile}
                  currentImage={
                    formInputs.image ? s3baseUrl + formInputs.image : ""
                  }
                  removeCurrentImage={() =>
                    setFormInputs((prev) => {
                      return {
                        ...prev,
                        image: "",
                      };
                    })
                  }
                />
              </Stack>
              <TextField
              inputRef={autoFocusRef}
                label="Project Title"
                required
                name="title"
                variant="outlined"
                value={formInputs.title}
                onChange={handleChange}
              />
              <div className="d-flex align-items-center">
                <FormControlLabel
                  required
                  control={
                    <Switch
                      checked={formInputs.status}
                      onChange={(e) =>
                        setFormInputs((prev) => {
                          return { ...prev, status: e.target.checked };
                        })
                      }
                    />
                  }
                  label={formInputs.status ? "Active" : "Inactive"}
                />
              </div>
              <RichEditor
                editorRef={editorRef}
                value={formInputs.description}
                setValue={(val) =>
                  setFormInputs({
                    ...formInputs,
                    description: val,
                  })
                }
              />
              <Stack direction="row" spacing={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="DD-MMM-YYYY"
                    value={formInputs.startDate}
                    onChange={(newValue) =>
                      setFormInputs({ ...formInputs, ["startDate"]: newValue })
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="startDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="DD-MMM-YYYY"
                    value={formInputs.endDate}
                    onChange={(newValue) => {
                      if (!formInputs.startDate) {
                        setFormInputs({
                          ...formInputs,
                          ["endDate"]: newValue,
                          ["startDate"]: new Date(),
                        });
                      } else {
                        setFormInputs({ ...formInputs, ["endDate"]: newValue });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="endDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <TextField
                sx={{ cursor: "pointer" }}
                onFocus={(e) => e.target.blur()}
                id="team-text-field"
                variant="outlined"
                label="Team Members"
                value={formInputs.team.map((member, i) => {
                  return " " + member.first_name + " " + member.last_name;
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Edit Team Members">
                        <IconButton
                          edge="end"
                          onClick={() => setTeamDrawerOpen(true)}
                        >
                          <Iconify icon="lucide:edit" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="centered-row">
                <LoadingButton
                  type="submit"
                  sx={{ width: common_button_size }}
                  loading={processing}
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </div>
            </Stack>
          </Box>
        </Container>
      </Container>
      {/* <MuiDrawer
        isOpen={teamDrawerOpen}
        onClose={() => {
          setTeamDrawerOpen(false);
        }}
        title="Project Team"
      >
        <Stack spacing={2}>
          {workspaceTeam.map((user, index) => {
            return (
              <ProjectUserCard
                key={index}
                item={user}
                userRole={state?.project?.role}
                onRemoveUser={(user) => {
                  onremoveUserClick(user);
                }}
                onAddUser={(user) => {
                  handleAddUserInProject(user);
                }}
                addLoading={addLoading}
              />
            );
          })}
        </Stack>
      </MuiDrawer> */}
      <MuiDialog
        open={removeUserDialogopen}
        loading={removeUserLoading}
        onAgree={removeUserFromProject}
        onToggle={() => setRemoveUserDialogopen(false)}
        title="Remove User"
        message="Are you sure you want to remove this user from project?"
        ExtraComponent={
          <>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeProjectUserType}
              onChange={(e) => setRemoveProjectUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by this user and remove him as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks, this user created, to some other team member"
              />
            </RadioGroup>
            {removeProjectUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={projectTeam.filter(
                  (user) => user.user_id !== targetUserToRemove.user_id
                )}
              />
            )}
          </>
        }
      />
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 550 } }}
        onClose={() => setTeamDrawerOpen(false)}
        isOpen={teamDrawerOpen}
        title="Project Team"
        titleButton={
          // workspaceData?.role !== "team" && (
          state.project.role !== "team" &&
          state.project.role !== "client_team" && (
            <Button
              variant="contained"
              size="small"
              onClick={openInvitationDialog}
            >
              invite members
            </Button>
          )
          // )
        }
      >
        <Tabs
          sx={{ pt: 0 }}
          value={currentTab}
          onChange={(e, val) => {
            setProjectTeamSearch("");
            setCurrentTab(val);
          }}
        >
          <Tab
            sx={{ pt: 0, width: "33%" }}
            value={"added"}
            label="Project Team"
          />
          <Tab
            value="toAdd"
            label="Workspace Team"
            sx={{ width: "33%", pt: 0 }}
          />
          <Tab
            value="projectInvitations"
            label="Invited Team"
            sx={{ width: "33%", pt: 0 }}
          />
        </Tabs>
        {currentTab == "added" && (
          <>
            <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
              <SearchBar
                notransition="true"
                sx={{ width: "100%", mb: 2 }}
                value={projectTeamSearch}
                onChange={(e) => setProjectTeamSearch(e.target.value)}
              />
            </Paper>
            <Stack spacing={2}>
              {getFilteredProjectTeam().added.map((item, index) => (
                <ProjectUserCard
                  onRemoveUser={(user) => {
                    onremoveUserClick(user);
                  }}
                  onAddUser={(user) => {
                    handleAddUserInProject(user);
                  }}
                  userRole={state?.project?.role}
                  key={index}
                  item={item}
                />
              ))}
            </Stack>
            {getFilteredProjectTeam().added.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No Project team members found
              </Typography>
            )}
          </>
        )}
        {currentTab == "toAdd" && (
          <>
            <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
              <SearchBar
                notransition="true"
                sx={{ width: "100%", mb: 2 }}
                value={projectTeamSearch}
                onChange={(e) => setProjectTeamSearch(e.target.value)}
              />
            </Paper>
            <Stack spacing={2}>
              {getFilteredProjectTeam().toAdd.map((item, index) => (
                <ProjectUserCard
                  onRemoveUser={(user) => {
                    onremoveUserClick(user);
                  }}
                  onAddUser={(user) => {
                    handleAddUserInProject(user);
                  }}
                  userRole={state?.project?.role}
                  key={index}
                  item={item}
                />
              ))}
            </Stack>
            {getFilteredProjectTeam().toAdd.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No team members found
              </Typography>
            )}
          </>
        )}
        {currentTab == "projectInvitations" && (
          <>
            <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
              <SearchBar
                notransition="true"
                sx={{ width: "100%", mb: 2 }}
                value={projectTeamSearch}
                onChange={(e) => setProjectTeamSearch(e.target.value)}
              />
            </Paper>
            <Stack spacing={2}>
              {getFilteredInvitations(
                workspaceData.invitations.filter((inv) =>
                  inv.project.includes(state?.project._id)
                ),
                projectTeamSearch
              ).map((item, index) => (
                <InvitationCard
                  onCancel={onCancelWorkspaceInvite}
                  onResend={onResendWorkspaceInvite}
                  key={item._id}
                  item={item}
                />
              ))}
            </Stack>
            {getFilteredInvitations(
              workspaceData.invitations.filter((inv) =>
                inv.project.includes(state?.project._id)
              ),
              projectTeamSearch
            ).length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 20 }}
                textAlign="center"
              >
                No invitations found!
              </Typography>
            )}
          </>
        )}
      </MuiDrawer>
      {invitationDialogOpen && (
        <InviteMembersDialog
          open={invitationDialogOpen}
          onClose={closeInvitationDialog}
          workspace={workspaceData}
          project={state?.project}
          onSuccess={(inv) =>
            setWorkspaceData((prev) => {
              return { ...prev, invitations: [...prev.invitations, inv] };
            })
          }
          setWorkspace={setWorkspaceData}
        />
      )}
      <MuiDialog
        open={cancelInvOpen}
        message="Are you sure you want to cancel your invite?"
        title="Cancel invite"
        onToggle={(value) => setCancelInvOpen(value)}
        onAgree={handleCancelWorkspaceInvite}
        loading={processing}
      />
      <MuiDialog
        open={resendInvOpen}
        message="Are you sure you want to re-send this invitation?"
        title="Resend invite"
        onToggle={(value) => setResendInvOpen(value)}
        onAgree={handleResendInvitation}
        loading={resendLoading}
      />
    </Page>
  );
};

export default EditWorkspaceProject;
