import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  //   Tooltip,
  //   IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material";
// component
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  "& legend": {
    display: "none",
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  moduleName: PropTypes.string,
};

export default function ListToolbar({ filterName, onFilterName, moduleName }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      margin={2}
    >
      {/* <Typography variant="h4">{moduleName}</Typography> */}

      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder={`Search ${moduleName}...`}
        sx={{ height: 50 }}
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: "text.disabled", width: 20, height: 20, mb: "5px" }}
            />
          </InputAdornment>
        }
      />
    </Stack>
  );
}
