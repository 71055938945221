import { Button, Card, CircularProgress, Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Page } from "src/components";
import PaymentPlanCard from "./components/PaymentPlanCard";
import { useLocation, useNavigate } from "react-router-dom";
import { _subscribtion_list } from "src/DAL/payment";
import { _get_user_profile } from "src/DAL";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css"; // Your custom CSS

import $ from "jquery";
// import "slick-carousel/slick/slick.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useSnackbar } from "notistack";
function PaymentPlans() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [user, setUser] = useState();

  const [userCurrentplan, setUserCurrentplan] = useState();
  const [paymentPlansList, setPaymentPlansList] = useState([]);
  const handleButtonClick = (plan) => {
    // Implement your button click logic here
    navigate("/payment", {
      state: {
        plan,
        token: state.token,
        paymentPlansList,
        userCurrentplan,
        user,
      },
    });
  };
  const targetDivRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const getSubscriptionList = async () => {
    try {
      const result = await _subscribtion_list(state.token);
      if (result.code == 200) {
        setPaymentPlansList(result.subscription_plan);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching payment plans");
    }
  };
  const getButtonText = (plan) => {
    if (
      (user.subscription_plan_name == plan.subscription_plan_name &&
        user.subscription_status == "active") ||
      (plan.subscription_plan_name == "free" && user.subscription_status == "")
    ) {
      return "Current Plan";
    }
    if (
      user.subscription_plan_name == plan.subscription_plan_name &&
      user.subscription_status == "deleted"
    ) {
      return "Buy Again";
    }
    if (
      user.subscription_plan_name !== plan.subscription_plan_name &&
      user.subscription_plan_name == "advance"
    ) {
      return "Cannot downgrade";
    }
    if (
      user.subscription_plan_name == "basic" &&
      plan.subscription_plan_name == "free"
    ) {
      return "Cannot downgrade";
    }
    if (
      user.subscription_plan_name == "basic" &&
      plan.subscription_plan_name == "advance"
    ) {
      return "Select plan";
    }
    if (
      user.subscription_plan_name == "free" &&
      (plan.subscription_plan_name == "advance" ||
        plan.subscription_plan_name == "basic")
    ) {
      return "Select plan";
    }
  };
  const getUserProfile = async () => {
    try {
      const result = await _get_user_profile();
      if (result.code == 200) {
        setUser(result.customer);
        setUserCurrentplan(result.current_plans);
        const idRank = {
          free: 1,
          basic: 2,
          advance: 3,
        };
        setPaymentPlansList(
          result.all_plans.sort(
            (a, b) =>
              idRank[a.subscription_plan_name] -
              idRank[b.subscription_plan_name]
          )
        );
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching user data");
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   if (paymentPlansList.length > 0) {
  //     $(".custom-cards").slick({
  //       dots: paymentPlansList.length > 2,
  //       infinite: false,
  //       autoplay: false,
  //       autoplaySpeed: 2000,
  //       slidesToShow: 2,
  //       // paymentPlansList.length >= 3 ? 3 : paymentPlansList.length,
  //       slidesToScroll: 1,
  //       // centerMode: paymentPlansList.length >= 3,
  //       // centerPadding: "0",
  //       arrows: paymentPlansList.length > 2,

  //       responsive: [
  //         {
  //           breakpoint: 1023,
  //           settings: {
  //             arrows: paymentPlansList.length > 2,
  //             dots: paymentPlansList.length > 2,
  //           },
  //         },
  //         {
  //           breakpoint: 991,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //             centerMode: false,
  //             arrows: paymentPlansList.length > 2,
  //             dots: paymentPlansList.length > 2,
  //           },
  //         },
  //         {
  //           breakpoint: 799,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //             centerMode: false,
  //             arrows: paymentPlansList.length > 2,
  //             dots: paymentPlansList.length > 2,
  //           },
  //         },
  //         {
  //           breakpoint: 767,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //             centerMode: false,
  //             arrows: paymentPlansList.length > 1,
  //             dots: paymentPlansList.length > 1,
  //           },
  //         },
  //         {
  //           breakpoint: 480,
  //           settings: {
  //             slidesToShow: 1,
  //             slidesToScroll: 1,
  //             centerMode: false,
  //             arrows: paymentPlansList.length > 1,
  //             dots: paymentPlansList.length > 1,
  //           },
  //         },
  //       ],
  //     });
  //   }
  //   if (targetDivRef.current) {
  //     targetDivRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [paymentPlansList, loading]);
  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      getUserProfile();
    }
  }, []);
  return (
    <Page title="Payment Plans">
      <Container maxWidth="xl">
        {loading && (
          <div
            style={{ minHeight: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress />
          </div>
        )}
        {!loading && user && (
          <>
            <div className="container py-3">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center mb-5 mt-3" id="mce_12">
                    <h1 className="h1-font-size">
                      <span>Ready to get started?</span>
                    </h1>
                    <p>Choose a plan tailored to your needs.</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="row justify-content-center">
                  {paymentPlansList.map((plan) => (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 d-flex align-items-end position-relative">
                      <Card
                        className={`p-3`}
                        sx={{
                          // activePlan._id == plan._id
                          //   ? {
                          height: "550px",
                          border: "0.15rem solid",
                          width: "100%",
                          // backgroundColor:theme.palette.primary.main,
                          borderColor: (theme) => theme.palette.primary.main,
                          //   }
                          // : {
                          height: "520px",
                          width: "100%",
                          // }
                        }}
                      >
                        {getButtonText(plan) == "Current Plan" && (
                          <div
                            style={{
                              height: "30px",
                              backgroundColor: "#00AB55",
                              margin: "-16px -17px 16px",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            <p className="pt-1">Active Plan</p>
                          </div>
                        )}
                        <div className="">
                          <div className="h3-style">
                            <div className="wb-editor-block  mt-2">
                              <h4>{plan.name}</h4>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="d-flex "
                              style={{
                                color: (theme) => theme.palette.primary.main,
                                alignItems: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontStyle: "32px",
                                  // color: "#beccdb",
                                  fontWeight: "initial",
                                }}
                              >
                                $
                              </h2>{" "}
                              <h2
                                style={{
                                  fontStyle: "32px",
                                  fontWeight: "initial",
                                }}
                              >
                                {plan.price}
                              </h2>{" "}
                              <span className="pt-2 ps-1"> /month</span>
                            </div>
                          </div>
                          <div>
                            <Button
                              onClick={() => handleButtonClick(plan)}
                              fullWidth
                              variant="contained"
                              className="my-2"
                              disabled={
                                getButtonText(plan) == "Current Plan" ||
                                getButtonText(plan) == "Cannot downgrade"
                              }
                            >
                              {getButtonText(plan)}
                              <i className="fa-solid fa-arrow-right ms-2"></i>
                            </Button>
                          </div>
                          <div className="mt-3">
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {plan.name} Includes:
                            </p>
                          </div>
                          <ul
                            className="list-unstyled wp-pt-10  wp-mb-80"
                            style={{ color: "grey", fontSize: "13px" }}
                          >
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Dashboard Analytic</span>
                              <i
                                id="fa-solid"
                                className={
                                  plan.dashboard_analytic.toLowerCase() == "yes"
                                    ? "fa-solid fa-check me-2"
                                    : "fa-solid fa-xmark me-2"
                                }
                              />
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Google Calendar Attachment</span>
                              <i
                                id="fa-solid"
                                className={
                                  plan.google_calendar_attachment.toLowerCase() ==
                                  "yes"
                                    ? "fa-solid fa-check me-2"
                                    : "fa-solid fa-xmark me-2"
                                }
                              />
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Workspaces Allowed</span>
                              <span>{plan.workspace_allow}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Projects Allowed</span>
                              <span>{plan.project_allow}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Storage Allowed</span>
                              <span>{plan.storage_allow}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Team / Managers Allowed</span>
                              <span>{plan.team_member_allow}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Real-Time Chat</span>
                              <i
                                id="fa-solid"
                                className={
                                  plan.real_time_chat.toLowerCase() == "yes"
                                    ? "fa-solid fa-check me-2"
                                    : "fa-solid fa-xmark me-2"
                                }
                              />
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Email Support</span>
                              {plan.email_support.toLowerCase() == "no" ? (
                                <i
                                  id="fa-solid"
                                  className="fa-solid fa-xmark me-2"
                                />
                              ) : (
                                <span>{plan.email_support}</span>
                              )}
                            </li>
                            <li className="d-flex justify-content-between align-items-center pt-2">
                              <span>Clients Allowed</span>
                              <span>{plan.client_allow}</span>
                            </li>
                          </ul>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </Page>
  );
}

export default PaymentPlans;
