import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import PlanDetail from "./PlanDetail";
import { useSnackbar } from "notistack";
import { _get_stripe_key } from "src/DAL/plans";
import { loadStripe } from "@stripe/stripe-js";

function MakeElementWrapper() {
  const { enqueueSnackbar } = useSnackbar();
  const [stripeKey, setStripeKey] = useState(null);
  console.log(stripeKey, "stripeKey");

  const stripePromise = useMemo(() => {
    if (stripeKey) {
      return loadStripe(stripeKey);
    }
    return null;
  }, [stripeKey]);

  const getStripeKeys = async () => {
    try {
      const result = await _get_stripe_key();
      if (result.code === 200) {
        setStripeKey(result.data);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching Stripe key:", error);
      enqueueSnackbar("Failed to load Stripe key", { variant: "error" });
    }
  };

  useEffect(() => {
    getStripeKeys();
  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        <PlanDetail />
      </Elements>
    </>
  );
}

export default MakeElementWrapper;
