import { Card, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import DaashboardTaskCard from "src/pages/dashboard/components/DaashboardTaskCard";
import NoTasksFound from "src/pages/dashboard/components/NoTasksFound";

export default function WeekCalendar({ events, handleClick, currentDate }) {
  console.log(events);
  const getByWeek = (events) => {
    // Define the start and end dates of the range
    const startDate = moment(currentDate.start_date, "YYYY-MM-DD");
    const endDate = moment(currentDate.end_date, "YYYY-MM-DD");

    // Filter events within the date range
    const filteredEvents = events.filter((event) => {
      return (
        moment(event.start).isSameOrAfter(startDate) &&
        moment(event.start).isSameOrBefore(endDate)
      );
    });

    let newArray = [];
    filteredEvents.forEach((event, i) => {
      let index = newArray.findIndex(
        (new_event) =>
          moment(new_event.date).format("ddd") ==
          moment(event.start).format("ddd")
      );
      if (index > -1) {
        newArray[index].events.push(event);
      } else {
        newArray.push({
          date: event.start,
          events: [event],
        });
      }
    });
    return newArray.sort(function (a, b) {
      var dateA = new Date(a.date);
      var dateB = new Date(b.date);
      return dateA - dateB;
    });
  };

  return (
    <>
      <div className="row">
        {getByWeek(events).length > 0 ? (
          <Stack spacing={2}>
            {getByWeek(events).map((weeks, index) => {
              console.log(weeks, "Aasajscaskcj");
              return (
                <div className="col-12 calendar-week-card">
                  <Card sx={{ px: 2, py: 1 }}>
                    <div className="row">
                      <div className="col-12 col-sm-2 d-flex flex-column justify-content-center align-items-center">
                        <div>
                          <h4>{moment(weeks.date).format("ddd")}</h4>
                          <span>{moment(weeks.date).format("MM/DD")}</span>
                        </div>
                      </div>
                      <div className="col-12 col-sm-10 p-1">
                        <Stack spacing={1}>
                          {weeks.events.map((task) => {
                            return (
                              <DaashboardTaskCard
                                task={task.more}
                                onTaskClick={handleClick}
                              />
                            );
                          })}
                        </Stack>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </Stack>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoTasksFound />
          </div>
        )}
      </div>
    </>
  );
}
