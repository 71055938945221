import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  _add_support_ticket,
  _close_support_ticket,
  _delete_support_ticket,
  _get_support_tickets_list,
} from "src/DAL";
import {
  CircularLoader,
  Iconify,
  Label,
  MuiDialog,
  MuiDrawer,
  Page,
  RichEditor,
  Scrollbar,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import { useAppContext } from "src/hooks";
import { upload_image } from "src/utils";
const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "subject", label: "Subject", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "comment_badge_count", label: "Unread Messages", alignRight: false },
  { id: "response_status", label: "Response Status", alignRight: false },
  { id: "ticket_status", label: "Ticket Status", alignRight: false },
  { id: "last_action_date", label: "Last Activity", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "" },
];
function SupportTickets() {
  const navigate = useNavigate();
  const { setBadge } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [tickets, setTickets] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const [counts, setCounts] = useState({ open: 0, all: 0, closed: 0 });
  const [menuOpen, setMenuOpen] = useState(null);
  const [currentTicket, setCurrentTicket] = useState();
  const [deleteTicketLoading, setDeleteTicketLoading] = useState(false);
  const [createTicketDrawerOpen, setCreateTicketDrawerOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const [closeTicketLoading, setCloseTicketLoading] = useState(false);
  const [newTicketData, setNewTicketData] = useState({
    subject: "",
    description: "",
    category: "",
    image: null,
  });
  const [addTicketLoading, setAddTicketLoading] = useState(false);
  const handleOpenMenu = (e) => {
    setMenuOpen(e.target);
  };
  const handleCloseMenu = () => {
    setMenuOpen(null);
    setCurrentTicket();
  };
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const openCloseDialog = () => {
    setCloseDialogOpen(true);
  };
  const closeCloseDialog = () => {
    setCloseDialogOpen(false);
  };
  const menuOptions = [
    {
      label: "View Details",
      clickHandler: () => {
        if (currentTicket.unread_count > 0) {
          setBadge((prev) => {
            return {
              ...prev,
              support: prev.support - 1,
            };
          });
        }
        navigate(`/support/${currentTicket._id}`);
      },
    },
    {
      label: "Close Ticket",
      clickHandler: openCloseDialog,
    },
    { label: "Delete", clickHandler: openDeleteDialog },
  ];
  const getMenuOptions = () => {
    if (!currentTicket) {
      return [];
    } else if (currentTicket.ticket_status == 0) {
      return menuOptions;
    } else {
      return menuOptions.filter((option) => option.label !== "Close Ticket");
    }
  };
  const openAddTicket = () => {
    setCreateTicketDrawerOpen(true);
  };
  const closeAddTicket = () => {
    setCreateTicketDrawerOpen(false);
    setNewTicketData({
      subject: "",
      description: "",
      category: "",
      image: null,
    });
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (
      No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const filteredTickets = () => {
    if (!filterName) {
      return [...tickets];
    } else {
      return tickets?.filter((x) =>
        x.subject.toLowerCase().includes(filterName.toLowerCase())
      );
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getSupportTicketsList = async () => {
    try {
      const result = await _get_support_tickets_list({ status: selectedTab });
      if (result.code == 200) {
        setTickets(result.support_ticket);
        setCounts({
          open: result.open_ticket_count,
          all: result.support_ticket_count,
          closed: result.close_ticket_count,
        });
      }
    } catch (error) {
      console.log(error, " catched while fetching support tickets list");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setLoading(false);
    }
  };

  const addNewTicket = async () => {
    try {
      setAddTicketLoading(true);
      let data = { ...newTicketData };
      if (newTicketData.image) {
        const imgresult = await upload_image(newTicketData.image);
        if (imgresult.code == 200) {
          data["image"] = imgresult.path;
        } else {
          enqueueSnackbar(imgresult.message, { variant: "error" });
          return;
        }
      } else {
        data["image"] = "";
      }
      const result = await _add_support_ticket(data);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setTickets((prev) => [result.support_ticket, ...prev]);
        closeAddTicket();
      }
    } catch (error) {
      console.log(error, " catched while adding new ticket");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } finally {
      setAddTicketLoading(false);
    }
  };
  const deleteSupportTicket = async () => {
    try {
      setDeleteTicketLoading(true);
      const result = await _delete_support_ticket(currentTicket._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setTickets((prev) =>
          prev.filter((ticket) => ticket._id !== currentTicket._id)
        );
        setCounts((prev) => {
          return {
            open: currentTicket.ticket_status == 0 ? prev.open - 1 : prev.open,
            all: prev.all - 1,
            closed:
              currentTicket.ticket_status != 0 ? prev.closed - 1 : prev.closed,
          };
        });
        handleCloseMenu();
        closeDeleteDialog();
      }
    } catch (error) {
      console.log(error, " catched while fetching support tickets list");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setDeleteTicketLoading(false);
    }
  };
  const closeSupportTicket = async () => {
    try {
      setCloseTicketLoading(true);
      const result = await _close_support_ticket(currentTicket._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setTickets((prev) => [
          { ...currentTicket, ticket_status: 1 },
          ...prev.filter((ticket) => ticket._id !== currentTicket._id),
        ]);
        // setCounts((prev) => {
        //   return {
        //     open: prev.open - 1,
        //     all: prev.all,
        //     closed: prev.closed + 1,
        //   };
        // });
        handleCloseMenu();
        closeCloseDialog();
      }
    } catch (error) {
      console.log(error, " catched while closing ticket");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setCloseTicketLoading(false);
    }
  };
  useEffect(() => {
    getSupportTicketsList();
  }, [selectedTab]);
  if (loading) {
    return (
      <Page title="Support Tickets">
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="mb-4"
          >
            <Typography variant="h4">Support Tickets</Typography>
            <Button
              //   onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={openAddTicket}
            >
              Add Support Ticket
            </Button>
          </Stack>
          <CircularLoader />
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Support Tickets">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="mb-4"
        >
          <Typography variant="h4">Support Tickets</Typography>
          <Button
            //   onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={openAddTicket}
          >
            Add Support Ticket
          </Button>
        </Stack>
        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-start"
          mb={3}
        >
          <div className="me-2 pointer">
            <Label
              onClick={() => setSelectedTab("open")}
              variant={selectedTab === "open" ? "ghost" : ""}
              style={{ width: 100, cursor: "pointer" }}
              color="info"
            >
              OPEN ({counts.open})
            </Label>
          </div>

          <div className="me-2 pointer">
            <Label
              onClick={() => setSelectedTab("close")}
              variant={selectedTab === "close" ? "ghost" : ""}
              style={{ width: 100, cursor: "pointer" }}
              color="success"
            >
              CLOSED ({counts.closed})
            </Label>
          </div>
          <div className="me-2 pointer">
            <Label
              onClick={() => setSelectedTab("all")}
              variant={selectedTab === "all" ? "filled" : ""}
              style={{ width: 100, cursor: "pointer" }}
              color="info"
            >
              ALL ({counts.all})
            </Label>
          </div>
        </Stack> */}
        {/* {tickets.length > 0 && ( */}
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Support Tickets"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {filteredTickets()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        subject,
                        _id,
                        description,
                        response_status,
                        ticket_status,
                        createdAt,
                        comment_badge_count,
                        last_action_date,
                        category,
                        unread_count,
                      } = row;
                      return (
                        <TableRow
                          hover
                          key={_id}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (unread_count > 0) {
                              setBadge((prev) => {
                                return {
                                  ...prev,
                                  support: prev.support - 1,
                                };
                              });
                            }
                            navigate(`/support/${_id}`);
                          }}
                        >
                          <TableCell>
                            {rowsPerPage * page + (index + 1)}
                          </TableCell>
                          <TableCell>{subject.substring(0, 50)}</TableCell>
                          <TableCell>
                            {description ? description.substring(0, 50) : "-"}
                          </TableCell>
                          <TableCell>
                            {capitalCase(category.replace("_", " "))}
                          </TableCell>
                          <TableCell>
                            <Label variant="filled" color="warning">
                              {unread_count ? unread_count : "0"}
                            </Label>
                          </TableCell>
                          <TableCell>
                            {response_status === 0 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="warning"
                              >
                                Waiting
                              </Label>
                            )}

                            {response_status === 1 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="success"
                              >
                                Replied
                              </Label>
                            )}
                          </TableCell>
                          <TableCell>
                            {!CheckActivityTime(last_action_date) &&
                              ticket_status === 0 && (
                                <Label
                                  style={{ width: 70 }}
                                  variant="ghost"
                                  color="info"
                                >
                                  OPEN
                                </Label>
                              )}

                            {ticket_status === 1 && (
                              <Label
                                style={{ width: 70 }}
                                variant="ghost"
                                color="error"
                              >
                                CLOSED
                              </Label>
                            )}
                            {CheckActivityTime(last_action_date) &&
                              ticket_status === 0 && (
                                <Label
                                  // style={{ width: 110 }}
                                  variant="ghost"
                                  color="error"
                                >
                                  Not Responding
                                </Label>
                              )}
                          </TableCell>
                          <TableCell>
                            {moment(last_action_date).fromNow()}
                          </TableCell>
                          <TableCell>
                            {moment(createdAt).format("LLL")}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e) => {
                                setCurrentTicket(row);
                                handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon="fluent:more-vertical-16-filled" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                      // });
                    })}
                  {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  <Menu
                    open={Boolean(menuOpen)}
                    anchorEl={menuOpen}
                    onClose={handleCloseMenu}
                  >
                    {getMenuOptions().map((item) => (
                      <MenuItem onClick={item.clickHandler}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableBody>

                {filteredTickets().length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={filteredTickets()?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {/* )} */}
      </Container>
      <MuiDialog
        open={deleteDialogOpen}
        onToggle={closeDeleteDialog}
        title="Delete Ticket"
        message="Are you sure you want to delete this support ticket"
        onAgree={deleteSupportTicket}
        loading={deleteTicketLoading}
      />
      <MuiDialog
        open={closeDialogOpen}
        onToggle={closeCloseDialog}
        title="Close Ticket"
        message="Are you sure you want to close this support ticket"
        onAgree={closeSupportTicket}
        loading={closeTicketLoading}
      />
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 400 } }}
        isOpen={createTicketDrawerOpen}
        onClose={closeAddTicket}
        title="Create Support Ticket"
        bottomComponent={
          <div className="mb-2 m-auto">
            <LoadingButton
              loading={addTicketLoading}
              variant="contained"
              onClick={addNewTicket}
            >
              Create
            </LoadingButton>
          </div>
        }
      >
        <Scrollbar>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                value={newTicketData.subject}
                label="Subject"
                required
                onChange={(e) => {
                  setNewTicketData((prev) => {
                    return { ...prev, subject: e.target.value };
                  });
                }}
              />
            </FormControl>
            <FormControl>
              {/* <RichEditor
                placeHolder="Description"
                value={newTicketData.description}
                setValue={(val) =>
                  setNewTicketData((prev) => {
                    return { ...prev, description: val };
                  })
                }
              /> */}
              <TextField
                multiline
                rows={4}
                value={newTicketData.description}
                label="Description"
                onChange={(e) => {
                  setNewTicketData((prev) => {
                    return { ...prev, description: e.target.value };
                  });
                }}
              />
            </FormControl>
            <FormControl required>
              <InputLabel>Category</InputLabel>
              <Select
                required
                label="Category"
                value={newTicketData.category}
                onChange={(e) =>
                  setNewTicketData((prev) => {
                    return { ...prev, category: e.target.value };
                  })
                }
              >
                <MenuItem value="technical_support">Technical Support</MenuItem>
                <MenuItem value="priority_support">Priority Support</MenuItem>
              </Select>
            </FormControl>
            {!newTicketData.image && (
              <label htmlFor="icon-button-file">
                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  name="image"
                  onChange={(e) => {
                    setNewTicketData((prev) => {
                      return {
                        ...prev,
                        image: e.target.files[0],
                      };
                    });
                    e.target.value = null;
                  }}
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  variant="outlined"
                  className="upload-button"
                  fullWidth
                >
                  Upload Image
                </Button>
              </label>
            )}
            {newTicketData.image && (
              <div
                style={{
                  position: "relative",
                  height: "150px",
                  width: "150px",
                  margin: "auto",
                }}
              >
                <img
                  src={URL.createObjectURL(newTicketData.image)}
                  height="150px"
                  width="150px"
                  style={{ objectFit: "contain" }}
                />
                <Iconify
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: 0,
                    color: "red",
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setNewTicketData((prev) => {
                      return { ...prev, image: null };
                    })
                  }
                  icon="gridicons:cross-circle"
                />
                {/* <Button
                  fullWidth
                  color="error"
                  variant="outlined"
                  onClick={() =>
                    setNewTicketData((prev) => {
                      return { ...prev, image: null };
                    })
                  }
                >
                  Remove
                </Button> */}
              </div>
            )}
          </Stack>
        </Scrollbar>
      </MuiDrawer>
    </Page>
  );
}

export default SupportTickets;
