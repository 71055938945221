import { Avatar, Badge, Tooltip, Typography } from "@mui/material";
import InfiniteScroller from "src/components/InfiniteScroller";
import { useAppContext } from "src/hooks";

function ChatList({
  chatList,
  setChatList,
  currentChat,
  setCurrentChat,
  chatCount,
  loadMoreChats,
  chatListPage,
  onChatClick,
}) {
  const { setChatListDrawerOpen } = useAppContext();
  const getBadgeColor = (item) => {
    if (item?.is_online) {
      return "primary";
    } else {
      return "inactive";
    }
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string?.length) {
      return string;
    }
    if (string?.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");
      return result;
    }
    return string;
  };
  return (
    <InfiniteScroller
      dataLength={(chatListPage + 1) * 15}
      hasMore={chatCount > chatList.length}
      height={"85vh"}
      next={() => {
        console.log("here in the next");
        loadMoreChats();
      }}
      style={{ overflowX: "hidden" }}
      inverse={false}
    >
      {chatList.map((chat, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: chat._id === currentChat?._id ? "#a5fdd2" : "",
              borderRadius: "3px",
            }}
            className={`user-card-parent d-flex align-items-center py-2 position-relative 
              item.isActive active-group`}
            onClick={() => {
              onChatClick(chat);
            }}
            // onClick={() => {
            //   if (currentChat?._id == chat._id) {
            //     setChatListDrawerOpen(false);
            //     return;
            //   } else {
            //     setCurrentChat(chat);
            //     setChatList((prev) => {
            //       return prev.map((cht) => {
            //         if (chat._id == cht._id) {
            //           return { ...cht, unread_count: 0 };
            //         } else {
            //           return { ...cht };
            //         }
            //       });
            //     });
            //     setChatListDrawerOpen(false);
            //   }
            // }}
          >
            <div>
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="circular"
                variant="dot"
                invisible={chat?.type == "1"}
                color={getBadgeColor(chat)}
              >
                <Avatar
                  className="chat-avatar"
                  style={{ height: 40, width: 40 }}
                  src={chat?.image}
                >
                  {chat?.label?.substring(0, 1)}
                </Avatar>
              </Badge>
            </div>
            <div className="ml-1">
              {/* <Tooltip arrow title={chat?.label}> */}
              <Typography fontWeight={500} noWrap>
                {truncateText(chat?.label, 33)}
              </Typography>
              <Typography fontWeight="light" fontSize="12px" noWrap>
                {truncateText(chat?.last_message?.text, 30)}
              </Typography>
              {/* </Tooltip> */}
            </div>
            {chat.unread_count > 0 && (
              <span className="messages-count">
                <Tooltip arrow title={`${chat?.unread_count} unread`}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    color="error"
                    badgeContent={chat?.unread_count}
                    max={99}
                  >
                    <div style={{ minHight: "2px" }}></div>
                  </Badge>
                </Tooltip>
              </span>
            )}
          </div>
        );
      })}
    </InfiniteScroller>
  );
}

export default ChatList;
