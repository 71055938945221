import { Card, CircularProgress, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Page } from "src/components";
import PricingPlans from "./PricingPlans";
import { useAppContext } from "src/hooks";
import { _subscription_listing } from "src/DAL/subscription";
import { useSnackbar } from "notistack";
import CancelSubscriptionConfrimation from "src/components/CancelSubscriptionConfirmation/CancelSubscriptionConfrimation";
import { _cancel_subscription } from "src/DAL";
import { _get_active_plans } from "src/DAL/plans";
import { result } from "lodash";
import moment from "moment";

function Plans() {
  const { dispatch_get_user_profile, dispatch_set_user_profile } =
    useAppContext();
  const user = dispatch_get_user_profile();
  const userInfo = user;
  console.log(userInfo, "userrrrrrrrrrrrrr");
  const { enqueueSnackbar } = useSnackbar();
  const [userSubscription, setUserSubscription] = useState({});
  console.log(userSubscription, "subscriptiondetailll");
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [activePlans, setActivePlans] = useState([]);
  console.log(activePlans, "plannnnss");

  const getActivePlans = async () => {
    setIsLoading(true);
    const result = await _get_active_plans();
    if (result.code === 200) {
      setActivePlans(result?.subscription_plan);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching user active plans");
    }
  };

  const getUserSubscription = async () => {
    try {
      if (!user?.user_id) return;
      const result = await _subscription_listing(user.user_id);
      if (result.code === 200) {
        setUserSubscription(result?.subscription);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching user subscriptions:", error);
    }
  };

  const handleCancelSubscription = () => {
    setOpenDelete(true);
  };

  const handleAgreeCancel = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const postData = {
      subscription_id: userSubscription?._id,
    };
    const result = await _cancel_subscription(postData);
    if (result.code === 200) {
      setIsLoading(false);
      dispatch_set_user_profile({ ...user, subscription_plan_id: null });
      enqueueSnackbar(result.message, {
        variant: "success",
      });
      setUserSubscription({});
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(user?.user_id, "iddddddddddddddddd");
    if (user?.user_id) {
      getUserSubscription();
    }

    getActivePlans();
  }, [user]);

  if (isLoading) {
    return (
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Page title="Payment Plans">
      <Container maxWidth="xl">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center mb-3 ">
              <h3>
                <span>Ready to get started?</span>
              </h3>
              <p>Choose a plan tailored to your needs.</p>
              {!!user?.subscription_plan_id ? (
                <p>
                  Currently Active (
                  {user?.subscription_plan_id
                    ? user?.subscription_plan_id?.name
                    : ""}
                  ) Plan
                </p>
              ) : (
                <p>No Active Plan</p>
              )}
            </div>
          </div>
        </div>
        {!!userSubscription && (
          <div className="row">
            <div className="my-3 col-12 ">
              <Card
                sx={{
                  boxShadow: "0 2px 5px 1px rgba(64,60,67,.16)",
                  borderRadius: "8px",

                  padding: "20px",
                }}
              >
                <h5>Cancel Your Plan Subscription</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <div style={{ marginRight: "20px" }}>
                      <strong>Subscription Date</strong>
                      <br />
                      <span>
                        {moment(userSubscription?.subscription_date).format(
                          "LL"
                        )}
                      </span>
                    </div>
                    <div>
                      <strong>Renewal Date</strong>
                      <br />
                      <span>
                        {moment(
                          userSubscription?.subscription_renewal_date
                        ).format("LL")}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-danger"
                      onClick={handleCancelSubscription}
                      //   disabled={subscription?.subscription_status === false}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        )}
        <PricingPlans activePlans={activePlans} />
      </Container>

      <CancelSubscriptionConfrimation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleAgreeCancel}
      />
    </Page>
  );
}

export default Plans;
