export const convertCurrencyToSign = (currency) => {
  if (currency === "gbp") {
    return "£";
  } else if (currency === "eur") {
    return "€";
  } else if (currency === "usd") {
    return "$";
  } else if (currency === "aud") {
    return "A$";
  } else {
    return "";
  }
};

export const currency_list = [
  { name: "gbp", sign: "£" },
  { name: "eur", sign: "£" },
  { name: "usd", sign: "$" },
  { name: "aud", sign: "A$" },
];
