import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  MuiDrawer,
  NavBackHeader,
  NoData,
  Page,
  Scrollbar,
  SearchBar,
} from "src/components";
import { LoadingButton } from "@mui/lab";
import {
  _add_task_comment,
  _delete_task,
  _delete_task_comment,
  _project_detail,
  _task_comment_list,
  _update_task_comment,
  _update_task_members,
  _update_task_status,
  _verified_tasks,
} from "src/DAL";
import {
  Container,
  Stack,
  Typography,
  Paper,
  Box,
  IconButton,
  Grid,
  Button,
  Skeleton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Tooltip,
  Pagination,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useAppContext } from "src/hooks";
import VerifiedTaskCard from "./components/VerifiedTaskCard";
import { get_zone_date, handle_localSearch, isColorLight } from "src/utils";
import CommentCard from "../workspace-project/components/CommentCard";
import { startTransition } from "react";
import { ALLOWED_TASK_FILES_WITH_ICONS } from "src/constants";
import { s3baseUrl } from "src/config";
/* <<--------------------------------------------------------->> */

/* <<--------------------------------------------------------->> */
const VerifiedTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [project, setProject] = useState(undefined);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [currentUserRole, setCurrentUserRole] = useState("team");
  const [processing, setProcessing] = useState(false);
  const [delTaskOpen, setDelTaskOpen] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [editCommentType, setEditCommentType] = useState("0");
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [selectedTaskComments, setSelectedTaskComments] = useState([]);
  const [processingEditComment, setProcessingEditComment] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [delCommentOpen, setDelCommentOpen] = useState(false);
  const [deletingTaskComment, setDeletingTaskComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(undefined);
  const [newComment, setNewComment] = useState("");
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [newCommentType, setNewCommentType] = useState("0");
  const [selectedTaskTitle, setSelectedTaskTitle] = useState("");
  const [selectedTaskDescription, setSelectedTaskDescription] = useState("");
  const { dispatch_get_user_profile, socketEmit } = useAppContext();
  const userProfile = dispatch_get_user_profile();
  const [tagExpand, setTagExpand] = useState(false);
  const [loaders, setLoaders] = useState({
    tasks: true,
    project: true,
    processing: false,
  });
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { project_id, workspace_id } = params;
  /* <<--------------------------------------------------------->> */
  const getModifiedTasks = (_tasks) => {
    return _tasks.map((item) => {
      return { ...item, query_field: item.task_title };
    });
  };
  const getTasks = async (_proj_id) => {
    const data = { workspace_id: workspace_id };
    const result = await _verified_tasks(project_id, page);
    if (result.code === 200) {
      setTasks(getModifiedTasks(result.project_list.task_list));
      setPageCount(
        result.project_list.task_count % 50 == 0
          ? result.project_list.task_count / 50
          : Math.floor(result.project_list.task_count / 50) + 1
      );
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
    } else {
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProjectDetail = async () => {
    const result = await _project_detail(null, project_id);
    if (result.code === 200) {
      console.log(result, "proj-detail-res");
      setLoaders((prev) => {
        return { ...prev, project: false };
      });
      setProject(result.project_detail);
      setCurrentUserRole(result.project_detail.role);
    } else {
      setLoaders((prev) => {
        return { ...prev, project: false };
      });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavBack = () => {
    navigate(-1);
  };
  const onSetSelectedTask = (task) => {
    setSelectedTask(task);
  };
  const onOpenComments = (task) => {
    console.log(task);
    setSelectedTask(task);
    openComments();
    getTaskComments(task._id);
  };
  const onOpenDetail = (task) => {
    openTaskDetail(task);
  };
  const canUpdateStatus = () => {
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(currentUserRole) === "team") {
      return false;
    }
    return false;
  };
  const projectTeam = () => {
    if (!project) {
      return [];
    }
    if (!project.team) {
      return [];
    }
    if (project.team) {
      return project.team;
    }
    return [];
  };

  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };
  const getFilteredTasks = () => {
    return handle_localSearch(searchValue, tasks);
  };
  /* toggles */
  const openTaskDetail = (task) => {
    console.log(task, "on open detail");
    setSelectedTaskDescription(task.task_description);
    setSelectedTaskTitle(task.task_title);
    setSelectedTask(task);
    setTaskDetailOpen(true);
  };
  const closeTaskDetail = () => {
    setTaskDetailOpen(false);
  };
  const closeDelCommentDialog = () => {
    setDelCommentOpen(false);
  };
  const openDelCommentDialog = () => {
    setDelCommentOpen(true);
  };
  const closeDelTask = () => {
    setDelTaskOpen(false);
  };
  const openDelTask = () => {
    setDelTaskOpen(true);
  };
  const openComments = () => {
    setCommentsOpen(true);
  };
  const closeComments = () => {
    setCommentsOpen(false);
    startTransition(() => {
      setEditingComment(false);
      setSelectedComment(undefined);
      setEditCommentTitle("");
      setEditCommentType("0");
      setSelectedTaskComments([]);
    });
  };
  /* toggles end */
  useEffect(() => {
    console.log(selectedTask, "asfaskdasufh");
  }, [selectedTask]);
  const onTaskAction = (action, data) => {
    console.log(data, "on-action");
    setSelectedTask(data);
    if (action === "del_task") {
      console.log(selectedTask, "selectedTask");
      console.log(data, "param-data");
      openDelTask();
    }
    if (action === "change_status") {
      handleChangeStatus(data);
    }
    if (action === "change_members") {
      handleChangeMembers(data);
    }
  };
  const handleChangeMembers = async (data) => {
    const prev_tasks = Array.from(tasks);
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { task, taskTeam, isSelected, targetUser, action } = data;
    console.log(data, "payload");
    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user) !== String(targetUser.user)
        );
      }
    }
    console.log(data, "on-change-Members");
    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };
    setTasks((prev) => getUpdatedTasks(prev));
    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(String(user));
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      workspace_id: workspace_id,
      list_type: "0",
    };
    const result = await _update_task_members(req_data);
    if (result.code === 200) {
      console.log(result, "update-members-res");
      const { task_detail } = result;
      const updatedTasks = (_data) => {
        return _data.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task_detail, query_field: item.task_title };
          }
          return item;
        });
      };
      setTasks((prev) => updatedTasks(prev));
      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        const { project, section_id, _id } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      setTasks(prev_tasks);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeStatus = async (data) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { task, status } = data;
    console.log(data, "on-change-Status");
    const req_data = {
      task_id: task._id,
      task_status: status,
      list_type: "0",
    };
    if (String(status) === "4") {
      console.log("already verified");
      return;
    }
    const result = await _update_task_status(req_data);
    if (result.code === 200) {
      console.log(result, "res");
      if (String(status) !== "4") {
        setTasks((prev) =>
          prev.filter((item) => String(item._id) !== String(task._id))
        );
      }
      enqueueSnackbar("Task moved back to project", { variant: "info" });
      // let receiver = [result.task_detail.creator_profile.user_id];
      // result.task_detail.team.map((member) => {
      //   receiver.push(member.user_id);
      // });
      // receiver = receiver.filter(
      //   (id) => id !== dispatch_get_user_profile().user_id
      // );
      const { project, section_id, _id } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task status`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.hidden_members,
        data: result.Project,
        message: `A member updated the task status`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDeleteTask = async () => {
    console.log(selectedTask);
    if (!selectedTask) {
      return;
    }
    setProcessing(true);
    const result = await _delete_task(selectedTask._id);
    if (result.code === 200) {
      setTasks((prev) =>
        prev.filter((item) => String(item._id) !== String(selectedTask._id))
      );
      closeDelTask();
      console.log(result, "del-res");
      setProcessing(false);
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  /*  */
  const onEditComment = (_comment) => {
    setSelectedComment(_comment);
    setEditingComment(true);
    setEditCommentTitle(_comment.comment_title);
    setEditCommentType(String(_comment.comment_type));
  };
  const onDelComment = (_comment) => {
    setSelectedComment(_comment);
    openDelCommentDialog();
  };
  const handleCancelEditComment = async (e) => {
    setEditingComment(false);
    setSelectedComment(undefined);
    setEditCommentTitle("");
    setEditCommentType("0");
  };
  const handleUpdateTaskComment = async (e) => {
    console.log(selectedComment, "to-update");
    if (!selectedComment) {
      console.log("no selectedComment");
      return;
    }
    const data = {
      comment_id: selectedComment._id,
      comment_title: selectedComment.comment_title,
      comment_type: selectedComment.comment_type,
    };
    console.log(data, "req-update-com");
    setProcessingEditComment(true);
    // const result = { code: 200 };
    const result = await _update_task_comment(data, selectedTask._id);
    if (result.code === 200) {
      console.log(result, "upd-com-res");
      setProcessingEditComment(false);
      enqueueSnackbar("Comment updated successfully", { variant: "success" });
      const updated_comments = (_comments) => {
        console.log(_comments, "_comments");
        if (!_comments) {
          return;
        }
        return _comments.map((item) => {
          if (String(item._id) === String(selectedComment._id)) {
            return selectedComment;
          }
          return item;
        });
      };
      setSelectedTaskComments((prev) => updated_comments(prev));
      setSelectedTask((prev) => {
        if (String(selectedComment.comment_type) === "0") {
          return {
            ...prev,
            task_comment_count: Number(prev.task_comment_count) + 1,
          };
        }
        if (String(selectedComment.comment_type) === "1") {
          return {
            ...prev,
            task_comment_count: Number(prev.task_comment_count) - 1,
          };
        }
        return { ...prev, task_comment: updated_comments(prev.task_comment) };
      });
      setTasks((prev) =>
        prev.map((task) => {
          if (String(task._id) === String(selectedTask._id)) {
            if (String(selectedComment.comment_type) === "0") {
              return {
                ...task,
                task_comment_count: Number(task.task_comment_count) + 1,
              };
            }
            if (String(selectedComment.comment_type) === "1") {
              return {
                ...task,
                task_comment_count: Number(task.task_comment_count) - 1,
              };
            }
          }
          return task;
        })
      );
      setEditingComment(false);
      setSelectedComment(undefined);
    } else {
      setProcessingEditComment(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }

    //
  };
  const handleDelTaskComment = async () => {
    console.log(selectedComment, "to-del");
    if (!selectedComment) {
      console.log("no selectedComment");
      return;
    }
    setDeletingTaskComment(true);
    const result = await _delete_task_comment(
      { comment_id: selectedComment._id },
      selectedTask._id
    );
    if (result.code === 200) {
      console.log(result, "del-com-res");
      setDeletingTaskComment(false);
      enqueueSnackbar("Comment deleted successfully", { variant: "success" });
      const updated_comments = (_comments) => {
        return _comments.filter(
          (item) => String(item._id) !== String(selectedComment._id)
        );
      };
      setSelectedTask((prev) => {
        return {
          ...prev,
          task_comment_count:
            prev.task_comment_count > 0
              ? Number(prev.task_comment_count) - 1
              : 0,
        };
      });
      setSelectedTaskComments((prev) => updated_comments(prev));
      setTasks((prev) => {
        return prev.map((task) => {
          if (String(task._id) === String(selectedTask._id)) {
            return {
              ...task,
              task_comment_count:
                task.task_comment_count > 0
                  ? Number(task.task_comment_count) - 1
                  : 0,
            };
          }
          return task;
        });
      });
      setSelectedComment(undefined);
      closeDelCommentDialog();
    } else {
      setDeletingTaskComment(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSendComment = async (e) => {
    console.log(newComment, "to-add");
    console.log(newCommentType, "to-add");
    if (newComment === "") {
      enqueueSnackbar("Comment cannot be empty", { variant: "error" });
      return;
    }
    if (!workspace_id) {
      console.log("no workspaceId");
      return;
    }
    if (!selectedTask) {
      console.log("no selectedTask");
      return;
    }
    setSendingComment(true);
    console.log(selectedTask, "slct");
    const data = {
      comment_title: newComment.trim().replace(/<a /g, "<a target='_blank' "),
      comment_type: newCommentType,
    };
    const result = await _add_task_comment(data, selectedTask._id);
    if (result.code === 200) {
      const new_comment = result.task_comment;
      console.log(result, "add-com-res");
      const updated = (_comments) => {
        let updated = Array.from(_comments);
        updated.unshift(new_comment);
        return updated;
      };
      setSelectedTaskComments((prev) => updated(prev));
      setSelectedTask((prev) => {
        return {
          ...prev,
          task_comment_count: prev.task_comment_count + 1,
        };
      });
      setTasks((prev) =>
        prev.map((task) => {
          if (String(task._id) === String(selectedTask._id)) {
            return {
              ...task,
              task_comment_count: task.task_comment_count + 1,
            };
          }
          return section;
        })
      );
      setNewComment("");
      setSendingComment(false);
      enqueueSnackbar("Comment added successfully", { variant: "success" });
      if (newCommentType == "0") {
        // let receiver = [selectedTask.creator_profile.user_id];
        // selectedTask.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id } = selectedTask;
        socketEmit("send_notification_to_all", {
          receiver: result.task_comment.allow_members,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } added a comment in task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      setSendingComment(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getTaskComments = async (task_id) => {
    const data = {
      workspace_id: workspace_id,
    };
    setLoadingComments(true);
    const result = await _task_comment_list(task_id);
    if (result.code === 200) {
      console.log(result, "task-comm-res");
      setLoadingComments(false);
      setSelectedTaskComments(result.project_list.comment_list);
    } else {
      setLoadingComments(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = currentTask();
    const { image } = task;
    if (image) {
      for (let path of image) {
        let data = getFileIconType(path);
        const { file_type, file_path, color } = data;
        files.push({
          path: file_path,
          type: file_type,
          db_path: path,
          color: color,
        });
      }
    }

    return files;
  };
  const renderTasks = () => {
    if (loaders.project) {
      return false;
    }
    if (loaders.tasks) {
      return false;
    }
    return true;
  };
  /* <<--------------------------------------------------------->> */
  const cleanUp = () => {
    // setTasks([]);
    setLoaders({
      tasks: true,
      project: true,
      processing: false,
    });
    // setProject(undefined);
    // setSelectedTask(undefined);
    // setProcessing(false);
    // setDelTaskOpen(false);
  };
  /* <<--------------------------------------------------------->> */
  useLayoutEffect(() => {
    let render = renderTasks();
    console.log(render, "rander?");
    getTasks();
    getProjectDetail();
    return () => {
      cleanUp();
    };
  }, [page]);
  /* <<--------------------------------------------------------->> */

  return (
    <Page title="Verified Tasks">
      <Container maxWidth="xl">
        <Grid container className="project-header">
          <Grid item display="flex" alignItems="center">
            <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
              <Iconify icon="ep:back" />
            </IconButton>
            {loaders.project ? (
              <Skeleton sx={{ width: "8rem" }} variant="text" />
            ) : (
              <Typography fontWeight="bold">{project?.title}</Typography>
            )}
          </Grid>
        </Grid>
        {loaders.tasks && <CircularLoader />}
        {tasks.length > 0 && (
          <Stack
            direction="row"
            sx={{
              mt: (theme) => theme.spacing(3),
              mb: (theme) => theme.spacing(3),
              // justifyContent: { xs: 'flex-start', sm: 'flex-end' },
            }}
          >
            <SearchBar
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Stack>
        )}
        {renderTasks() && (
          <Stack spacing={3}>
            {getFilteredTasks().map((item, index) => (
              <VerifiedTaskCard
                key={index}
                onSetSelectedTask={(task) => onSetSelectedTask(task)}
                onOpenComments={onOpenComments}
                onOpenDetail={onOpenDetail}
                projectTeam={projectTeam()}
                task={item}
                onTaskAction={(action, data) => onTaskAction(action, data)}
                isDraggingOver={false}
                userProfile={userProfile}
                currentUserRole={currentUserRole}
                rerenderDependencies={[item]}
              />
            ))}
          </Stack>
        )}
        {pageCount > 1 && !loaders.tasks && (
          <div className="d-flex justify-content-center my-3 ">
            <Pagination
              count={pageCount}
              page={page + 1}
              onChange={(e, newPage) => setPage(newPage - 1)}
              color="primary"
            />
          </div>
        )}
        <NoData
          dataLength={tasks.length}
          loading={loaders.tasks}
          title="No tasks found"
        />
      </Container>
      {/* dialogs */}
      <MuiDialog
        open={delTaskOpen}
        onToggle={closeDelTask}
        onAgree={handleDeleteTask}
        loading={processing}
        title="Delete Task"
        message="Are you sure you want to delete this task?"
      />
      <MuiDialog
        open={delCommentOpen}
        onToggle={closeDelCommentDialog}
        onAgree={handleDelTaskComment}
        loading={deletingTaskComment}
        title="Delete Comment"
        message={`Are you sure you want to delete this comment?`}
      />
      {/* DRAWERS */}
      {/* task detail */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 620 } }}
        isOpen={taskDetailOpen}
        onClose={closeTaskDetail}
        title="Task Detail"
        bottomComponent={
          <div className="centered-row pb-1">
            <LoadingButton
              onClick={() => {}}
              className="active-border"
              variant="outlined"
              loading={false}
              disabled={true}
            >
              Update
            </LoadingButton>
          </div>
        }
      >
        {currentTask() && (
          <>
            <Stack spacing={2}>
              <Stack spacing={0.1}>
                <Typography
                  sx={{ color: (theme) => theme.palette.text.disabled }}
                  fontWeight="bold"
                >
                  Created at :
                </Typography>
                <Typography
                  sx={{ color: (theme) => theme.palette.text.disabled }}
                >
                  {get_zone_date(
                    currentTask().createdAt,
                    "DD-MMM-YYYY hh:mm a"
                  )}
                </Typography>
              </Stack>
              <TextField
                value={selectedTaskTitle}
                disabled
                onChange={(e) => {}}
                label="Title"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
              />
              <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
                {currentTask()?.tag_colors?.map((tag) => (
                  <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
                    <div
                      style={{
                        backgroundColor: tag.color_code,
                        borderRadius: "5px",
                        minWidth: "50px",
                        maxWidth: "100px",
                        maxHeight: tagExpand ? "20px" : "5px",
                        minHeight: "5px",
                        fontSize: "10px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        color: isColorLight(tag.color_code) ? "#000" : "#fff",
                        transition: "max-height 0.3s ease",
                        cursor: "pointer",
                      }}
                      className="px-1"
                      onClick={() => setTagExpand((prev) => !prev)}
                    >
                      {tagExpand && tag.name}
                    </div>
                  </Tooltip>
                ))}
              </Stack>
              {/* <TextField
                value={selectedTaskDescription}
                disabled
                onChange={(e) => {}}
                label="Title"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
              /> */}
              {selectedTaskDescription && (
                <div>
                  <Typography fontWeight={"bold"}>Description:</Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedTaskDescription,
                    }}
                  />
                </div>
              )}
              <Grid container spacing={1} direction={"row"}>
                {getTaskImages().map((item, index) => {
                  return (
                    <Grid sx={{ position: "relative" }} key={index} item xs={3}>
                      <div className="task-files-container" key={index}>
                        {item.type === "file" ? (
                          <a
                            href={s3baseUrl + item.db_path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Iconify
                              sx={{
                                height: "4rem",
                                width: "4rem",
                                color: item.color,
                              }}
                              icon={item.path}
                            />
                          </a>
                        ) : (
                          <a
                            href={s3baseUrl + item.path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="add-task-img"
                              src={s3baseUrl + item.path}
                            />
                          </a>
                        )}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </>
        )}
      </MuiDrawer>
      {/* comments */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 620 } }}
        isOpen={commentsOpen}
        onClose={closeComments}
        title="Task Comments"
        bottomComponent={
          <Stack sx={{ px: 2.5, pb: "5px" }}>
            <Stack
              sx={{ py: 1.5 }}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {editingComment ? (
                <TextField
                  value={selectedComment?.comment_title}
                  onChange={({ target }) =>
                    setSelectedComment({
                      ...selectedComment,
                      comment_title: target.value,
                    })
                  }
                  fullWidth
                  autoFocus={true}
                  focused={true}
                  variant="outlined"
                  label="Comment"
                  multiline
                  rows={4}
                  // disabled={processingEditComment}
                  disabled={true}
                />
              ) : (
                <TextField
                  autoFocus={true}
                  value={newComment}
                  onChange={({ target }) => setNewComment(target.value)}
                  fullWidth
                  variant="outlined"
                  label="Comment"
                  multiline
                  rows={4}
                  disabled={true}
                  // disabled={sendingComment}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <div className="centered-row">
                  {editingComment ? (
                    <FormControl>
                      <RadioGroup
                        row
                        disabled={true}
                        // disabled={processingEditComment}
                        aria-labelledby="gender-select-radio-buttons-label"
                        name="row-radio-buttons-group"
                        value={selectedComment?.comment_type}
                        onChange={({ target }) =>
                          setSelectedComment({
                            ...selectedComment,
                            comment_type: target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio disabled />}
                          label="Public"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio disabled />}
                          label="Private"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="gender-select-radio-buttons-label"
                        name="row-radio-buttons-group"
                        value={newCommentType}
                        // disabled={sendingComment}
                        disabled={true}
                        onChange={({ target }) =>
                          setNewCommentType(target.value)
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio disabled />}
                          label="Public"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio disabled />}
                          label="Private"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>
              </Stack>
              {editingComment ? (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingButton
                    loading={false}
                    size="small"
                    variant="contained"
                    onClick={() => handleCancelEditComment()}
                    startIcon={<Iconify icon="gridicons:cross" />}
                    color="error"
                    disabled={true}
                    // disabled={processingEditComment}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    loading={processingEditComment}
                    size="small"
                    disabled={true}
                    // disabled={processingEditComment}
                    variant="contained"
                    onClick={() => handleUpdateTaskComment()}
                    startIcon={<Iconify icon="dashicons:saved" />}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              ) : (
                <LoadingButton
                  loading={sendingComment}
                  size="small"
                  variant="contained"
                  disabled={true}
                  // disabled={newComment.trim() === ''}
                  onClick={handleSendComment}
                  startIcon={<Iconify icon="fluent:send-24-regular" />}
                >
                  Send
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        }
        headerComponent={
          currentTask() && (
            <Box sx={{ pt: "12px" }}>
              <Typography sx={{ px: 2.5 }} noWrap fontWeight="bold">
                {currentTask().task_title}
              </Typography>
            </Box>
          )
        }
      >
        {currentTask() && (
          <>
            <Stack className="position-relative" spacing={1}>
              {selectedTaskComments.map((item, index) => {
                return (
                  <CommentCard
                    onEdit={onEditComment}
                    onDel={onDelComment}
                    key={index}
                    item={item}
                    disabled={true}
                    currentUserRole={currentUserRole}
                  />
                );
              })}
              {loadingComments && (
                <span className="comment-loader-wrap">
                  <span className="comment-loader">
                    <CircularLoader size="3rem" />
                  </span>
                </span>
              )}
              {selectedTaskComments.length === 0 && (
                <span className="comment-loader-wrap">
                  <span className="comment-loader">
                    <Typography
                      color={(theme) => theme.palette.text.disabled}
                      sx={{ mt: 30 }}
                      textAlign="center"
                    >
                      No comments added
                    </Typography>
                  </span>
                </span>
              )}
            </Stack>
          </>
        )}
      </MuiDrawer>
    </Page>
  );
};

export default VerifiedTasks;
