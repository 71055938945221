import React from 'react';
import { notFound, noResults } from 'src/assets';
import { styled } from '@mui/material/styles';
import { CircularProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

const ImgContainer = styled('div')({
  width: '30%',
  position: 'relative',
});

const NoData = ({ title, loading, showImage, dataLength,taskSection=false }) => {
  const matches_mobile = useMediaQuery('(min-width:375px)');

  if (loading) {
    return taskSection?<CircularProgress sx={{position:"relative", left:"50%"}} />:<></>;
  }
  if (dataLength !== 0) {
    return <></>;
  }
  return (
    <Stack
      sx={{ marginTop: `${matches_mobile && '5rem'}` }}
      alignItems='center'
      justifyContent='center'
      width='100%'
    >
      {showImage !== false && (
        <ImgContainer id='not-found-results-container'>
          <img width='100%' src={noResults} alt='no data added' />
        </ImgContainer>
      )}
      <Typography
        sx={{ marginTop: showImage === false ? '32vh' : '' }}
        textAlign='center'
        variant='h5'
      >
        {title ? title : ' No Results Found !'}
      </Typography>
    </Stack>
  );
};
NoData.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  showImage: PropTypes.bool,
  dataLength: PropTypes.number.isRequired,
};
export default NoData;
