import { get_from_localStorage, invokeApi } from 'src/utils';

export const _add_task_comment = async (data, id) => {
  const requestObj = {
    path: `api/task/add_task_comment/${id}`,
    method: 'POST',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _task_comment_list = async (id) => {
  const requestObj = {
    path: `api/task/task_comment_list/${id}`,
    method: 'GET',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
  };
  return invokeApi(requestObj);
};
export const _load_more_comments = async (path) => {
  const requestObj = {
    path: path,
    method: 'GET',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
  };
  return invokeApi(requestObj);
}
export const _update_task_comment = async (data, id) => {
  const requestObj = {
    path: `api/task/update_task_comment/${id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_task_comment = async (data, id) => {
  const requestObj = {
    path: `api/task/delete_task_comment/${id}`,
    method: 'POST',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
