import { get_from_localStorage, invokeApi } from "src/utils";

export const _user_workspaces = async (data, page = 0, limit = 100) => {
  const requestObj = {
    path: `api/workspace/workspace_list?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _user_workspace_detail = async (data, id) => {
  const requestObj = {
    path: `api/workspace/workspace_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_user_workspace = async (data, id) => {
  const requestObj = {
    path: `api/workspace/edit_workspace/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _create_user_workspace = async (data) => {
  const requestObj = {
    path: `api/workspace/create_workspace`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_user_workspace = async (data, id) => {
  const requestObj = {
    path: `api/workspace/delete_workspace/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _leave_workspace = async (data) => {
  const requestObj = {
    path: `api/workspace/leave_workspace`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _workspace_team = async (data, id) => {
  /*see postman for pagination */
  const requestObj = {
    path: `api/team/team_list_by_workspace_id/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_workspace_member = (data, id) => {
  const requestObj = {
    path: `api/team/change_team_member_settings/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _workspace_admin_detail = (id) => {
  const requestObj = {
    path: `api/customer/workspace_customer_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_custom_status = (data) => {
  const requestObj = {
    path: `api/workspace/remove_workspace_status`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_custom_status = (data) => {
  const requestObj = {
    path: `api/workspace/add_workspace_status`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
