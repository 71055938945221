import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import Label from "../../components/Label";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { CircularLoader, Iconify, MuiDialog } from "src/components";
import { upload_image } from "src/utils";
import {
  _add_support_ticket_comment,
  _close_support_ticket,
  _delete_message,
  _delete_support_ticket,
  _delete_support_ticket_comment,
  _support_ticket_details,
} from "src/DAL";
import { capitalCase } from "change-case";
import { useAppContext } from "src/hooks";

function SupportTicketDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const { socketEmit, dispatch_get_user_profile } = useAppContext();
  const ticket_id = params?.id;

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [ticketData, setTicketData] = useState("");
  const [comments, setComments] = useState([]);
  const [Templates, setTemplates] = useState([[], []]);

  const [deleteTicketLoading, setDeleteTicketLoading] = useState(false);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const [closeTicketLoading, setCloseTicketLoading] = useState(false);
  const [deleteMessageDialogOpen, setDeleteMessageDialogOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState();
  const [deleteMessageLoading, setDeleteMessageLoading] = useState(false);
  const [messageMenuOpen, setMessageMenuOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inputs, setInputs] = useState({
    message: "",
    image: "",
  });
  const [menuOpen, setMenuOpen] = useState(null);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const openDeleteMessageDialog = () => {
    setDeleteMessageDialogOpen(true);
  };
  const closeDeleteMessageDialog = () => {
    setDeleteMessageDialogOpen(false);
    setCurrentMessage();
  };
  const handleOpenMessageMenu = (e) => {
    setMessageMenuOpen(e.target);
  };
  const handleCloseMessageMenu = () => {
    setMessageMenuOpen();
  };
  const handleClickRemove = () => {
    setInputs({
      ...inputs,
      image: "",
    });
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const openCloseDialog = () => {
    setCloseDialogOpen(true);
  };
  const closeCloseDialog = () => {
    setCloseDialogOpen(false);
  };
  const MENU_OPTIONS = [
    {
      label: "Close Ticket",
      clickHandler: openCloseDialog,
    },
    { label: "Delete", clickHandler: openDeleteDialog },
  ];
  const getMenuOptions = () => {
    if (!ticketData) {
      return [];
    } else if (ticketData.ticket_status == 0) {
      return MENU_OPTIONS;
    } else {
      return MENU_OPTIONS.filter((option) => option.label !== "Close Ticket");
    }
  };
  const handleOpenMenu = (e) => {
    setMenuOpen(e.target);
  };
  const handleCloseMenu = () => {
    setMenuOpen();
  };
  const deleteSupportTicket = async () => {
    try {
      setDeleteTicketLoading(true);
      const result = await _delete_support_ticket(ticketData._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        navigate(-1, { replace: true });
      }
    } catch (error) {
      console.log(error, " catched while fetching support tickets list");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setDeleteTicketLoading(false);
    }
  };
  const closeSupportTicket = async () => {
    try {
      setCloseTicketLoading(true);
      const result = await _close_support_ticket(ticketData._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setTicketData((prev) => {
          return { ...prev, ticket_status: 1 };
        });
        handleCloseMenu();
        closeCloseDialog();
      }
    } catch (error) {
      console.log(error, " catched while closing ticket");
      enqueueSnackbar("Something went wrong", { varinat: "error" });
    } finally {
      setCloseTicketLoading(false);
    }
  };
  const handleDeleteMessage = async () => {
    try {
      setDeleteMessageLoading(true);
      const result = await _delete_message(currentMessage._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setComments((prev) =>
          prev.filter((msg) => msg._id !== currentMessage._id)
        );
        handleCloseMessageMenu();
        closeDeleteMessageDialog();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteMessageLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // upload image
    if (inputs.image) {
      setIsLoadingSend(true);
      const image_resp = await upload_image(inputs.image);
      if (image_resp.code == 200) {
        const postData = {
          support_ticket_id: ticket_id,
          message: inputs.message,
          image: image_resp.path,
        };

        const resp = await _add_support_ticket_comment(postData);
        if (resp.code === 200) {
          setIsLoadingSend(false);
          setInputs({
            message: "",
            image: "",
          });
          fetchDetails();
        } else {
          setIsLoadingSend(false);
          enqueueSnackbar(resp.message, { variant: "error" });
        }
      }
    } else {
      const postData = {
        support_ticket_id: ticket_id,
        message: inputs.message,
        image: inputs.image,
      };

      setIsLoadingSend(true);
      const resp = await _add_support_ticket_comment(postData);
      if (resp.code === 200) {
        setIsLoadingSend(false);
        setInputs({
          message: "",
          image: "",
        });
        fetchDetails();
      } else {
        setIsLoadingSend(false);
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    }
  };

  const fetchDetails = async () => {
    const resp = await _support_ticket_details(ticket_id);
    if (resp.code === 200) {
      setTicketData(resp.support_ticket);
      let name = ticketData.subject;
      console.log("userData", resp.support_ticket_comment_array);
      setComments(resp.support_ticket_comment_array);
      setIsLoading(false);
      socketEmit("send_unread_count", {
        user_id: dispatch_get_user_profile().user_id,
      });
    } else if (resp.code == "400") {
      navigate("/support");
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (ticketData) {
      let name = [];
      name.push(ticketData.user_id);
      console.log("User Detail", ticketData);
      setTemplates([
        [
          "Hi User",
          "Hi " +
            ticketData.user_id.first_name +
            " " +
            ticketData.user_id.last_name +
            ", ",
        ],
        ["Greetings", "Thanks for reaching out to our support team. "],
        ["Regards", "Regards\nDone Support Team "],
        ["Ending Signature", "Regards Done Support Team "],
        ["Thanks & Reagards", "Thanks & Regards\nDone Support Team "],
        ["Feedback", "Please Let us know if this helps you "],
        [
          "Reminder",
          "Hi, " +
            ticketData.user_id.first_name +
            " " +
            ticketData.user_id.last_name +
            " we hope your issue has been resolved kindly give your feedback\nRegards Done Support Team ",
        ],
      ]);
    }
  }, [ticketData]);
  useEffect(() => {
    fetchDetails();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }

  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (
      No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <p className="text-capitalize mb-0">
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                >
                  Subject:
                </span>
                {ticketData.subject}
              </p>
            </div>

            <div className="d-flex align-items-start ">
              <div className="m-2 d-flex justify-content-between align-items-center">
                {!CheckActivityTime(ticketData.last_action_date) &&
                  ticketData.ticket_status === 0 && (
                    <Label style={{ width: 70 }} variant="ghost" color="info">
                      OPEN
                    </Label>
                  )}

                {ticketData.ticket_status === 1 && (
                  <Label style={{ width: 70 }} variant="ghost" color="error">
                    CLOSED
                  </Label>
                )}
                {CheckActivityTime(ticketData.last_action_date) &&
                  ticketData.ticket_status === 0 && (
                    <Label
                      // style={{ width: 110 }}
                      variant="ghost"
                      color="error"
                    >
                      Not Responding
                    </Label>
                  )}
                <div className="m-0">
                  <IconButton onClick={handleOpenMenu}>
                    <Iconify icon="fluent:more-vertical-16-filled" />
                  </IconButton>
                  <Menu
                    open={Boolean(menuOpen)}
                    anchorEl={menuOpen}
                    onClose={handleCloseMenu}
                  >
                    {getMenuOptions().map((item) => (
                      <MenuItem onClick={item.clickHandler}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Typography fontWeight={600} sx={{ my: 1, mr: 1 }}>
              Created At:
            </Typography>
            <Label className="m-2" variant="" color="success">
              {moment(ticketData.createdAt).format("LLL")}
            </Label>
          </div>
          <div className="d-flex align-items-center">
            <Typography fontWeight={600} sx={{ my: 1, mr: 1 }}>
              Category:
            </Typography>
            {/* <Label> */}
            {capitalCase(ticketData?.category.replace("_", " "))}
            {/* </Label> */}
          </div>
          <div className="col-12 mb-4">
            {/* <Typography variant="h5" fontWeight={600}>
              Description
            </Typography>
            <Typography
              variant="p"
              style={{ whiteSpace: "pre-wrap" }}
              gutterBottom
            >
              {ticketData.description
                ? ticketData.description
                : "No description added!"}
            </Typography> */}
            <p
              className="text-capitalize mb-0"
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              >
                Description:
              </span>
              {ticketData.description
                ? ticketData.description
                : "No description added!"}
            </p>
          </div>
          <div className="col-12 mb-3">
            {ticketData.image && (
              <a href={s3baseUrl + ticketData.image} target="_blank">
                <img
                  // width="100%"
                  src={s3baseUrl + ticketData.image}
                  height={100}
                  width={100}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </a>
            )}
          </div>
          {comments.map((comment) => (
            <div className="col-12 mt-2">
              <div className="support-ticket-wrapper border-top">
                <div className="support-profile me-3 mt-2">
                  <Avatar
                    style={{ width: 50, height: 50 }}
                    src={s3baseUrl + comment.user?.image}
                    alt={comment.user?.first_name}
                  />
                </div>
                <div className="w-100">
                  <div className="mt-2">
                    <Typography
                      className="mb-0 text-capitalize"
                      variant="subtitle1"
                      gutterBottom
                    >
                      {comment.user?.first_name + " " + comment.user?.last_name}
                    </Typography>
                    {comment.user?.type === "1" && (
                      <div className="float-end">
                        <IconButton
                          onClick={(e) => {
                            setCurrentMessage(comment);
                            handleOpenMessageMenu(e);
                          }}
                        >
                          <Iconify icon="fluent:more-vertical-16-filled" />
                        </IconButton>
                      </div>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                      noWrap
                    >
                      {moment(comment.createdAt).fromNow()}
                    </Typography>
                  </div>
                  <div className="mt-1">
                    <Typography
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="p"
                      gutterBottom
                    >
                      {comment.message}
                    </Typography>
                  </div>

                  <div className="mt-1">
                    <a href={s3baseUrl + comment.image} target="_blank">
                      <img width="60" src={s3baseUrl + comment.image} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Menu
            open={Boolean(messageMenuOpen)}
            anchorEl={messageMenuOpen}
            onClose={handleCloseMessageMenu}
          >
            <MenuItem onClick={openDeleteMessageDialog}>Delete</MenuItem>
          </Menu>
          {ticketData?.ticket_status == 0 && (
            <form onSubmit={handleSubmit}>
              <div className="col-12 mt-4">
                <TextField
                  fullWidth
                  label="Message"
                  placeholder="Please add your details here"
                  required
                  name="message"
                  multiline
                  rows={5}
                  value={inputs.message}
                  onChange={handleChange}
                />
              </div>

              <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                {inputs.image ? (
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ position: "relative" }}
                  >
                    <img
                      width="60"
                      src={URL.createObjectURL(inputs.image)}
                      alt=""
                    />
                    <Iconify
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "red",
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleClickRemove}
                      icon="gridicons:cross-circle"
                    />
                    {/* <Button
                    onClick={handleClickRemove}
                    className="mt-1 ms-3"
                    style={{ color: "Red" }}
                    fullWidth
                  >
                    Remove
                  </Button> */}
                  </div>
                ) : (
                  <label htmlFor="icon-button-file">
                    <input
                      style={{ display: "none" }}
                      accept="image/png, image/jpg, image/jpeg"
                      id="icon-button-file"
                      type="file"
                      value=""
                      name="image"
                      onChange={handleChange}
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button"
                      variant="outlined"
                      fullWidth
                    >
                      Upload Image
                    </Button>
                  </label>
                )}

                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoadingSend}
                >
                  Send
                </LoadingButton>
              </div>
              {/* <hr></hr>
              <Stack direction="row" spacing={1} overflow="auto">
                {Templates.map((template) => (
                  <Chip
                    onClick={() => {
                      setInputs({ message: inputs.message + template[1] });
                    }}
                    label={template[0]}
                  />
                ))}
              </Stack>
              <hr></hr> */}
            </form>
          )}
          {ticketData?.ticket_status != 0 && (
            <div className="d-flex justify-content-center align-items-center">
              <Label
                color="error"
                variant="outlined"
                sx={{ py: 2, mt: 1, mb: 3 }}
              >
                <Typography fontWeight={600} fontSize={20}>
                  This ticket has been closed!
                </Typography>
              </Label>
            </div>
          )}
        </div>
      </div>
      <MuiDialog
        open={deleteDialogOpen}
        onToggle={closeDeleteDialog}
        title="Delete Ticket"
        message="Are you sure you want to delete this support ticket"
        onAgree={deleteSupportTicket}
        loading={deleteTicketLoading}
      />
      <MuiDialog
        open={closeDialogOpen}
        onToggle={closeCloseDialog}
        title="Close Ticket"
        message="Are you sure you want to close this support ticket"
        onAgree={closeSupportTicket}
        loading={closeTicketLoading}
      />
      <MuiDialog
        open={deleteMessageDialogOpen}
        onToggle={closeDeleteMessageDialog}
        title="Delete Message"
        message="Are you sure you want to Delete this message?"
        onAgree={handleDeleteMessage}
        loading={deleteMessageLoading}
      />
    </>
  );
}

export default SupportTicketDetails;
