import { Card, CardContent, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { noResults } from "src/assets";

function NoTasksFound() {
  const ImgContainer = styled("div")({
    width: "30%",
    // position: 'relative',
    margin: "auto",
  });
  return (
    <Card>
      <CardContent>
        <div className="centered-row" style={{ flexGrow: 1 }}>
          {/* <Stack sx={{ height: "7rem", my: 2 }}> */}
          <Stack>
            <ImgContainer id="not-found-results-container">
              <img width="100%" src={noResults} style={{ margin: "auto" }} />
            </ImgContainer>
            <Typography
              color={(theme) => theme.palette.text.disabled}
              variant="body2"
              textAlign="center"
              fontWeight="bold"
            >
              No Tasks !
            </Typography>
          </Stack>
        </div>
      </CardContent>
    </Card>
  );
}

export default NoTasksFound;
