import { Avatar, Badge, IconButton, Tooltip, Typography } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import InfoIcon from "@mui/icons-material/Info";
import { Iconify, MuiDialog } from "src/components";
import { useAppContext } from "src/hooks";
import { useState } from "react";
import { _deleteChat } from "src/DAL";
import { useSnackbar } from "notistack";
import GroupInfoDrawer from "./GroupInfoDrawer";
import { del_from_local_storage } from "src/utils";

function ChatHeader({ isMobile, setChatListDrawerOpen }) {
  const {
    currentChat,
    setCurrentChat,
    dispatch_get_user_profile,
    setMessageList,
    setMessageCount,
    setChatList,
    socketEmit,
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteChatLoading, setDeleteChatLoading] = useState(false);
  const [deleteChatDialogOpen, setDeleteChatDialogOpen] = useState(false);
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);
  const isBadgeInVisible = () => {
    if (currentChat?.type == "0") {
      return false;
    } else {
      return true;
    }
  };
  const getBadgeColor = () => {
    if (currentChat?.is_online) {
      return "primary";
    } else {
      return "inactive";
    }
  };
  const handleDeleteChat = async () => {
    setDeleteChatLoading(true);
    try {
      const result = await _deleteChat(currentChat._id);
      if (result.code == 200) {
        del_from_local_storage("currentChat");
        setMessageCount(0);
        setMessageList([]);
        setChatList((prev) => {
          return prev.filter((chat) => chat._id !== currentChat._id);
        });
        setCurrentChat();
        setDeleteChatDialogOpen(false);
        enqueueSnackbar("Chat deleted successfully", { variant: "success" });
        socketEmit("deleteGroupChat", result.chat_data);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while deleting the chat");
    } finally {
      setDeleteChatLoading(false);
    }
  };
  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{ padding: "10px 0" }}
      >
        <div className="d-flex align-items-center">
          {isMobile && (
            <Tooltip arrow title="Chat List">
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => setChatListDrawerOpen(true)}
              >
                <MenuOpenIcon />
              </IconButton>
            </Tooltip>
          )}
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            overlap="circular"
            variant="dot"
            invisible={isBadgeInVisible()}
            color={getBadgeColor()}
          >
            <Avatar className="chat-avatar" src={currentChat?.image}>
              {currentChat?.label?.substring(0, 1)}
            </Avatar>
          </Badge>
          <Typography fontWeight="bold" sx={{ ml: 1 }}>
            {currentChat?.label}
          </Typography>
        </div>
        <div className="d-flex align-items-center">
          {currentChat.creator_id == dispatch_get_user_profile().user_id && (
            <Tooltip title="Delete this chat">
              <IconButton onClick={() => setDeleteChatDialogOpen(true)}>
                <Iconify
                  icon="ic:outline-delete"
                  sx={{ width: 24, height: 24 }}
                  color="#D22B2B"
                />
              </IconButton>
            </Tooltip>
          )}
          {currentChat.type == "1" && (
            <Tooltip title="Group Info">
              <IconButton onClick={() => setIsInfoDrawerOpen(true)}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <MuiDialog
        open={deleteChatDialogOpen}
        title={currentChat.type == "0" ? "Delete Chat" : "Delete Group"}
        message={`Are you sure you want to delete this ${
          currentChat.type == "0" ? "chat" : "group"
        }?`}
        onAgree={() => {
          handleDeleteChat();
        }}
        onToggle={() => setDeleteChatDialogOpen(false)}
        loading={deleteChatLoading}
      />
      {currentChat && (
        <GroupInfoDrawer
          isInfoDrawerOpen={isInfoDrawerOpen}
          setIsInfoDrawerOpen={setIsInfoDrawerOpen}
        />
      )}
    </>
  );
}

export default ChatHeader;
