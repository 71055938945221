import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { _delete_report, _report_list } from "src/DAL";
import {
  CircularLoader,
  Iconify,
  MuiDialog,
  Page,
  Scrollbar,
  SearchNotFound,
} from "src/components";
import AddNewCard from "src/components/AddNewCard";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "total_time",
    label: "Time Consumed (hh:mm)",
    alignRight: false,
  },
  // { id: "price", label: "Price", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  // { id: '' },/* to add empty column */
];
function TaskReports() {
  const { enqueueSnackbar } = useSnackbar();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [menuOpen, setMenuOpen] = useState(null);
  const [currentReport, setCurrentReport] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();
  const handleNav = () => {
    navigate("/reports/add-report");
  };
  const handleOpenMenu = (e, item) => {
    setCurrentReport(item);
    setMenuOpen(e.target);
  };
  const handleCloseMenu = () => {
    setMenuOpen(null);
    setCurrentReport();
  };
  const getReportList = async () => {
    try {
      const result = await _report_list();
      if (result.code == 200) {
        setReports(result.report_list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error, " catcehd while fetchin list");
    } finally {
      setLoading(false);
    }
  };
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const menuOptions = [{ label: "Delete", clickHandler: openDeleteDialog }];
  const handleDeleteReport = async () => {
    try {
      if (!currentReport) {
        console.log("no report selected");
        return;
      }
      setDeleteLoading(true);
      const result = await _delete_report(currentReport._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        setReports((prev) =>
          prev.filter((report) => report._id !== currentReport._id)
        );
        closeDeleteDialog();
        handleCloseMenu();
      }
    } catch (error) {
      console.log(error, " fetched while deleting report");
    } finally {
      setDeleteLoading(false);
    }
  };
  const filteredReports = () => {
    if (!filterName) {
      return [...reports];
    } else {
      return reports?.filter((x) =>
        x.title.toLowerCase().includes(filterName.toLowerCase())
      );
    }
  };
  const isReportNotFound = filteredReports().length === 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  useEffect(() => {
    getReportList();
  }, []);
  if (loading) {
    return (
      <Page title="Reports">
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="mb-4"
          >
            <Typography variant="h4">Reports</Typography>
            <Button
              onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Report
            </Button>
          </Stack>
          <CircularLoader />
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Reports">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="mb-4"
        >
          <Typography variant="h4">Reports</Typography>
          <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Report
          </Button>
        </Stack>
        {reports.length !== 0 && (
          <Card
            sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
          >
            <ListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              moduleName="Reports"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {filteredReports()
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const { title, total_time, price, _id, createdAt } =
                          row;
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            // selected={isItemSelected}
                            // aria-checked={isItemSelected}
                          >
                            <TableCell>
                              <Typography variant="subtitle2">
                                {rowsPerPage * page + (index + 1)}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </TableCell>

                            <TableCell>{total_time}</TableCell>
                            {/* <TableCell>${price}</TableCell> */}

                            <TableCell>
                              {moment(createdAt).format("LLL")}
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                open={menuOpen}
                                onClose={handleCloseMenu}
                                options={[...menuOptions]}
                                item={row}
                                handleOpenOptions={handleOpenMenu}
                              />
                            </TableCell>
                          </TableRow>
                        );
                        // });
                      })}
                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>

                  {isReportNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[50, 100, 150]}
              component="div"
              count={filteredReports()?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
        {reports.length == 0 && !loading && (
          <AddNewCard title="report" handleClick={handleNav} />
        )}
      </Container>
      <MuiDialog
        title="Delete Report"
        message="Are you sure you want to delete this report?"
        open={deleteDialogOpen}
        onToggle={closeDeleteDialog}
        onAgree={handleDeleteReport}
        loading={deleteLoading}
      />
    </Page>
  );
}

export default TaskReports;
