import { filter } from "lodash";
import { useSnackbar } from "notistack";
import { _generate_token } from "src/DAL";
export const handle_localSearch = (input, array) => {
  if (!array) {
    console.error("invalid params supplied to localSearch ");
    return [];
  }
  let filtered = filter(array, (_item) => {
    if (!_item.query_field) {
      console.error("no query_field in object");
      return [];
    }
    return _item.query_field.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  });
  return filtered;
};

export const string_with_limited_length = (string, limit = 50) => {
  var dots = "...";
  if (string.length > limit) {
    // you can also use substr instead of substring
    string = string.substring(0, limit) + dots;
  }

  return string;
};
