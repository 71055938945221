import React from "react";
// material
import {
  Stack,
  Avatar,
  Box,
  Typography,
  ButtonBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { s3baseUrl } from "src/config";
import { capitalCase } from "change-case";
import { Label } from "src/components";
const InvitationCard = ({ item, onCancel, onResend }) => {
  const { email, status, createdAt, _id, workspace, image } = item;
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Stack direction="column">
        <Stack direction="row" sx={{ mb: 2 }}>
          <Box alignItems="center" justifyContent="center">
            {workspace.image ? (
              <Avatar src={s3baseUrl + workspace.image} />
            ) : (
              <Avatar>{workspace.name.substring(0, 2)}</Avatar>
            )}
          </Box>
          <Typography
            sx={{ alignSelf: "center", ml: 1 }}
            variant="body1"
            noWrap
          >
            {workspace.name}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight="bold" variant="body1">
              Role
            </Typography>
            <Typography variant="body1" noWrap>
              {capitalCase(item.role)}
            </Typography>
          </Stack>
          {workspace.description && (
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="bold" variant="body1">
                Description
              </Typography>
              {workspace.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: workspace.description }}
                />
              ) : (
                <></>
              )}
              {/* <Typography variant="body1">
              {workspace.description ? workspace.description : "..."}
            </Typography> */}
            </Stack>
          )}
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Tooltip title="cancel invitation">
              <ButtonBase onClick={() => onCancel(item)}>
                <Label
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  color="error"
                  variant="ghost"
                >
                  Cancel
                </Label>
              </ButtonBase>
            </Tooltip>
            <Tooltip title="resend invitation">
              <ButtonBase onClick={() => onResend(item)}>
                <Label
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  color="success"
                  variant="ghost"
                >
                  Re-send
                </Label>
              </ButtonBase>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default InvitationCard;
