import { invokeApi } from "src/utils";
import { get_from_localStorage } from "src/utils";

export const _get_tasks_for_report = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/task/task_list_for_report`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _add_report = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/report/add_client_report`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

export const _report_list = (data) => {
  const reqObj = {
    method: "GET",
    path: `api/report/client_report_list`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _delete_report = (id) => {
  const reqObj = {
    method: "DELETE",
    path: `api/report/delete_client_report/${id}`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};
