import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  AllTasksVariant,
  CircularLoader,
  CommentsDrawer,
  Iconify,
  MuiDialog,
  NoData,
  Page,
  SearchBar,
  TaskCard,
  TaskDetailDrawer,
} from "src/components";
import {
  Container,
  Box,
  Typography,
  Card,
  Stack,
  Grid,
  IconButton,
  Button,
  Pagination,
} from "@mui/material";
import { capitalCase } from "change-case";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  ALLOWED_TASK_FILES_WITH_ICONS,
  TASK_PRIORITY_OPTIONS,
  TASK_STATUS_OPTIONS,
} from "src/constants";
import TaskDetailDialog from "src/components/TaskDetailDrawer/TaskDetailDrawer";
import {
  get_month_first_day,
  get_month_last_day,
  handle_localSearch,
  upload_task_files,
} from "src/utils";
import {
  _add_task_comment,
  _all_completed_tasks,
  _dashboard_tasks,
  _delete_task,
  _delete_task_comment,
  _edit_task,
  _task_comment_list,
  _update_task_comment,
  _update_task_end_date,
  _update_task_members,
  _update_task_priority,
  _update_task_status,
  _update_task_tags_in_task,
} from "src/DAL";
import { motion, AnimatePresence } from "framer-motion";
import { useAppContext } from "src/hooks";
/* <<--------------------------------------------------------->> */
const AllCompletedTasks = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("");
  const [tasks, setTasks] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [loaders, setLoaders] = useState({ tasks: true });
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [loadingComments, setLoadingComments] = useState(true);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [selectedTaskComments, setSelectedTaskComments] = useState([]);
  const { dispatch_get_user_profile, socketEmit } = useAppContext();
  const user_profile = dispatch_get_user_profile();
  const [page, setPage] = useState(0);
  const [projectTeam, setProjectTeam] = useState([]);
  const [projectTags, setProjectTags] = useState([]);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [editingComment, setEditingComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState();
  const [editCommentType, setEditCommentType] = useState("");
  const [editingCommentId, setEditingCommentId] = useState("");
  const [newComment, setNewComment] = useState("");
  const [delCommentOpen, setDelCommentOpen] = useState(false);
  const [newCommentForDrawer, setNewCommentForDrawer] = useState({
    title: "",
    type: "0",
  });
  /* <<--------------------------------------------------------->> */
  const handleNavBack = async () => {
    navigate(-1);
  };
  const openCommentsDrawer = () => {
    setCommentsOpen(true);
  };
  const closeCommentsDrawer = () => {
    setCommentsOpen(false);
    setSelectedTaskComments([]);
  };
  const openDelTaskDialog = () => {
    setDelDialogOpen(true);
  };
  const closeDelTaskDialog = () => {
    setDelDialogOpen(false);
  };
  const getFilteredTasks = () => {
    return handle_localSearch(search, tasks, { search });
  };
  const onSetSelectedTask = (task) => {
    console.log(task);
  };
  const onOpenTaskDetail = (task) => {
    console.log(task, "task-");
    setSelectedTask(task);
  };
  const onOpenComments = (task) => {
    setSelectedTask(task);
    getTaskComments(task);
    openCommentsDrawer();
  };
  const getTaskComments = async (task, noLoader) => {
    if (!noLoader) {
      setLoadingComments(true);
    }
    const data = {
      workspace_id: task.workspace_id,
    };
    const result = await _task_comment_list(task._id);
    console.log(result, "com-list");
    setLoadingComments(false);
    setSelectedTaskComments(result.project_list.comment_list);
  };
  const handleCommentAction = async (action, payload) => {
    console.log(payload, "payload");
    console.log(action, "action");
    if (action === "add_comment") {
      return handleAddComment(payload);
    }
    if (action === "edit_comment") {
      return handleEditComment(payload);
    }
    if (action === "delete_comment") {
      return handleDeleteComment(payload);
    }
  };
  const afterCommentAction = () => {
    getDashboardData();
    getTaskComments(selectedTask, true);
    setSelectedComment();
    setEditingComment(false);
    setEditCommentTitle("");
    setEditingCommentId("");
  };
  const handleAddComment = async (payload) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }
    const { title, type } = payload;
    const data = {
      comment_title: title.trim().replace(/<a /g, "<a target='_blank' "),
      comment_type: type,
    };

    console.log(data, "req-add-comm");
    const result = await _add_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      if (selectedTask) {
        setSelectedTask((prev) => {
          let found = prev.task_comment.findIndex(
            (item) => item._id == result.task_comment._id
          );
          if (found !== -1) {
            let temp = prev.task_comment;
            temp.splice(found, 1);
            temp.push(result.task_comment);
            return {
              ...prev,
              task_comment: [...temp],
            };
          } else {
            return {
              ...prev,
              task_comment: [...prev.task_comment, result.task_comment],
            };
          }
          // return { ...prev };
        });
      }
      if (type == "0") {
        // let receiver = [selectedTask.creator_profile.user_id];
        // selectedTask.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id } = selectedTask;
        socketEmit("send_notification_to_all", {
          receiver: result.task_comment.allow_members,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } added a comment in task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    }
    return result;
  };

  const handleEditComment = async (selectedComment) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }

    const data = {
      comment_id: selectedComment._id,
      comment_title: selectedComment.comment_title,
      comment_type: selectedComment.comment_type,
    };
    console.log(data, "req-update-com");
    const result = await _update_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      if (selectedTask) {
        setSelectedTask((prev) => {
          return {
            ...prev,
            task_comment: prev.task_comment.map((oldComment) => {
              if (oldComment._id === result.task_comment._id) {
                return { ...result.task_comment };
              } else {
                return { ...oldComment };
              }
            }),
          };
        });
      }
    }
    return result;
  };
  const handleDeleteComment = async () => {
    if (!selectedTask) {
      return undefined;
    }
    const result = await _delete_task_comment(
      { comment_id: selectedComment._id },
      selectedTask._id
    );
    afterCommentAction();
    if (result.code == 200) {
      if (selectedTask) {
        setSelectedTask((prev) => {
          return {
            ...prev,
            task_comment: prev.task_comment.filter(
              (comment) => comment._id !== selectedComment._id
            ),
          };
        });
      }

      closeDelCommentDialog();
    }
    return result;
  };
  const getDashboardData = async () => {
    let first_day = get_month_first_day();
    let last_day = get_month_last_day();
    const data = { start_date: first_day, end_date: last_day };

    const result = await _all_completed_tasks(page, 40, { search });
    console.log(result, "_all_completed_tasks res");
    if (result.code === 200) {
      const isCreator = (item) => {
        const user_id = user_profile.user_id;
        return String(user_id) === String(item.task_action_id);
      };
      const getModified = (data) => {
        let modified = data.map((item, i) => {
          return {
            ...item,
            ["query_field"]: item.task_title,
            creator: isCreator(item),
            scroll_to_view: false,
          };
        });
        console.log(modified, "modif");

        return modified;
      };
      const { task_list, task_count } = result;
      setTasks(getModified(task_list));
      setTaskCount(task_count);
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
    } else {
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAction = (action, data) => {
    console.log(action, "action");
    console.log(data, "payload");
    if (action === "change_status") {
      handleChangeStatus(data);
    }
    if (action === "change_date") {
      handleChangeDate(data);
    }
    if (action === "change_priority") {
      handleChangePriority(data);
    }
    if (action === "change_members") {
      handleChangeMembers(data);
    }
    if (action === "del_task") {
      handleDelTask(data);
    }
    if (action === "copy_task_link") {
      handleCopyTaskLink(data);
    }
    if (action === "open_task_detail") {
      handleOpenTaskDetail(data);
    }
  };
  const shouldFilterTask = (status) => {
    if (String(status) === "0") return true;
    if (String(status) === "1") return true;
    if (String(status) === "2") return true;
    if (String(status) === "4") return true;
    return false;
  };
  const handleChangeStatus = async (data) => {
    console.log(data, "change-status");
    const { is_checkbox, status, checked, task } = data;

    const updateTaskStatus = async (req_data, section_id) => {
      const { task_status } = req_data;
      const should_filter = shouldFilterTask(task_status);
      if (should_filter === true) {
        setTasks((prev) =>
          prev.filter((item) => String(item._id) !== String(task._id))
        );
      }
      const result = await _update_task_status(req_data, section_id);
      getDashboardData();
      if (result.code === 200) {
        console.log(selectedTask, "AScsiovuaiovuioavs");
        if (selectedTask) {
          setSelectedTask((prev) => {
            return { ...result.task_detail, project_data: prev.project_data };
          });
        }
        enqueueSnackbar("Task Status Updated Successfully", {
          variant: "success",
        });
        // let receiver = [result.task_detail.creator_profile.user_id];
        // result.task_detail.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.allow_members,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.hidden_members,
          data: result.Project,
          message: `A member updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    if (is_checkbox === false) {
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }

    if (is_checkbox === true) {
      let updated_status = checked === true ? 3 : 0;
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: updated_status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: updated_status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }
  };

  const handleChangePriority = async (data) => {
    console.log(data, "change-priority");
    const { priority, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, priority: priority };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      task_priority: priority,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_priority(req_data, task.section_id);
    getDashboardData();
    if (result.code === 200) {
      if (selectedTask) {
        setSelectedTask((prev) => {
          return { ...result.task_detail, project_data: prev.project_data };
        });
      }
      console.log(result, "res");
      enqueueSnackbar("Task Priority Updated Successfully", {
        variant: "success",
      });
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.hidden_members,
        data: result.Project,
        message: `A member updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };
  const handleChangeDate = async (data) => {
    console.log(data, "change-date");
    const { endDate, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, end_date: endDate };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      end_date: endDate,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_end_date(req_data, task.section_id);
    getDashboardData();
    if (result.code === 200) {
      if (selectedTask) {
        setSelectedTask((prev) => {
          return { ...result.task_detail, project_data: prev.project_data };
        });
      }
      console.log(result, "res");
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.Project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the target date of the task`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };
  const onEditComment = (_comment) => {
    setSelectedComment(_comment);
    setEditingComment(true);
    setEditCommentTitle(_comment.comment_title);
    setEditCommentType(String(_comment.comment_type));
  };
  const handleDelTask = async (task) => {
    setSelectedTask(task);
    openDelTaskDialog();
  };
  const deleteTask = async () => {
    console.log(selectedTask, "slc task");
    if (!selectedTask) {
      return;
    }
    setProcessing(true);
    const result = await _delete_task(selectedTask._id);
    getDashboardData();
    if (result.code === 200) {
      setTasks((prev) => prev.filter((item) => item._id != selectedTask._id));
      setTaskCount((prev) => prev - 1);
      closeDelTaskDialog();
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      closeDelTaskDialog();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeMembers = async (data) => {
    console.log(data, "change-team");
    const { task, taskTeam, isSelected, targetUser, action } = data;

    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user) !== String(targetUser.user)
        );
      }
    }

    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };

    setTasks((prev) =>
      prev.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      })
    );
    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(user);
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      // workspace_id: task.workspace.id,
      list_type: "0",
    };

    const result = await _update_task_members(req_data);
    getDashboardData();
    if (result.code === 200) {
      if (selectedTask) {
        setSelectedTask((prev) => {
          return { ...result.task_detail, project_data: prev.project_data };
        });
      }
      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        console.log(receiver, "Aasckljschals");
        const { project, section_id, _id } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          data: result.Project,
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenTaskDetail = (_task) => {
    setSelectedTask(_task);
    setTaskDetailOpen(true);
  };
  const handleCloseTaskDetail = () => {
    setSelectedTask(undefined);
    setTaskDetailOpen(false);
  };
  const handleCopyTaskLink = async (task) => {
    const { project, section_id, _id } = task;
    console.log(task, "to-copy");
    const scroll_task_data = {
      task_id: _id,
      section_id: section_id,
    };
    let newLink = "";
    newLink = `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`;
    console.log(newLink);
    // unsecuredCopyToClipboard(newLink);
    let has_error = "";
    try {
      await window.navigator.clipboard.writeText(newLink);
    } catch (error) {
      if (error) {
        if (error.message) {
          has_error = error.message;
        }
      }
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    if (has_error === "") {
      enqueueSnackbar("Task link copied to clipboard", {
        variant: "success",
      });
      return;
    }
    enqueueSnackbar("Could not copy task link", { variant: "error" });
  };
  const handleTaskDetailSidebarAction = async (_action, _payload) => {
    console.log(_action, "action--");
    console.log(_payload, "payload--");
    if (String(_action) === "remove_task_file") {
      setSelectedTask(_payload);
    }
    if (String(_action) === "edit_task") {
      return handleEditTask(_payload);
    }
  };
  const uploader = async (addTaskFiles) => {
    if (addTaskFiles.length === 0) {
      console.log("no files");
      return [];
    }
    let paths = [];
    for (let i = 0; i < addTaskFiles.length; i++) {
      let res = await upload_task_files(addTaskFiles[i].file);
      paths.push(res.path);
    }
    return paths;
  };
  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };
  const closeDelCommentDialog = () => {
    setDelCommentOpen(false);
    setSelectedComment();
  };
  const openDelCommentDialog = (comment) => {
    setSelectedComment(comment);
    setDelCommentOpen(true);
  };
  const handleEditTask = async (_data) => {
    const { selectedTaskDescription, selectedTaskTitle, addTaskFiles } = _data;
    let target_task = currentTask();
    console.log(target_task);
    let task_file_paths = [...target_task.image];

    let image_paths = await uploader(addTaskFiles);
    if (image_paths) {
      console.log("file-paths", image_paths);
      task_file_paths = [...target_task.image, ...image_paths];
    }
    console.log(task_file_paths, "final-paths");
    const req_data = {
      task_id: target_task._id,
      workspace_id: target_task.workspace_id,
      image: task_file_paths /* include current task paths too */,
      task_title: selectedTaskTitle,
      task_description: selectedTaskDescription,
    };
    console.log(req_data, "edit-req");
    let success = false;
    const result = await _edit_task(req_data, target_task.section_id);
    if (result.code === 200) {
      success = true;

      const { task_data } = result;
      let updated_task = {
        ...task_data,
        section_id: target_task.section_id,
        query_field: task_data.task_title,
        workspace_id: target_task.workspace_id,
        project_data: target_task.project_data,
      };

      setTasks((_tasks) =>
        _tasks.map((task) => {
          if (String(task._id) === String(target_task._id)) {
            return updated_task;
          }
          return task;
        })
      );
      setSelectedTask(updated_task);
      // getProjectTasks();
      console.log(result, "edit-task-res");
      enqueueSnackbar("Task Updated Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    return success;
  };
  const getPaginationCount = () => {
    let pages = taskCount / 40;
    let pagesFloor = Math.floor(pages);
    if (pages > pagesFloor) {
      return pagesFloor + 1;
    } else {
      return pagesFloor;
    }
  };
  const onChangeTags = async (data) => {
    try {
      const result = await _update_task_tags_in_task(data);
      if (result.code == 200) {
        if (selectedTask) {
          setSelectedTask((prev) => {
            return { ...result.task_data, project_data: prev.project_data };
          });
        }
        setTasks((prev) => {
          return prev.map((task) => {
            if (task._id == result.task_data._id) {
              return { ...result.task_data, project_data: task.project_data };
            } else {
              return { ...task };
            }
          });
        });
      }
    } catch (error) {
      console.log(error, " catched while updating task tags");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  /* <<--------------------------------------------------------->> */
  ////==============Files///////////////////////////////////////////
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = currentTask();
    const { image } = task;
    for (let path of image) {
      let data = getFileIconType(path);
      const { file_type, file_path, color } = data;
      files.push({
        path: file_path,
        type: file_type,
        db_path: path,
        color: color,
      });
    }
    return files;
  };

  /* <<--------------------------------------------------------->> */
  useLayoutEffect(() => {
    getDashboardData();
    return () => {
      setTasks([]);
      setLoaders({ tasks: true });
    };
  }, [page, search]);
  /* <<--------------------------------------------------------->> */
  useEffect(() => {
    console.log(selectedTask, "aoauysioausfoiasf");
    if (selectedTask) {
      setProjectTeam(selectedTask?.project_data?.team);
      setProjectTags(selectedTask?.project_data?.tag_colors);
    }
  }, [selectedTask]);
  /* <<--------------------------------------------------------->> */
  return (
    <div>
      <Page title="All Completed Tasks">
        <Container maxWidth="xl">
          <Grid container className="project-header">
            <Grid item display="flex" alignItems="center">
              <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
                <Iconify icon="ep:back" />
              </IconButton>
              <Typography fontWeight="bold">
                {capitalCase("all completed tasks")}
              </Typography>
            </Grid>
          </Grid>
          <Stack sx={{ my: 3 }} direction="row" alignItems="center">
            <SearchBar
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Stack>
          {!loaders.tasks && (
            /* limit 10 on dashboard*/
            <>
              <Stack spacing={2}>
                <AnimatePresence layout initial={false}>
                  {tasks.map((task, i) => (
                    <motion.div
                      key={task._id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      layout
                    >
                      <AllTasksVariant
                        task={task}
                        onSetSelectedTask={onSetSelectedTask}
                        taskTeam={task.team}
                        onOpenDetail={onOpenTaskDetail}
                        onOpenComments={onOpenComments}
                        onTaskAction={handleAction}
                        projects={[task.project_data]}
                        getListing={() => getDashboardData()}
                        onChangeTags={onChangeTags}
                        completed={true}
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </Stack>
              {taskCount > 40 && (
                <div className="d-flex justify-content-center my-4">
                  <Pagination
                    page={page + 1}
                    count={getPaginationCount()}
                    color="primary"
                    size="large"
                    onChange={(e, p) => setPage(p - 1)}
                    hidePrevButton={page == 0}
                    hideNextButton={page == getPaginationCount() - 1}
                  />
                </div>
              )}
            </>
          )}
          {loaders.tasks && <CircularLoader />}
        </Container>

        {/* <TaskDetailDrawer
          selectedTask={selectedTask}
          open={taskDetailOpen}
          onClose={handleCloseTaskDetail}
          disabled={false}
          onAction={handleTaskDetailSidebarAction}
        /> */}
        {currentTask() && (
          <TaskDetailDialog
            open={taskDetailOpen}
            onClose={handleCloseTaskDetail}
            task={selectedTask}
            setTask={setSelectedTask}
            taskFiles={getTaskImages()}
            getFileIconType={getFileIconType}
            projectTeam={projectTeam}
            projectTags={projectTags}
            setProjectTags={setProjectTags}
            onChangeTags={onChangeTags}
            onChangePriority={handleChangePriority}
            onChangeStatus={handleChangeStatus}
            onChangeMembers={handleChangeMembers}
            handleSendComment={() =>
              handleAddComment({ title: newComment, type: 0 })
            }
            handleUpdateTaskComment={(e, type, comment) =>
              handleEditComment({ ...comment, comment_title: editCommentTitle })
            }
            newComment={newComment}
            setNewComment={setNewComment}
            onDelComment={openDelCommentDialog}
            editCommentTitle={editCommentTitle}
            setEditCommentTitle={setEditCommentTitle}
            onEditComment={onEditComment}
            editingCommentId={editingCommentId}
            setEditingCommentId={setEditingCommentId}
            setTasks={(section_id, task_id, updated_task) => {
              setTasks((prev) => {
                return prev.map((oldTask) => {
                  if (oldTask._id == task_id) {
                    return { ...updated_task };
                  } else {
                    return { ...oldTask };
                  }
                });
              });
            }}
            onChangeDate={handleChangeDate}
            getListing={getDashboardData}
          />
        )}
        <CommentsDrawer
          open={commentsOpen}
          selectedTask={selectedTask}
          onClose={closeCommentsDrawer}
          comments={selectedTaskComments}
          disabled={false}
          onCommentAction={handleCommentAction}
          loading={loadingComments}
          newComment={newCommentForDrawer}
          setNewComment={setNewCommentForDrawer}
        />
        <MuiDialog
          open={delDialogOpen}
          onAgree={deleteTask}
          onToggle={closeDelTaskDialog}
          title="Delete Task"
          loading={processing}
          message="Are you sure you want to delete this task?"
        />
        <MuiDialog
          open={delCommentOpen}
          onToggle={closeDelCommentDialog}
          onAgree={handleDeleteComment}
          loading={false}
          title="Delete Comment"
          message={`Are you sure you want to delete this comment?`}
        />
        {/* <div className="no-data-container"> */}
        <NoData
          title="No Tasks Found"
          loading={loaders.tasks}
          dataLength={getFilteredTasks().length}
        />
        {/* </div> */}
      </Page>
    </div>
  );
};

export default AllCompletedTasks;
